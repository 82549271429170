import { getLegalFormObject } from 'helpers/formatLegalForm';

export const getLegalFormOptions = () => {
  const legalFormObject = getLegalFormObject();
  return [
    {
      label: legalFormObject.beslotenvennootschap,
      value: 'Besloten Vennootschap',
    },
    {
      label: legalFormObject.naamlozevennootschap,
      value: 'Naamloze Vennootschap',
    },
    {
      label: legalFormObject.stichting,
      value: 'Stichting',
    },
  ];
};
