import type { Document, Register } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import { SidebarFormContainer, SidebarFormContent } from 'components/SidebarForm/SidebarForm';
import { DocumentUploadForm, documentUploadFormSchema } from 'forms/document/DocumentUploadForm';
import { useUpdateDocument } from 'hooks/useUpdateDocument';
import type { CreateDocument_createDocument } from 'types/graphql/CreateDocument';
import type { UpdateDocument_updateDocument } from 'types/graphql/UpdateDocument';
import type { UpdateMutation_updateMutation_document } from 'types/graphql/UpdateMutation';

export type OnDocumentSaveFnDocument =
  | CreateDocument_createDocument
  | UpdateDocument_updateDocument
  | UpdateMutation_updateMutation_document;
export type OnDocumentSaveFn = (document: OnDocumentSaveFnDocument) => void;

interface DocumentSidebarFormProps {
  register: Pick<Register, 'id' | 'legalEntity'>;
  document?: Document;
  isSidebarShown: boolean;
  onDocumentSave: OnDocumentSaveFn;
  onSidebarClose: () => void;
}
export const DocumentSidebarForm = ({ isSidebarShown, ...props }: DocumentSidebarFormProps) => {
  return (
    <SidebarFormContainer isDrawerShown={isSidebarShown}>
      {isSidebarShown && <DocumentSidebarFormContent {...props} />}
    </SidebarFormContainer>
  );
};

const DocumentSidebarFormContent = ({
  register,
  document,
  onDocumentSave,
  onSidebarClose,
}: Omit<DocumentSidebarFormProps, 'isSidebarShown'>) => {
  const updateDocument = useUpdateDocument(register.id);
  const mutationType = document?.mutation?.type;
  const isNew = !document;
  const header = isNew ? t`Document toevoegen` : t`Document bewerken`;
  const defaultValues = document;

  return (
    <SidebarFormContent
      defaultValues={defaultValues}
      form={
        <DocumentUploadForm
          canChooseType={isNew}
          hasColumns={false}
          isFoundation={register.legalEntity.isFoundation}
          isRequired={!mutationType}
          mutationType={mutationType}
        />
      }
      formSchema={documentUploadFormSchema}
      header={header}
      setDrawerClosed={onSidebarClose}
      onSubmit={async (values) => {
        updateDocument({
          values,
          document,
          onDocumentSave: (document) => {
            onDocumentSave(document);
            onSidebarClose();
          },
        });
      }}
    />
  );
};
