import type { RegisterMutation } from '@elseu/sdu-evidend-graphql';
import { MutationType } from '@elseu/sdu-evidend-graphql';
import { hasErrorsForPath } from 'forms/helpers/setFormErrors';
import { useUpdateMutationForm } from 'forms/mutation/useUpdateMutationForm';
import { mapToUpdateMutationInput } from 'graphql/input-mappers/RegisterMutation';
import { useLabels } from 'hooks/useLabels';
import { createMutationTypePredicate } from 'mutations/isMutationType';
import type { MutationInputFields } from 'mutations/mutationFields';
import { getMutationField } from 'mutations/mutationFields';
import { mapShareSeriesValidationErrors } from 'mutations/shareSeries';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import type { UpdateMutationVariables } from 'types/graphql/UpdateMutation';
import * as yup from 'yup';

export type CardFormMutationTypes = Exclude<MutationType, MutationType.LIQUIDATION>;

/** type predicate for mutations that can become cards. Only array type mutations can be cards and they must have a shareSeries. */
const isInvalidCardMutation = createMutationTypePredicate(MutationType.LIQUIDATION);

/**
 * The issuance mutation form is the same as the update mutation form, but has specific defaults for the issuance mutation.
 * It will automatically map the mutation to the cards field.
 * @param props
 * @returns
 */
const useMutationCardForm = <
  Mutation extends RegisterMutation,
  CardSchema extends yup.AnyObjectSchema,
  Type extends CardFormMutationTypes = CardFormMutationTypes,
  Field extends MutationInputFields[Type] = MutationInputFields[Type],
  CardData extends Mutation[Field] = Mutation[Field],
  DefaultCards extends Array<yup.Asserts<CardSchema>> = Array<yup.Asserts<CardSchema>>,
>({
  mutation,
  mutationType = mutation.type as Type,
  mutationSeriesToCards,
  cardsToMutationSeries,
  cardSchema,
  emptyCard = cardSchema.getDefault(),
  /** we may use the provided mutationSeriesToCards to fill in the default cards */
  defaultCards = [emptyCard] as DefaultCards,
  ...props
}: {
  mutation: Mutation;
  mutationType: Type;
  /** the schema for the card itself. */
  cardSchema: CardSchema;
  emptyCard?: yup.TypeOf<CardSchema>;
  defaultCards?: DefaultCards;
  /** Map each item in mutation[field] to a card.  */
  mutationSeriesToCards: (
    mutationSeries: NonNullable<CardData>,
    defaultCards?: Array<yup.Asserts<CardSchema>>,
  ) => Array<yup.Asserts<CardSchema>>;
  /** Map each card to one or more mutations. May return an array or a single UpdateMutationVariables input for the type(s) given to `mutationType`. Uses flatmap under the hood.  */
  cardsToMutationSeries: (
    cards: Array<yup.Asserts<CardSchema>>,
    mutationSeries: NonNullable<CardData>,
  ) => Array<NonNullable<UpdateMutationVariables['mutation'][Field]>[number]>;
} & Omit<
  Parameters<typeof useUpdateMutationForm>[0],
  | 'formInputMapper'
  | 'mutationType'
  | 'mutationField'
  | 'mutationSchema'
  | 'schema'
  | 'validationErrorMapper'
  | 'replaceValidationPart'
  | 'defaultValues'
>) => {
  if (isInvalidCardMutation(mutation)) {
    throw new Error('The mutation is not valid for cards');
  }
  const mutationRef = useRef(mutation);
  const mutationField = getMutationField(mutationRef.current.type);
  const cards = useMemo(() => {
    const cardData = mutationRef.current[mutationField];
    return cardData ? mutationSeriesToCards(cardData as NonNullable<CardData>, []) : defaultCards;
  }, [mutationField, mutationSeriesToCards, defaultCards]);

  const [mutationForm, mutationFormContext] = useUpdateMutationForm({
    ...props,
    mutation,
    mutationType,
    defaultValues: {
      cards,
    },
    schema: yup
      .object({
        cards: yup.array().defined().min(1).of(cardSchema),
      })
      .required(),
    validationErrorMapper: mapShareSeriesValidationErrors,
    formInputMapper: (formValues) => {
      const mutationId = mutationRef.current.id;
      const mutationInput = mapToUpdateMutationInput(mutationRef.current);
      const mutationValue = {
        ...mutationInput,
        effectiveDate: mutationRef.current.effectiveDate || new Date(),
        [mutationField]: cardsToMutationSeries(
          formValues.cards || [],
          mutationRef.current[mutationField] as NonNullable<CardData>,
        ),
      };
      return {
        mutationId,
        mutation: mutationValue,
      };
    },
  });

  const [expandedCardIndex, setExpandedCardIndex] = useState<number | undefined>(
    cards.length === 1 ? 0 : undefined,
  );

  const toggleCard = useCallback((index: number) => {
    setExpandedCardIndex((currentIndex) => (index === currentIndex ? undefined : index));
  }, []);

  // Automatically open the only card on mount.
  const {
    prepend,
    fields,
    remove: removeCard,
  } = useFieldArray({
    control: mutationForm.control,
    // this will trigger a cyclic structure, disable the typechecking for this string using `never`
    // more info: https://github.com/react-hook-form/react-hook-form/issues/4055
    name: 'cards' as never,
    keyName: 'key',
  });
  const addCard = () => {
    prepend(emptyCard);
    setExpandedCardIndex(() => 0);
  };

  const hasError = (cardIndex: number) =>
    hasErrorsForPath(mutationForm.formState.errors, `cards[${cardIndex}]`);

  const labels = useLabels(`${mutationField}.shares`, mutation.register.legalEntity.isFoundation);
  const isSubmitDisabled = !mutationForm.formState.isValid || mutationForm.formState.isSubmitting;
  const isLoading = mutationForm.formState.isSubmitting;

  return [
    mutationForm,
    {
      ...mutationFormContext,
      expandedCardIndex,
      toggleCard,
      addCard,
      fields,
      removeCard,
      cards,
      hasError,
      isSubmitDisabled,
      isLoading,
      labels,
    },
  ] as const;
};

export { useMutationCardForm };
