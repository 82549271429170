import type { RegisterMutation, ValidationError } from '@elseu/sdu-evidend-graphql';
import { useMutationForm } from 'forms/mutation/useMutationForm';
import { mapToUpdateMutationInput } from 'graphql/input-mappers/RegisterMutation';
import { merge } from 'lodash';
import { useUpdateMutation } from 'mutations/hooks/useUpdateMutation';
import type { MutationInputFields } from 'mutations/mutationFields';
import { mutationInputFields } from 'mutations/mutationFields';
import type { DeepPartial } from 'react-hook-form';
import type { UpdateMutation, UpdateMutationVariables } from 'types/graphql/UpdateMutation';
import type { AnyObjectSchema, AnySchema, ObjectSchema, TypeOf } from 'yup';
import * as yup from 'yup';
import type { RequiredStringSchema } from 'yup/lib/string';

const updateMutationFormInputMapper = <
  FormValues extends Record<string, unknown>,
  Mutation extends RegisterMutation,
>(
  formValues: FormValues,
  mutation: Mutation,
  mutationField = mutationInputFields[mutation.type],
): UpdateMutationVariables => ({
  mutationId: mutation.id,
  mutation: mapToUpdateMutationInput({
    ...mutation,
    [mutationField]:
      'mutation' in formValues
        ? merge(
            {},
            mutation[mutationField],
            (formValues as unknown as { mutation: RegisterMutation }).mutation[mutationField] || {},
          )
        : mutation[mutationField],
  }),
});

/**
 * Boilerplate to create a form that can handle creating a mutation and do validation
 * @param props
 * @returns
 */
const useUpdateMutationForm = <
  Mutation extends RegisterMutation,
  MutationSchema extends AnySchema = AnySchema,
  Type extends keyof MutationInputFields = Mutation['type'],
  Schema extends AnyObjectSchema = ObjectSchema<{
    mutationId: RequiredStringSchema<string | undefined>;
    mutation: ObjectSchema<{ [x in MutationInputFields[Type]]: MutationSchema }>;
  }>,
>({
  mutation,
  mutationSchema = yup.mixed() as MutationSchema,
  defaultMutationValues,
  mutationType = mutation.type as Type,
  mutationField = mutationInputFields[mutationType],
  schema = yup
    .object({
      mutationId: yup.string().required(),
      mutation: yup.object({ [mutationField]: mutationSchema }),
    })
    .required() as Schema,
  defaultValues = {
    mutationId: mutation.id,
    mutation: {
      [mutationField]: defaultMutationValues,
    },
  },
  formInputMapper = (formValues) => updateMutationFormInputMapper(formValues, mutation),
  ...props
}: Omit<
  Parameters<typeof useMutationForm>[0],
  'onSuccess' | 'onSubmit' | 'schema' | 'validationErrorMapper'
> & {
  /** called when the mutation is saved and no validation errors */
  onSuccess?: (mutation: UpdateMutation) => void;
  mutation: Mutation;
  /** used to infer the actual type of the mutation. If there is no specific mutation type given, the form will be typed to all possible mutations */
  mutationType?: Type;
  /** the validation specific to this mutation type. The correct base schema will be attached for (hopefully) automatic validation errors */
  mutationSchema?: MutationSchema;
  /** the field to assign this mutation to */
  mutationField?: MutationInputFields[Type];
  /** the field schema. It is set by default, but can be overridden using this parameter */
  schema?: Schema;
  /** the form default values */
  defaultMutationValues?: DeepPartial<TypeOf<MutationSchema>>;
  defaultValues?: DeepPartial<TypeOf<Schema>>;
  /** is used to map the specific form values to the graphql variables, if they don't match already */
  formInputMapper?: (formValues: TypeOf<Schema>) => UpdateMutationVariables;
  validationErrorMapper?: (
    errors: ValidationError[],
    formValues: yup.Asserts<MutationSchema>,
  ) => ValidationError[];
}) => {
  const [updateMutationQuery] = useUpdateMutation();

  return useMutationForm({
    ...props,
    schema,
    defaultValues,
    onSubmit: (formValues) =>
      updateMutationQuery({
        variables: formInputMapper(formValues),
      }),
  });
};

export { updateMutationFormInputMapper, useUpdateMutationForm };
