import type { MutationType, RegisterMutation } from '@elseu/sdu-evidend-graphql';

type BindMutationType<Mutation, Type> = Omit<Mutation, 'type'> & { type: Type } extends infer X
  ? X extends Mutation
    ? X
    : never
  : never;

/** Returns true if mutation type matches given type. Typescript will narrow type of Mutation using this in if statement. */
export const isMutationType = <Mutation extends RegisterMutation, Type extends MutationType>(
  mutation: Mutation,
  mutationType: Type,
): mutation is Mutation & { type: Type } => mutation.type === mutationType;

/** Returns true if mutation type is in given array of types. */
export const isMutationTypeIn = <Mutation extends RegisterMutation, Types extends MutationType[]>(
  mutation: Mutation,
  mutationTypes: readonly [...Types],
): mutation is Mutation & { type: Types[number] } => mutationTypes.includes(mutation.type);

/** Return a predicate function that can check a mutation to match the given type(s) given as initial argument(s)  */
export const createMutationTypePredicate =
  <Types extends MutationType[]>(...mutationTypes: readonly [...Types]) =>
  <Mutation extends RegisterMutation>(
    mutation: Mutation,
  ): mutation is BindMutationType<Mutation, Types[number]> =>
    mutationTypes.includes(mutation.type);
