import type { Usufruct, UsufructInput } from '@elseu/sdu-evidend-graphql';

import { mapDelegationDetailsToDelegationDetailsInput } from './DelegationDetails';
import { mapShareSeriesToShareSeriesInput } from './ShareSeries';

export const mapUsufructToUsufructInput = (usufruct: Usufruct): UsufructInput => ({
  details: usufruct.details ? mapDelegationDetailsToDelegationDetailsInput(usufruct.details) : null,
  ownerPartyId: usufruct.owner.id,
  shareSeries: mapShareSeriesToShareSeriesInput(usufruct.shareSeries),
});
