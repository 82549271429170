import type {
  Incorporation,
  Onboarding,
  Party,
  RegisterMutation,
  ShareType,
} from '@elseu/sdu-evidend-graphql';
import { MutationType } from '@elseu/sdu-evidend-graphql';
import { Block, Box, Text } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import type { SummaryCardsComponentProps } from 'helpers/formatMutation';
import { sortShareTypes } from 'helpers/formatShares';
import { useLabels } from 'hooks/useLabels';
import { uniqBy } from 'lodash';
import { getMutationField } from 'mutations/mutationFields';
import React, { useMemo } from 'react';
import { CapitalCardListItem } from 'summaries/CapitalCards/CapitalCardListItem';
import { ShareholderCapitalCard } from 'summaries/CapitalCards/ShareholderCapitalCard';
import { ShareTypeCapitalCard } from 'summaries/CapitalCards/ShareTypeCapitalCard';
import { DeedCard } from 'summaries/DeedCard/DeedCard';

const getShareDataOfParty = (
  mutation: RegisterMutation,
  party: Party,
): Incorporation[] | Onboarding[] => {
  const mutationField = getMutationField(mutation.type as MutationType.INCORPORATION);
  const shareDataArray = mutation[mutationField];
  if (!shareDataArray?.length) return [];

  return shareDataArray.filter(
    (shareData: Incorporation | Onboarding) => shareData.to.id === party.id,
  );
};

const getShareDateOfShareType = (
  mutation: RegisterMutation,
  shareType: ShareType,
): Incorporation[] | Onboarding[] => {
  const mutationField = getMutationField(mutation.type as MutationType.ONBOARDING);
  const shareDataArray = mutation[mutationField];
  if (!shareDataArray?.length) return [];

  return shareDataArray.filter((shareData) => shareData.shareSeries.shareType.id === shareType.id);
};

export const IncorporationSummaryCards = ({
  backgroundVariant = 'white',
  mutation,
  onDocumentSave,
}: SummaryCardsComponentProps) => {
  const { register } = mutation;
  const { legalEntity, shareTypes } = register;
  const labels = useLabels('issuance.summary', legalEntity.isFoundation);
  const mutationField: 'incorporation' | 'onboarding' = useMemo(
    () => (mutation.type === MutationType.INCORPORATION ? 'incorporation' : 'onboarding'),
    [mutation],
  );

  const parties = useMemo(
    () =>
      uniqBy(
        (mutation[mutationField] || []).map(
          (seriesData: Incorporation | Onboarding) => seriesData.to,
        ),
        'id',
      ),
    [mutation, mutationField],
  );

  return (
    <>
      {!!shareTypes.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            <Trans>Totale kapitaal</Trans>
          </Text>

          <Box spaceAfter={10}>
            {sortShareTypes(shareTypes).map((shareType) => (
              <ShareTypeCapitalCard
                key={shareType.id}
                backgroundVariant={backgroundVariant}
                shareType={shareType}
              >
                {getShareDateOfShareType(mutation, shareType).map((shareData, index) => (
                  <CapitalCardListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    mutationOptions={{ showPaidStatus: true }}
                    paid={shareData.paid}
                    shareSeries={shareData.shareSeries}
                  />
                ))}
              </ShareTypeCapitalCard>
            ))}
          </Box>
        </>
      )}

      {!!parties.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            {labels.shareholders}
          </Text>

          <Box spaceAfter={10}>
            {parties.map((party) => (
              <ShareholderCapitalCard
                key={party.id}
                backgroundVariant={backgroundVariant}
                compareDeceaseDate={mutation.effectiveDate}
                party={party}
              >
                {getShareDataOfParty(mutation, party).map((shareData, index) => (
                  <CapitalCardListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    mutationOptions={{ showPaidStatus: true }}
                    paid={shareData.paid}
                    shareSeries={shareData.shareSeries}
                  />
                ))}
              </ShareholderCapitalCard>
            ))}
          </Box>
        </>
      )}

      {mutation.document && (
        <Block spaceAfter={10}>
          <DeedCard
            backgroundVariant={backgroundVariant}
            document={mutation.document}
            mutation={mutation}
            onDocumentSave={onDocumentSave}
          />
        </Block>
      )}
    </>
  );
};
