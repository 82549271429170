import type { Transfer, TransferInput } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import { mergeCards } from 'forms/helpers/mergeCards';
import { shareSeriesInputRowSchemaNoPaid } from 'mutations/seriesDataCards';
import * as yup from 'yup';

export type TransferCard = yup.Asserts<ReturnType<typeof getTransferCardSchema>>;

export const mapTransferToCard = ({ from, to = null, ...transfer }: Transfer) => ({
  from,
  to,
  seriesData: [transfer],
});

export const mergeTransferPredicate = (a: any, b: any) =>
  a.from.id === b.from.id && a.to?.id === b.to?.id;
const mergeTransferCards = mergeCards<TransferCard>(mergeTransferPredicate);

export const mapTransfersToCards = (transfers: Transfer[], defaultCards: TransferCard[] = []) =>
  transfers.reduce(
    (cards, transfer) => mergeTransferCards(cards, mapTransferToCard(transfer)),
    defaultCards,
  );

export const mapCardsToTransferInput = (cards: TransferCard[]): TransferInput[] =>
  cards.flatMap((card) =>
    card.seriesData.map((transfer) => ({
      fromPartyId: card.from.id,
      toPartyId: card.to?.id,
      shareSeries: {
        from: transfer.shareSeries.from,
        shareTypeId: transfer.shareSeries.shareType.id,
        to: transfer.shareSeries.to,
      },
    })),
  );

// reference: https://github.com/jquense/yup/issues/1631
interface TestContextExtended extends yup.TestContext {
  from: Array<{
    value: any;
  }>;
}
export const getTransferCardSchema = () =>
  yup.object({
    from: yup.object({
      id: yup
        .string()
        .nullable()
        .required(t`Kies een partij als vervreemder`)
        .default(null),
    }),
    to: yup
      .object({
        id: yup
          .string()
          .nullable()
          .test(
            'samePartyRef',
            t`Vervreemder en verkrijger mogen niet hetzelfde zijn`,
            (id, context) => {
              // Only invalid if both fields are filled and they match.
              const { from } = context as TestContextExtended;
              const fromId = from[1].value.from.id;
              return !(id && fromId && id === fromId);
            },
          )
          .required(t`Kies een partij als verkrijger`)
          .default(null),
      })
      .nullable(),
    seriesData: yup
      .array()
      .min(1)
      .of(shareSeriesInputRowSchemaNoPaid)
      .required()
      .default([shareSeriesInputRowSchemaNoPaid.getDefault()]),
  });
