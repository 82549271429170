import type { Pledge, PledgeInput } from '@elseu/sdu-evidend-graphql';

import { mapDelegationDetailsToDelegationDetailsInput } from './DelegationDetails';
import { mapShareSeriesToShareSeriesInput } from './ShareSeries';

export const mapPledgeToPledgeInput = (pledge: Pledge): PledgeInput => ({
  ownerPartyId: pledge.owner.id,
  shareSeries: mapShareSeriesToShareSeriesInput(pledge.shareSeries),
  details: pledge.details ? mapDelegationDetailsToDelegationDetailsInput(pledge.details) : null,
});
