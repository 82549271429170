import type { ListBoxItemProps } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';

const booleanOptions = (): ListBoxItemProps[] => [
  {
    label: t`Ja`,
    value: 'true',
  },
  {
    label: t`Nee`,
    value: 'false',
  },
];

const booleanTransform = {
  input: (value: any) => (value ? 'true' : 'false'),
  output: (value: string) => value === 'true',
};

export { booleanOptions, booleanTransform };
