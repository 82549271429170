import { useMutation } from '@apollo/client';
import type { LegalEntity, NaturalPerson } from '@elseu/sdu-evidend-graphql';
import { PartyType } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import type { PartyCreateFormValues } from 'forms/mutation/PartyCreateForm';
import { CREATE_LEGAL_ENTITY } from 'graphql/queries/legalEntity';
import { CREATE_NATURAL_PERSON } from 'graphql/queries/naturalPerson';
import { useCallback } from 'react';
import type {
  CreateLegalEntity,
  CreateLegalEntityVariables,
} from 'types/graphql/CreateLegalEntity';
import type {
  CreateNaturalPerson,
  CreateNaturalPersonVariables,
} from 'types/graphql/CreateNaturalPerson';

export const useCreateNaturalPerson = (registerId: string) => {
  const [createNaturalPerson] = useMutation<CreateNaturalPerson, CreateNaturalPersonVariables>(
    CREATE_NATURAL_PERSON,
  );
  return useCallback(
    async (party: PartyCreateFormValues & { naturalPerson: NaturalPerson }) => {
      const response = await createNaturalPerson({
        variables: {
          registerId,
          naturalPerson: party.naturalPerson,
        },
      });
      if (response.errors || !response.data)
        return {
          errors: response.errors,
          message: t`De natuurlijke persoon kon niet worden aangemaakt. Probeer het alstublieft opnieuw.`,
        };

      const { createNaturalPerson: naturalPerson } = response.data;
      return {
        id: naturalPerson.id,
        naturalPerson,
        type: PartyType.NATURAL_PERSON,
      };
    },
    [createNaturalPerson, registerId],
  );
};

export const useCreateLegalEntity = (registerId: string) => {
  const [createLegalEntity] = useMutation<CreateLegalEntity, CreateLegalEntityVariables>(
    CREATE_LEGAL_ENTITY,
  );
  return useCallback(
    async (party: PartyCreateFormValues & { legalEntity: LegalEntity }) => {
      const response = await createLegalEntity({
        variables: {
          registerId,
          legalEntity: party.legalEntity,
        },
      });
      if (response.errors || !response.data)
        return {
          errors: response.errors,
          message: t`De rechtspersoon kon niet worden aangemaakt. Probeer het alstublieft opnieuw.`,
        };

      const { createLegalEntity: legalEntity } = response.data;
      return {
        id: legalEntity.id,
        legalEntity,
        type: PartyType.LEGAL_ENTITY,
      };
    },
    [createLegalEntity, registerId],
  );
};
