import { useWizardContext, WizardSummary } from 'components/Wizard';
import { LegalEntityPersonalInfo } from 'legalEntities/components/LegalEntityPersonalInfo';
import { ConversionSummaryCards } from 'summaries/ConversionSummaryCards/ConversionSummaryCards';

export const ConversionSummary = () => {
  const { mutation } = useWizardContext();

  return (
    <WizardSummary>
      <LegalEntityPersonalInfo
        hasHeaderButton={false}
        legalEntityId={mutation.register.legalEntity.id}
      />

      <ConversionSummaryCards mutation={mutation} />
    </WizardSummary>
  );
};
