import type { StepProps } from '@elseu/sdu-titan';
import type { useSaveRegisterMutation } from 'forms/mutation/useSaveRegisterMutation';
import React from 'react';

export type IWizardContext = {
  step: string;
  steps: StepProps[];
  visitStep: (step: string, replace?: boolean) => void;
  visitNextStep: () => void;
  nextStep: string;
  previousStep: string;
} & ReturnType<typeof useSaveRegisterMutation>;

// The default context value should not be used anywhere.
export const WizardContext = React.createContext<IWizardContext>(undefined!);
