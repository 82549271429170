import type { MutationFunctionOptions } from '@apollo/client';
import type { RegisterMutation } from '@elseu/sdu-evidend-graphql';
import { mapMutationToMutationInput } from 'graphql/input-mappers/RegisterMutation';
import { useGlobalError } from 'hooks/useGlobalError';
import { useUpdateMutation } from 'mutations/hooks/useUpdateMutation';
import { useMutationQueryProvider } from 'mutations/providers/MutationQueryProvider';
import { useCallback } from 'react';
import type { UpdateMutation, UpdateMutationVariables } from 'types/graphql/UpdateMutation';

type ValidUseSaveCallbacks = Pick<
  MutationFunctionOptions<UpdateMutation, UpdateMutationVariables>,
  'onError' | 'onCompleted'
>;
export type SaveMutation = {
  mutation: RegisterMutation;
} & ValidUseSaveCallbacks;

/** Can be used if the form and mutation are seperated. It can be passed a mutation of type RegisterMutation, and it will try to save it. */
const useSaveRegisterMutation = () => {
  const [mutation, { refetch: refetchMutation }] = useMutationQueryProvider();
  const [updateMutation] = useUpdateMutation();
  const [, setGlobalError] = useGlobalError();

  const saveMutation = useCallback(
    async ({ mutation: newMutation, ...props }: SaveMutation) => {
      setGlobalError(undefined);
      // const mergedMutation = mergeWith({}, mutation, newMutation, customizer);
      const mutationInput = mapMutationToMutationInput(newMutation);
      await updateMutation({
        variables: {
          mutationId: newMutation.id || mutation.id,
          mutation: mutationInput,
        },
        ...props,
      });
      return refetchMutation();
    },
    [mutation, setGlobalError, updateMutation, refetchMutation],
  );

  return { mutation, saveMutation, refetchMutation } as const;
};

export { useSaveRegisterMutation };
