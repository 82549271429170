import { gql } from '@apollo/client';

import {
  LEGAL_ENTITY_BASE_FRAGMENT,
  PARTY_FRAGMENT,
  PARTY_UPDATE_FRAGMENT,
  REGISTER_FRAGMENT,
} from '../fragments';

export const LEGAL_ENTITY_QUERY = gql`
  query LegalEntityQuery($legalEntityId: ID!) {
    legalEntity(legalEntityId: $legalEntityId) {
      id
      ...LegalEntityFields

      lastFetchedFromKvkOn

      phoneNumber
      emailAddress

      register {
        ...RegisterFields
      }

      entitlement

      partyUpdates {
        ...PartyUpdateFields
      }
    }
  }
  ${LEGAL_ENTITY_BASE_FRAGMENT}
  ${REGISTER_FRAGMENT}
  ${PARTY_UPDATE_FRAGMENT}
`;

export const KVK_LOOKUP_QUERY = gql`
  query KvkLookup($kvkNumber: String!) {
    kvkLookup(kvkNumber: $kvkNumber) {
      id
      ...LegalEntityFields

      lastFetchedFromKvkOn
    }
  }
  ${LEGAL_ENTITY_BASE_FRAGMENT}
`;

export const SHAREHOLDER_GRAPH_QUERY = gql`
  query ShareholderGraph(
    $legalEntityId: ID!
    $groupBy: ShareholderGraphGroupBy!
    $shareholderGraphType: ShareholderGraphType
  ) {
    shareholderGraph(
      legalEntityId: $legalEntityId
      groupBy: $groupBy
      shareholderGraphType: $shareholderGraphType
    ) {
      nodes {
        party {
          ...PartyFields
        }
      }
      edges {
        from {
          id
          type
        }
        to {
          id
          type
        }
        quantity
        percentage
      }
    }
  }
  ${PARTY_FRAGMENT}
`;

export const CREATE_LEGAL_ENTITY = gql`
  mutation CreateLegalEntity($registerId: ID, $legalEntity: LegalEntityInput!) {
    createLegalEntity(registerId: $registerId, legalEntity: $legalEntity) {
      id
      ...LegalEntityFields

      phoneNumber
      emailAddress
    }
  }

  ${LEGAL_ENTITY_BASE_FRAGMENT}
`;

export const UPDATE_LEGAL_ENTITY = gql`
  mutation UpdateLegalEntity(
    $legalEntityId: ID!
    $legalEntity: LegalEntityInput!
    $effectiveDate: Date
    $reason: String
  ) {
    updateLegalEntity(
      legalEntityId: $legalEntityId
      legalEntity: $legalEntity
      effectiveDate: $effectiveDate
      reason: $reason
    ) {
      id
      ...LegalEntityFields

      phoneNumber
      emailAddress
    }
  }

  ${LEGAL_ENTITY_BASE_FRAGMENT}
`;

export const ADD_KVK_NUMBER_TO_LEGAL_ENTITY = gql`
  mutation AddKvkNumberToLegalEntity($legalEntityId: ID!, $kvkNumber: String!) {
    addKvkNumberToLegalEntity(legalEntityId: $legalEntityId, kvkNumber: $kvkNumber) {
      id
      ...LegalEntityFields

      lastFetchedFromKvkOn
    }
  }

  ${LEGAL_ENTITY_BASE_FRAGMENT}
`;

export const ADD_LEGAL_ENTITY_TO_REGISTER = gql`
  mutation AddLegalEntityToRegister($legalEntityId: ID!, $registerId: ID!) {
    addLegalEntityToRegister(legalEntityId: $legalEntityId, registerId: $registerId) {
      id
      ...LegalEntityFields
    }
  }

  ${LEGAL_ENTITY_BASE_FRAGMENT}
`;
