import type { RegisterMutation } from '@elseu/sdu-evidend-graphql';
import type { StepProps } from '@elseu/sdu-titan';
import { useSaveRegisterMutation } from 'forms/mutation/useSaveRegisterMutation';
import { MutationQueryProvider } from 'mutations/providers/MutationQueryProvider';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';

import { incrementStep } from './helpers';
import { useVisitStep } from './useVisitStep';
import type { IWizardContext } from './WizardContext';
import { WizardContext } from './WizardContext';

interface IWizardProvider {
  children: React.ReactNode;
  determineSteps: (mutation: RegisterMutation, activeStep: any) => StepProps[];
}

const WizardStepProvider = ({ children, determineSteps }: IWizardProvider) => {
  const router = useRouter();

  // We assume all wizard routes contain a `mutationId` and `step` slug.
  const { step: stepSlug } = router.query;

  // Step can be passed as string or slug (which will be flattened to a string).
  const step = Array.isArray(stepSlug) ? stepSlug.join('/') : stepSlug ?? '';

  // All wizards have a mutation ID, so we can use the mutation query provider to get the mutation.
  const { mutation, saveMutation, refetchMutation } = useSaveRegisterMutation();

  // Change the active step by rewriting the current url to use the specified step.
  const visitStep = useVisitStep();
  // Calculate condition of all steps based on current step and workflow.

  const steps = useMemo(() => {
    return determineSteps(mutation as RegisterMutation, step).map((step, index) => ({
      ...step,
      step: step.step || index + 1,
    }));
  }, [determineSteps, mutation, step]);

  // Determine incremental steps
  const { nextStep, previousStep } = useMemo(
    () => ({
      nextStep: incrementStep(1, steps, step),
      previousStep: incrementStep(-1, steps, step),
    }),
    [steps, step],
  );

  const context: IWizardContext = useMemo(
    () => ({
      step,
      steps,
      visitStep,
      visitNextStep: () => visitStep(nextStep),
      nextStep,
      previousStep,
      mutation,
      saveMutation,
      refetchMutation,
    }),
    [step, steps, visitStep, nextStep, previousStep, mutation, saveMutation, refetchMutation],
  );

  return <WizardContext.Provider value={context}>{children}</WizardContext.Provider>;
};

export const WizardProvider = ({ children, determineSteps }: IWizardProvider) => {
  return (
    <MutationQueryProvider>
      <WizardStepProvider determineSteps={determineSteps}>{children}</WizardStepProvider>
    </MutationQueryProvider>
  );
};
