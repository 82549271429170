import React from 'react';

interface ILoaderIcon {
  size?: number;
}

export const LoaderIcon = ({ size = 20 }: ILoaderIcon) => {
  return (
    <svg
      data-test-class="loaderSpinner"
      height={size}
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0h100v100H0z" fill="none" />
      <mask id="mask">
        <path d="M0 0h100v100H0z" fill="white" />
        <circle
          cx="50"
          cy="50"
          fill="none"
          r="40"
          stroke="black"
          strokeLinecap="round"
          strokeWidth="6"
        >
          <animate
            attributeName="stroke-dashoffset"
            dur="3s"
            from="502"
            repeatCount="indefinite"
            to="0"
          />
          <animate
            attributeName="stroke-dasharray"
            dur="3s"
            repeatCount="indefinite"
            values="150.6 100.4;1 250;150.6 100.4"
          />
        </circle>
      </mask>

      <circle
        cx="50"
        cy="50"
        fill="none"
        mask="url(#mask)"
        r="40"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="6"
      />
    </svg>
  );
};
