import type { Document, DocumentInput, DocumentLabel, Maybe } from '@elseu/sdu-evidend-graphql';
import { pick } from 'lodash';

type MapDocumentToDocumentInputProps = Pick<
  Document,
  'type' | 'date' | 'commentary' | 'deedPassed'
> & {
  labels?: Maybe<DocumentLabel[]>;
};
export const mapDocumentToDocumentInput = (
  document: MapDocumentToDocumentInputProps,
): DocumentInput => ({
  ...pick(document, ['type', 'date', 'labels', 'commentary', 'deedPassed']),
  labels: document.labels || [],
});
