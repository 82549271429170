import { gql } from '@apollo/client';

import { NAME_FRAGMENT, PARTY_UPDATE_FRAGMENT, POSTAL_ADDRESS_FRAGMENT } from '../fragments';

export const PARTY_DASHBOARD_QUERY = gql`
  query PartyDashboardQuery($filter: PartySearchFilterInput, $pagination: PaginationInput!) {
    partySearch(filter: $filter, pagination: $pagination) {
      items {
        legalEntity {
          id
          name
          tradeRegisterNumber
          countryOfRegistration
          register {
            id
            isSigned
            signatures {
              id
            }
          }
        }
      }
      pagination {
        totalPages
        totalCount
        page
        count
      }
    }
  }
`;

export const PARTY_SEARCH_QUERY = gql`
  query PartySearchQuery($filter: PartySearchFilterInput, $pagination: PaginationInput!) {
    partySearch(filter: $filter, pagination: $pagination) {
      items {
        naturalPerson {
          id
          dateOfBirth
          placeOfBirth
          postalAddress {
            ...PostalAddressFields
          }
          tag
          name {
            ...NameFields
          }
        }
        legalEntity {
          id
          name
          tradeRegisterNumber
          countryOfRegistration
          visitorAddress {
            ...PostalAddressFields
          }
          register {
            id
            entitlement
            requestForAccess {
              id
            }
          }
        }
      }
      pagination {
        totalPages
        totalCount
        page
        count
      }
    }
  }
  ${POSTAL_ADDRESS_FRAGMENT}
  ${NAME_FRAGMENT}
`;

export const KVK_UPDATES_QUERY = gql`
  query KvkUpdates($partyId: ID!) {
    kvkUpdates(partyId: $partyId) {
      ...PartyUpdateFields
    }
  }
  ${PARTY_UPDATE_FRAGMENT}
`;

export const SAVE_PARTY_UPDATES_MUTATION = gql`
  mutation SavePartyUpdates($partyUpdates: [PartyUpdateInput!]!, $document: DocumentInput) {
    savePartyUpdates(partyUpdates: $partyUpdates, document: $document) {
      ...PartyUpdateFields
    }
  }
  ${PARTY_UPDATE_FRAGMENT}
`;
