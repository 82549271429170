import { t } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import { DatePickerField } from 'components/FormFields/DatePickerField';
import { InputField } from 'components/FormFields/InputField';
import { SelectField } from 'components/FormFields/SelectField';
import { dateBasicValidation } from 'forms/helpers/dateTransform';
import { getLegalFormOptions } from 'forms/helpers/getLegalFormOptions';
import React from 'react';
import * as yup from 'yup';

const getLegalEntityGeneralInfoFormSchema = () => {
  return yup.object({
    name: yup.string().trim().required(),
    legalForm: yup.string().trim().required(),
    incorporatedOn: dateBasicValidation,
    countryOfRegistration: yup.string().trim().nullable(),
  });
};

export const legalEntityGeneralInfoFormSchema = getLegalEntityGeneralInfoFormSchema();

export type LegalEntityGeneralInfoFormValues = yup.InferType<
  typeof legalEntityGeneralInfoFormSchema
>;

interface ILegalEntityGeneralInfoForm {
  nestedField?: string;
  hasColumns?: boolean;
}
const LegalEntityGeneralInfoForm = ({
  nestedField = '',
  hasColumns = true,
}: ILegalEntityGeneralInfoForm) => {
  const legalFormOptions = getLegalFormOptions();
  return (
    <>
      <Columns count={hasColumns ? 2 : 1}>
        <Column>
          <InputField required label={t`Naam`} name={`${nestedField}name`} placeholder={t`Naam`} />
        </Column>
        <Column>
          <SelectField
            required
            defaultValue={legalFormOptions[0].value}
            label={t`Rechtsvorm`}
            name={`${nestedField}legalForm`}
            options={legalFormOptions}
            placeholder={t`Rechtsvorm`}
          />
        </Column>
      </Columns>

      <Columns count={hasColumns ? 2 : 1}>
        <Column>
          <DatePickerField
            isFutureDisabled
            required
            label={t`Oprichtingsdatum`}
            name={`${nestedField}incorporatedOn`}
            placeholder={t`Oprichtingsdatum`}
          />
        </Column>
      </Columns>
    </>
  );
};

export {
  LegalEntityGeneralInfoForm,
  legalEntityGeneralInfoFormSchema as LegalEntityGeneralInfoFormSchema,
};
