import type { MutationHookOptions } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { UPDATE_MUTATION } from 'graphql/queries/mutation';
import type { UpdateMutation, UpdateMutationVariables } from 'types/graphql/UpdateMutation';

const useUpdateMutation = (
  options?: MutationHookOptions<UpdateMutation, UpdateMutationVariables>,
) => useMutation<UpdateMutation, UpdateMutationVariables>(UPDATE_MUTATION, options);

export { useUpdateMutation };
