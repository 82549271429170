import type { RegisterMutation, ShareType } from '@elseu/sdu-evidend-graphql';
import { Box, Card, Divider, Flex, Link, PlusIcon } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import type { OnPartyCreateFn } from 'forms/mutation/PartyCreateForm';
import { ShareSeriesInputRow } from 'forms/mutation/ShareSeriesInputRow';
import { ShareTypeSelect } from 'forms/mutation/ShareTypeSelect';
import { formatShareTypeName } from 'helpers/formatShares';
import { shareSeriesInputRowPaidSchema } from 'mutations/seriesDataCards';
import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

import { setConversionsToExisting } from './conversionCards';
import { hasSingleShareType } from './conversionFilters';

interface IConversionShareCard {
  labels: any;
  mutation: RegisterMutation;
  nestedField: string;
  shareTypes: ShareType[];
  onDelete: any;
  onPartyCreate: OnPartyCreateFn;
  onShareTypeCreate: (shareType: ShareType) => any;
}
const ConversionShareCard = ({
  mutation,
  labels,
  nestedField,
  onDelete,
  onPartyCreate,
  onShareTypeCreate,
}: IConversionShareCard) => {
  const { control } = useFormContext();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: `${nestedField}seriesData`,
    keyName: 'key',
  });
  const shareTypeId = useWatch({ control, name: `${nestedField}shareType.id` });
  const { shareTypes } = mutation.register;

  const setSeriesToExisting = () => {
    const conversions = setConversionsToExisting(mutation.conversion || []);
    if (conversions.length === 1) replace(conversions[0].seriesData);
  };
  const isSeriesToExistingAllowed = hasSingleShareType(mutation.conversion);

  const shareType = shareTypeId && shareTypes.find((shareType) => shareType.id === shareTypeId);
  const title = shareType?.id ? formatShareTypeName(shareType) : t`Nieuwe conversie`;
  return (
    <Card
      footer={
        <Link onClick={onDelete}>
          <Trans>Verwijderen</Trans>
        </Link>
      }
      spaceAfter={4}
      title={title}
    >
      <>
        <Columns count={2} spaceAfter={4}>
          <Column>
            <ShareTypeSelect
              isCreateAllowed
              required
              label={labels?.shareTypeColumnHeader}
              mutationId={mutation.id}
              nestedField={nestedField}
              register={mutation.register}
              shareTypes={shareTypes}
              onShareTypeCreate={onShareTypeCreate}
            />
          </Column>
        </Columns>
        <Divider spaceAfter={6} />

        <Box spaceAfter={6}>
          <Flex justifyContent="space-between">
            <div>
              {isSeriesToExistingAllowed && (
                <Link onClick={setSeriesToExisting}>
                  <Trans>Nummering behouden</Trans>
                </Link>
              )}
            </div>
            <Link
              prefixAdornment={<PlusIcon />}
              onClick={() => append(shareSeriesInputRowPaidSchema.getDefault())}
            >
              <Trans>Toevoegen</Trans>
            </Link>
          </Flex>
        </Box>

        {fields.map(({ key }, mutationIndex) => (
          <ShareSeriesInputRow
            key={key}
            hasPartySelect
            isPartyCreateAllowed
            required
            hasPaid={!mutation.register.legalEntity.isFoundation}
            hasShareTypeSelect={false}
            isLabelHidden={mutationIndex !== 0}
            labels={labels}
            mutation={mutation}
            nestedField={`${nestedField}seriesData[${mutationIndex}].`}
            onPartyCreate={onPartyCreate}
            onRemove={() => remove(mutationIndex)}
          />
        ))}
      </>
    </Card>
  );
};

export { ConversionShareCard };
