import type { Conversion, ConversionInput } from '@elseu/sdu-evidend-graphql';

import { mapShareSeriesToShareSeriesInput } from './ShareSeries';

export const mapConversionToConversionInput = (conversion: Conversion): ConversionInput => ({
  ownerPartyId: conversion.owner.id,
  shareSeries: mapShareSeriesToShareSeriesInput(conversion.shareSeries),
  action: conversion.action,
  paid: conversion.paid,
});
