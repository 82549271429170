import styled, { css } from 'styled-components';

interface IProgressBar {
  variant?: 'primary' | 'secondary' | 'danger' | 'success';
  value?: number;
}

export const ProgressBar = styled.div<IProgressBar>`
  height: 0.25rem;
  background-color: ${({ theme, variant = 'primary' }) =>
    theme.designTokens.colors[`${variant}50`]};
  width: ${({ value }) => (value || 0) * 100}%;
  ${({ value }) =>
    value &&
    css`
      transition: width 300ms ease;
    `}
`;
