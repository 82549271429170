import { Flex, OkCircleIcon } from '@elseu/sdu-titan';
import React from 'react';
import styled from 'styled-components';

const GreenOkCircle = styled(OkCircleIcon)`
  path {
    fill: ${(props) => props.theme.designTokens.colors.success70};
  }
`;

export const GreenOkCircleIcon = () => {
  return <GreenOkCircle />;
};

export const GreenOkCircleTableCell = ({ isGreen }: { isGreen: boolean }) => {
  return isGreen ? (
    <Flex justifyContent="center">
      <GreenOkCircleIcon />
    </Flex>
  ) : (
    <>&nbsp;</>
  );
};
