import type { Party, Pledge } from '@elseu/sdu-evidend-graphql';
import { Block, Box, Text } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import type { SummaryCardsComponentProps } from 'helpers/formatMutation';
import { useLabels } from 'hooks/useLabels';
import { uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import { CapitalCardListItem } from 'summaries/CapitalCards/CapitalCardListItem';
import { ShareholderCapitalCard } from 'summaries/CapitalCards/ShareholderCapitalCard';
import { DeedCard } from 'summaries/DeedCard/DeedCard';
import DelegateRightsCard from 'summaries/DelegateRightsCard/DelegateRightsCard';
import PartyCard from 'summaries/PartyCard/PartyCard';

const getPledgesOfParty = (pledges: Pledge[], party: Party): Pledge[] =>
  pledges.filter((pledge) => pledge.owner.id === party.id);

export const PledgeSummaryCards = ({
  backgroundVariant = 'white',
  mutation,
  onDocumentSave,
}: SummaryCardsComponentProps) => {
  const { register } = mutation;
  const { legalEntity } = register;
  const labels = useLabels('pledge.summary', legalEntity.isFoundation);
  const pledges = useMemo(() => mutation.pledge || [], [mutation.pledge]);
  const parties = useMemo(
    () =>
      uniqBy(
        pledges.map((pledge) => pledge.owner),
        'id',
      ),
    [pledges],
  );

  const delegatee = useMemo(() => pledges[0]?.details?.delegatee, [pledges]);
  const rights = useMemo(() => pledges[0]?.details, [pledges]);

  return (
    <>
      {!!parties.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            {labels.shares}
          </Text>

          <Box spaceAfter={10}>
            {parties.map((party) => (
              <ShareholderCapitalCard
                key={party.id}
                backgroundVariant={backgroundVariant}
                compareDeceaseDate={mutation.effectiveDate}
                party={party}
              >
                {getPledgesOfParty(pledges, party).map((pledge, index) => (
                  <CapitalCardListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    mutationOptions={{ showPaidStatus: true }}
                    shareSeries={pledge.shareSeries}
                  />
                ))}
              </ShareholderCapitalCard>
            ))}
          </Box>
        </>
      )}

      {!!delegatee && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            <Trans>Pandhouder</Trans>
          </Text>

          <PartyCard
            backgroundVariant={backgroundVariant}
            compareDeceaseDate={mutation.effectiveDate}
            party={delegatee}
          />
        </>
      )}
      {!!rights && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            <Trans>Rechten pandhouder</Trans>
          </Text>

          <DelegateRightsCard backgroundVariant={backgroundVariant} rights={rights} />
        </>
      )}

      {mutation.document && (
        <Block spaceAfter={10}>
          <DeedCard
            backgroundVariant={backgroundVariant}
            document={mutation.document}
            mutation={mutation}
            onDocumentSave={onDocumentSave}
          />
        </Block>
      )}
    </>
  );
};
