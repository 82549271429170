import type { LegalEntity } from '@elseu/sdu-evidend-graphql';
import { Card, List, ListItem } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { KvkInputField } from 'components/FormFields/KvkInputField';
import { formatLegalForm } from 'helpers/formatLegalForm';
import { formatDate } from 'helpers/formatShares';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

interface IKvkSidebarForm {
  defaultValue?: string;
  onLookup?: (legalEntity: LegalEntity) => void;
}

export const getKvkFormSchema = () =>
  yup.object({
    kvkNumber: yup.string().trim().required(),
  });

export const kvkFormSchema = getKvkFormSchema();
export type KvkFormValues = yup.InferType<typeof kvkFormSchema>;

export const KvkSidebarForm = ({ defaultValue }: IKvkSidebarForm) => {
  const { setValue } = useFormContext();
  const [legalEntity, setLegalEntity] = useState<LegalEntity>();

  return (
    <>
      <KvkInputField
        defaultValue={defaultValue}
        onLookup={(lookupLegalEntity: LegalEntity) => {
          // Since this sidebar is only used to link existing legalEntity to kvk
          // we do not use the id of the new kvk lookup and let the backend decide if merging is possible
          setLegalEntity(lookupLegalEntity);
          setValue('kvkNumber', lookupLegalEntity.tradeRegisterNumber, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }}
      />
      {legalEntity && (
        <Card backgroundVariant="grey" showDivider={false} title={legalEntity.name}>
          <List spaceBetween={0}>
            <ListItem prefixAdornment="•">
              <Trans>Oprichtingsdatum</Trans>: {formatDate(legalEntity.incorporatedOn as Date)}
            </ListItem>
            <ListItem prefixAdornment="•">
              <Trans>RSIN</Trans>: {legalEntity.rsinNumber}
            </ListItem>
            <ListItem prefixAdornment="•">
              <Trans>Rechtsvorm</Trans>: {formatLegalForm(legalEntity)}
            </ListItem>
          </List>
        </Card>
      )}
    </>
  );
};
