import type { RegisterMutation, RegisterMutationInput } from '@elseu/sdu-evidend-graphql';

import { mapAdministrationToAdministrationInput } from './Administration';
import { mapAttachmentToAttachmentInput } from './Attachment';
import { mapConversionToConversionInput } from './Conversion';
import { mapDocumentToDocumentInput } from './Document';
import { mapEndAdministrationToEndAdministrationInput } from './EndAdministration';
import { mapEndAttachmentToEndAttachmentInput } from './EndAttachment';
import { mapEndPledgeToEndPledgeInput } from './EndPledge';
import { mapEndUsufructToEndUsufructInput } from './EndUsufruct';
import { mapIncorporationToIncorporationInput } from './Incorporation';
import { mapIssuanceToIssuanceInput } from './Issuance';
import { mapLiquidationToLiquidationInput } from './Liquidation';
import { mapOnboardingToOnboardingInput } from './Onboarding';
import { mapPayingUpToPayingUpInput } from './PayingUp';
import { mapPledgeToPledgeInput } from './Pledge';
import { mapTransferToTransferInput } from './Transfer';
import { mapUsufructToUsufructInput } from './Usufruct';
import { mapWithdrawalToWithdrawalInput } from './Withdrawal';
const mapValues =
  <Mapper extends (...args: never[]) => unknown>(mapper: Mapper) =>
  <Values extends unknown[]>(array?: [...Values]) =>
    Array.isArray(array) ? ([...array].map(mapper as never) as Array<ReturnType<Mapper>>) : null;

const mappers = {
  administration: mapValues(mapAdministrationToAdministrationInput),
  attachment: mapValues(mapAttachmentToAttachmentInput),
  conversion: mapValues(mapConversionToConversionInput),
  endAdministration: mapValues(mapEndAdministrationToEndAdministrationInput),
  endAttachment: mapValues(mapEndAttachmentToEndAttachmentInput),
  endPledge: mapValues(mapEndPledgeToEndPledgeInput),
  endUsufruct: mapValues(mapEndUsufructToEndUsufructInput),
  incorporation: mapValues(mapIncorporationToIncorporationInput),
  issuance: mapValues(mapIssuanceToIssuanceInput),
  onboarding: mapValues(mapOnboardingToOnboardingInput),
  payingUp: mapValues(mapPayingUpToPayingUpInput),
  pledge: mapValues(mapPledgeToPledgeInput),
  transfer: mapValues(mapTransferToTransferInput),
  usufruct: mapValues(mapUsufructToUsufructInput),
  withdrawal: mapValues(mapWithdrawalToWithdrawalInput),
  liquidation: mapLiquidationToLiquidationInput,
  document: mapDocumentToDocumentInput,
  isDraft: Boolean,
  effectiveDate: (date: string) => new Date(date),
  acknowledgedDate: (date: string) => new Date(date),
  title: String,
} as const;
type Mappers = typeof mappers;

//* similar to mapMutationToMutationInput, but with more type safety */
export const mapToUpdateMutationInput = <T extends { [k in keyof Mappers & keyof T]: T[k] }>(
  mutation: T,
) =>
  Object.fromEntries(
    Object.entries(mutation!)
      .map(([key, mutationValue]) =>
        key in mappers && mutationValue !== null
          ? [key, (mappers as any)[key](mutationValue)]
          : null,
      )
      .filter(Array.isArray),
  ) as {
    [k in keyof Mappers]: ReturnType<Mappers[k]>;
  };

export const mapMutationToMutationInput = (mutation: RegisterMutation): RegisterMutationInput => {
  const { document, liquidation, effectiveDate, acknowledgedDate, title } = mutation;
  const administration = mutation.administration
    ? mutation.administration.map(mapAdministrationToAdministrationInput)
    : null;
  const attachment = mutation.attachment
    ? mutation.attachment.map(mapAttachmentToAttachmentInput)
    : null;
  const conversion = mutation.conversion
    ? mutation.conversion.map(mapConversionToConversionInput)
    : null;
  const endAdministration = mutation.endAdministration
    ? mutation.endAdministration.map(mapEndAdministrationToEndAdministrationInput)
    : null;
  const endAttachment = mutation.endAttachment
    ? mutation.endAttachment.map(mapEndAttachmentToEndAttachmentInput)
    : null;
  const endPledge = mutation.endPledge
    ? mutation.endPledge.map(mapEndPledgeToEndPledgeInput)
    : null;
  const endUsufruct = mutation.endUsufruct
    ? mutation.endUsufruct.map(mapEndUsufructToEndUsufructInput)
    : null;
  const incorporation = mutation.incorporation
    ? mutation.incorporation.map(mapIncorporationToIncorporationInput)
    : null;
  const issuance = mutation.issuance ? mutation.issuance.map(mapIssuanceToIssuanceInput) : null;
  const onboarding = mutation.onboarding
    ? mutation.onboarding.map(mapOnboardingToOnboardingInput)
    : null;
  const payingUp = mutation.payingUp ? mutation.payingUp.map(mapPayingUpToPayingUpInput) : null;
  const pledge = mutation.pledge ? mutation.pledge.map(mapPledgeToPledgeInput) : null;
  const transfer = mutation.transfer ? mutation.transfer.map(mapTransferToTransferInput) : null;
  const usufruct = mutation.usufruct ? mutation.usufruct.map(mapUsufructToUsufructInput) : null;
  const withdrawal = mutation.withdrawal
    ? mutation.withdrawal.map(mapWithdrawalToWithdrawalInput)
    : null;

  return {
    isDraft: mutation.isDraft,
    document: document ? mapDocumentToDocumentInput(document) : null,
    administration,
    attachment,
    conversion,
    endAdministration,
    endAttachment,
    endPledge,
    endUsufruct,
    incorporation,
    issuance,
    liquidation,
    onboarding,
    payingUp,
    pledge,
    transfer,
    usufruct,
    withdrawal,
    effectiveDate,
    acknowledgedDate,
    title,
  };
};
