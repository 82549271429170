import type { QueryResult } from '@apollo/client';
import type { RegisterMutation } from '@elseu/sdu-evidend-graphql';
import { Loader } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { useGlobalError } from 'hooks/useGlobalError';
import { useMutationQuery } from 'mutations/hooks/useMutationQuery';
import { useRouter } from 'next/router';
import { createTypedContextProvider } from 'providers/createTypedContextProvider';
import React, { useEffect } from 'react';
import type { Mutation, MutationVariables } from 'types/graphql/Mutation';

export interface MutationProviderProps {
  children: React.ReactNode;
}

export type SuccessfullMutationQueryResult = [
  RegisterMutation,
  Omit<QueryResult<Mutation, MutationVariables>, 'data'> & {
    data: { mutation: RegisterMutation };
  },
];
export const [useMutationQueryProvider, MutationQueryContextProvider] =
  createTypedContextProvider<SuccessfullMutationQueryResult>();

export const MutationQueryProvider = ({ children }: MutationProviderProps) => {
  const router = useRouter();
  const [, setGlobalError] = useGlobalError();
  const mutationId = router.query.mutationId as string;
  const result = useMutationQuery({
    variables: {
      mutationId,
    },
  });
  const [mutation, { error }] = result;

  useEffect(() => {
    if (error) {
      // Redirect to front page.
      console.warn(error);
      router.push('/');
      // Show specific error message.
      setGlobalError(t`De door jou opgevraagde workflow is niet meer beschikbaar.`);
    }
  }, [error, router, setGlobalError]);

  if (!mutation) return <Loader height={80} variant="spinner" />;
  return (
    <MutationQueryContextProvider value={result as SuccessfullMutationQueryResult}>
      {children}
    </MutationQueryContextProvider>
  );
};
