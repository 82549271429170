import type { LegalEntity, Maybe, PartyUpdate, PostalAddress } from '@elseu/sdu-evidend-graphql';
import { PartyUpdateField } from '@elseu/sdu-evidend-graphql';
import { Box, Card, CardHeader, ChevronDownIcon, Flex, Text } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { formatAddressPostalCity, formatAddressStreet } from 'helpers/address';
import { formatLegalForm } from 'helpers/formatLegalForm';
import { formatCountryByCode } from 'helpers/formatShares';
import { partyUpdateFieldObject } from 'helpers/partyUpdate';
import { cloneDeep, set } from 'lodash';
import styled from 'styled-components';

const StyledSpan = styled.span<{ hasLineThrough: boolean }>`
  ${({ hasLineThrough }) => hasLineThrough && `text-decoration: line-through;`}
`;

interface CardDescriptionProps {
  children: React.ReactNode;
  type?: 'label' | 'labelBold';
}
const CardDescription = ({ children, type = 'label' }: CardDescriptionProps) => (
  <Text isBlock color={type === 'label' ? 'grey70' : 'grey80'} type={type}>
    {children}
  </Text>
);
const visitorAddressFields = [
  PartyUpdateField.VISITOR_ADDRESS_STREET_NAME,
  PartyUpdateField.VISITOR_ADDRESS_STREET_NUMBER,
  PartyUpdateField.VISITOR_ADDRESS_STREET_NUMBER_ADDITION,
  PartyUpdateField.VISITOR_ADDRESS_STREET_AND_NUMBER,
  PartyUpdateField.VISITOR_ADDRESS_POST_OFFICE_BOX_NUMBER,
  PartyUpdateField.VISITOR_ADDRESS_POSTAL_CODE,
  PartyUpdateField.VISITOR_ADDRESS_CITY,
  PartyUpdateField.VISITOR_ADDRESS_POSTAL_CODE_CITY,
  PartyUpdateField.VISITOR_ADDRESS_REGION,
  PartyUpdateField.VISITOR_ADDRESS_COUNTRY_CODE,
];
const correspondenceAddressFields = [
  PartyUpdateField.CORRESPONDENCE_ADDRESS_STREET_NAME,
  PartyUpdateField.CORRESPONDENCE_ADDRESS_STREET_NUMBER,
  PartyUpdateField.CORRESPONDENCE_ADDRESS_STREET_NUMBER_ADDITION,
  PartyUpdateField.CORRESPONDENCE_ADDRESS_STREET_AND_NUMBER,
  PartyUpdateField.CORRESPONDENCE_ADDRESS_POST_OFFICE_BOX_NUMBER,
  PartyUpdateField.CORRESPONDENCE_ADDRESS_POSTAL_CODE,
  PartyUpdateField.CORRESPONDENCE_ADDRESS_CITY,
  PartyUpdateField.CORRESPONDENCE_ADDRESS_POSTAL_CODE_CITY,
  PartyUpdateField.CORRESPONDENCE_ADDRESS_REGION,
  PartyUpdateField.CORRESPONDENCE_ADDRESS_COUNTRY_CODE,
];

interface AddressDescriptionProps {
  label: string;
  address?: Maybe<PostalAddress>;
  partyUpdateFields: PartyUpdateField[];
  addressFields: PartyUpdateField[];
}
const AddressDescription = ({
  label,
  address,
  partyUpdateFields,
  addressFields,
}: AddressDescriptionProps) => {
  if (!address) return null;
  return (
    <Box spaceAfter={2}>
      <CardDescription type="labelBold">{label}:</CardDescription>
      <StyledSpan hasLineThrough={addressFields.some((field) => partyUpdateFields.includes(field))}>
        <CardDescription>{formatAddressStreet(address)}</CardDescription>
        <CardDescription>{formatAddressPostalCity(address)}</CardDescription>
        {address.countryCode && (
          <CardDescription>{formatCountryByCode(address.countryCode)}</CardDescription>
        )}
      </StyledSpan>
    </Box>
  );
};

interface ApplyPartyUpdatesToLegalEntityProps {
  legalEntity: LegalEntity;
  partyUpdates: PartyUpdate[];
}
const applyPartyUpdatesToLegalEntity = ({
  legalEntity,
  partyUpdates,
}: ApplyPartyUpdatesToLegalEntityProps) => {
  const newLegalEntity = cloneDeep(legalEntity);
  partyUpdates.forEach((partyUpdate) => {
    const legalEntityField = partyUpdateFieldObject[partyUpdate.field].field;
    set(newLegalEntity, legalEntityField, partyUpdate.after);
  });
  return newLegalEntity;
};

interface PartyUpdatesCardsProps {
  legalEntity: LegalEntity;
  partyUpdates: PartyUpdate[];
}
export const PartyUpdatesCards = ({ legalEntity, partyUpdates }: PartyUpdatesCardsProps) => {
  const partyUpdateFields = partyUpdates.map((partyUpdate) => partyUpdate.field);
  const legalEntityWithUpdates = applyPartyUpdatesToLegalEntity({ legalEntity, partyUpdates });

  return (
    <>
      <Card backgroundVariant="grey" showDivider={false} spaceAfter={4}>
        <CardHeader>
          <Text isBlock color="grey80" spaceAfter={2} type="labelLargeBold">
            <StyledSpan
              hasLineThrough={partyUpdateFields.includes(PartyUpdateField.STATUTORY_NAME)}
            >
              {legalEntity.name}
            </StyledSpan>
          </Text>
        </CardHeader>
        <AddressDescription
          address={legalEntity.visitorAddress}
          addressFields={visitorAddressFields}
          label={t`Bezoekadres`}
          partyUpdateFields={partyUpdateFields}
        />
        <AddressDescription
          address={legalEntity.correspondenceAddress}
          addressFields={visitorAddressFields}
          label={t`Correspondentieadres`}
          partyUpdateFields={partyUpdateFields}
        />

        <CardDescription>
          <Trans>Land van handelsregister</Trans>:{' '}
          <StyledSpan
            hasLineThrough={partyUpdateFields.includes(PartyUpdateField.COUNTRY_OF_REGISTRATION)}
          >
            {formatCountryByCode(legalEntity.countryOfRegistration)}
          </StyledSpan>
        </CardDescription>
        <CardDescription>
          <Trans>Rechtsvorm</Trans>:{' '}
          <StyledSpan hasLineThrough={partyUpdateFields.includes(PartyUpdateField.LEGAL_FORM)}>
            {formatLegalForm(legalEntity)}
          </StyledSpan>
        </CardDescription>
      </Card>

      <Flex justifyContent="center" spaceAfter={4}>
        <Text color="grey60" type="headerSmall">
          <ChevronDownIcon />
        </Text>
      </Flex>

      <Card backgroundVariant="grey" showDivider={false} spaceAfter={4}>
        <CardHeader>
          <Text isBlock color="grey80" spaceAfter={2} type="labelLargeBold">
            {legalEntityWithUpdates.name}
          </Text>
        </CardHeader>
        <AddressDescription
          address={legalEntityWithUpdates.visitorAddress}
          addressFields={visitorAddressFields}
          label={t`Bezoekadres`}
          partyUpdateFields={[]}
        />
        <AddressDescription
          address={legalEntityWithUpdates.correspondenceAddress}
          addressFields={correspondenceAddressFields}
          label={t`Correspondentieadres`}
          partyUpdateFields={[]}
        />

        <CardDescription>
          <Trans>Land van handelsregister</Trans>:{' '}
          {formatCountryByCode(legalEntityWithUpdates.countryOfRegistration)}
        </CardDescription>
        <CardDescription>
          <Trans>Rechtsvorm</Trans>: {formatLegalForm(legalEntityWithUpdates)}
        </CardDescription>
      </Card>
    </>
  );
};
