import type { Register, ShareType } from '@elseu/sdu-evidend-graphql';
import { PlusIcon } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { SelectField } from 'components/FormFields/SelectField';
import { ShareTypeSidebarForm } from 'components/ShareTypeSidebarForm/ShareTypeSidebarForm';
import { ShareTypeFormMode } from 'forms/sharetype/ShareTypeEditForm';
import { formatShareTypeName, sortShareTypes } from 'helpers/formatShares';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

interface IShareTypeSelect {
  register: Register;
  shareTypes: ShareType[];
  isLabelHidden?: boolean;
  isCreateAllowed?: boolean;
  nestedField?: string;
  label?: string;
  createLabel?: string;
  required?: boolean;
  mutationId?: string;
  onShareTypeCreate?: (shareType: ShareType) => any;
}

export const ShareTypeSelect = ({
  register,
  label,
  shareTypes,
  isLabelHidden = false,
  isCreateAllowed = false,
  nestedField = '',
  createLabel,
  mutationId,
  required,
  onShareTypeCreate,
}: IShareTypeSelect) => {
  const { setValue } = useFormContext();
  const [isSidebarShown, setSidebarShown] = useState<boolean>(false);
  const options = useMemo(
    () =>
      sortShareTypes(shareTypes).map((st) => ({ label: formatShareTypeName(st), value: st.id })),
    [shareTypes],
  );

  const createOption = useCallback(
    () => ({
      value: 'create',
      label: createLabel || t`Toevoegen`,
      prefixAdornment: <PlusIcon />,
    }),
    [createLabel],
  );

  const isValidNewOption = useCallback(() => true, []);

  const handleShareTypeCreate = useCallback(
    (shareType: ShareType) => {
      setValue(`${nestedField}shareType.id`, shareType.id, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setSidebarShown(false);
      if (onShareTypeCreate) onShareTypeCreate(shareType);
    },
    [nestedField, onShareTypeCreate, setValue],
  );

  return (
    <>
      <SelectField
        isSearchable
        createOption={createOption}
        createOptionPosition="first"
        isClearable={!required}
        isCreateAllowed={isCreateAllowed}
        isLabelHidden={isLabelHidden}
        isValidNewOption={isValidNewOption}
        label={label || t`Soort aandelen`}
        name={`${nestedField}shareType.id`}
        options={options}
        placeholder={t`Kies een type`}
        popoverWidth={300}
        required={required}
        testId="selectShareType"
        onCreateOption={() => setSidebarShown(true)}
      />
      {isSidebarShown && (
        <ShareTypeSidebarForm
          isSidebarShown={isSidebarShown}
          mode={ShareTypeFormMode.Create}
          mutationId={mutationId}
          register={register}
          onSidebarClose={() => setSidebarShown(false)}
          onUpdate={handleShareTypeCreate}
        />
      )}
    </>
  );
};
