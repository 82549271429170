import type {
  AttachmentDetails,
  AttachmentDetailsInput,
  EndAttachment,
  EndAttachmentInput,
  Maybe,
} from '@elseu/sdu-evidend-graphql';

import { mapShareSeriesToShareSeriesInput } from './ShareSeries';

export const mapEndAttachmentDetailsToEndAttachmentDetailsInput = (
  attachmentDetails?: Maybe<AttachmentDetails>,
): AttachmentDetailsInput | null => {
  if (!attachmentDetails) return null;
  return {
    attachedAt: attachmentDetails.attachedAt,
    bailiffId: attachmentDetails.bailiff.id,
  };
};

export const mapEndAttachmentToEndAttachmentInput = (
  endAttachment: EndAttachment,
): EndAttachmentInput => ({
  ownerPartyId: endAttachment.owner.id,
  shareSeries: mapShareSeriesToShareSeriesInput(endAttachment.shareSeries),
  details: mapEndAttachmentDetailsToEndAttachmentDetailsInput(endAttachment.details),
});
