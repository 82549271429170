import type { EndAdministration, Party } from '@elseu/sdu-evidend-graphql';
import { Block, Box, Text } from '@elseu/sdu-titan';
import type { SummaryCardsComponentProps } from 'helpers/formatMutation';
import { useLabels } from 'hooks/useLabels';
import { uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import { CapitalCardListItem } from 'summaries/CapitalCards/CapitalCardListItem';
import { ShareholderCapitalCard } from 'summaries/CapitalCards/ShareholderCapitalCard';
import { DeedCard } from 'summaries/DeedCard/DeedCard';

const getEndAdministrationsOfParty = (
  endAdministrations: EndAdministration[],
  party: Party,
): EndAdministration[] =>
  endAdministrations.filter((endAdministration) => endAdministration.owner.id === party.id);

export const EndAdministrationSummaryCards = ({
  backgroundVariant = 'white',
  mutation,
  onDocumentSave,
}: SummaryCardsComponentProps) => {
  const { register } = mutation;
  const { legalEntity } = register;
  const labels = useLabels('endAdministration.summary', legalEntity.isFoundation);
  const endAdministrations = useMemo(
    () => mutation.endAdministration || [],
    [mutation.endAdministration],
  );
  const parties = useMemo(
    () =>
      uniqBy(
        endAdministrations.map((endAdministration) => endAdministration.owner),
        'id',
      ),
    [endAdministrations],
  );

  return (
    <>
      {!!parties.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            {labels.shares}
          </Text>

          <Box spaceAfter={10}>
            {parties.map((party) => (
              <ShareholderCapitalCard
                key={party.id}
                backgroundVariant={backgroundVariant}
                compareDeceaseDate={mutation.effectiveDate}
                party={party}
              >
                {getEndAdministrationsOfParty(endAdministrations, party).map(
                  (endAdministration, index) => (
                    <CapitalCardListItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      mutationOptions={{ showPaidStatus: true }}
                      shareSeries={endAdministration.shareSeries}
                    />
                  ),
                )}
              </ShareholderCapitalCard>
            ))}
          </Box>
        </>
      )}

      {mutation.document && (
        <Block spaceAfter={10}>
          <DeedCard
            backgroundVariant={backgroundVariant}
            document={mutation.document}
            mutation={mutation}
            onDocumentSave={onDocumentSave}
          />
        </Block>
      )}
    </>
  );
};
