import type { MutationType } from '@elseu/sdu-evidend-graphql';
import { DocumentLabel, DocumentType } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import FileSaver from 'file-saver';
import { upperFirst } from 'lodash';

import { mutationLabelObject } from './formatMutation';
import { capitalizeString } from './formatShares';

export const downloadFile = async (bytes: Uint8Array | Blob, name: string, mimeType?: string) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const blob =
    bytes instanceof Blob
      ? bytes
      : new Blob([bytes], { type: mimeType || 'application/octet-stream' });

  FileSaver.saveAs(blob, name);
};

export const isUnsupportedFileError = (message: string) => {
  return (
    message.match(/^Content type (.*) is not allowed$/) ||
    message.match(/^Filetype (.*) is not allowed$/) ||
    message === 'Content type is empty'
  );
};

const documentLabels = {
  [DocumentLabel.ARTICLE_OF_ASSOCIATION]: () => t`statuten`,
  [DocumentLabel.COC_EXTRACT]: () => '',
  [DocumentLabel.SHAREHOLDERS_AGREEMENT]: () => '',
  [DocumentLabel.SHAREHOLDERS_REGISTER]: () => '',
  [DocumentLabel.SHAREHOLDERS_RESOLUTION]: () => '',
  [DocumentLabel.TRUST_CONDITIONS]: () => t`administratievoorwaarden`,
};
export const formatDocumentLabel = (documentLabel: DocumentLabel) => {
  return upperFirst(documentLabels[documentLabel]());
};

const documentTypes = {
  [DocumentType.NOTARIAL_DEED]: () => t`notariële akte`,
  [DocumentType.PRIVATE_DEED]: () => t`onderhandse akte`,
  [DocumentType.OTHER]: () => t`document`,
};

export const formatDocumentType = (
  documentType: DocumentType,
  mutationType: MutationType | null = null,
) => {
  if (mutationType !== null) {
    const mutationObject = mutationLabelObject[mutationType];
    if (documentType === DocumentType.NOTARIAL_DEED && mutationObject.deedLabel)
      return upperFirst(mutationObject.deedLabel());
    if (mutationObject.documentLabel) return upperFirst(mutationObject.documentLabel());
  }
  return upperFirst(documentTypes[documentType]());
};

export const documentTypeOptions = () =>
  Object.keys(documentTypes)
    .map(
      (value) =>
        ({
          value: value as keyof typeof documentTypes,
          label: capitalizeString(formatDocumentType(value as keyof typeof documentTypes)),
        } as const),
    )
    .sort((a, b) => a.label.localeCompare(b.label));
