import type {
  Incorporation,
  Issuance,
  Onboarding,
  ValidationError,
} from '@elseu/sdu-evidend-graphql';
import { MutationType } from '@elseu/sdu-evidend-graphql';
import type { ValidationErrorCode } from 'forms/helpers/setFormErrors';
import { validationErrorParams } from 'forms/helpers/setFormErrors';
import { createMutationTypePredicate } from 'mutations/isMutationType';
import type { MutationInputFields } from 'mutations/mutationFields';
import { mutationInputFields } from 'mutations/mutationFields';

const shareSeriesMutationTypes = [
  MutationType.INCORPORATION,
  MutationType.ISSUANCE,
  MutationType.ONBOARDING,
] as const;
const shareSeriesMutationFields = shareSeriesMutationTypes.map((type) => mutationInputFields[type]);

type ShareSeriesMutationTypes = typeof shareSeriesMutationTypes;
type ShareSeriesMutationType = ShareSeriesMutationTypes[number];
type ShareSeriesMutationField = (typeof shareSeriesMutationFields)[number];
type ShareSeriesMutation = Incorporation | Onboarding | Issuance;
type ShareSeriesRow = {
  shareSeries: {
    from: number | null;
    to: number | null;
    shareType?: {
      id: string;
    };
  };
};
type SeriesDataCard = {
  to: {
    id: string;
  };
  shareType?: {
    id: string;
  };
  seriesData: ShareSeriesRow[];
};

const shareSeriesMutationField = Object.fromEntries(
  shareSeriesMutationTypes.map((field, i) => [field, shareSeriesMutationFields[i]]),
) as {
  [key in ShareSeriesMutationType]: MutationInputFields[key];
};
const isShareSeriesMutationType = (type: MutationType): type is ShareSeriesMutationType =>
  type in shareSeriesMutationField;

const isShareSeriesMutation = createMutationTypePredicate(...shareSeriesMutationTypes);

const getShareSeriesMutationField = (type: MutationType) => {
  if (!isShareSeriesMutationType(type))
    throw new Error(`Invalid shareSeries mutation type: ${type}`);
  return shareSeriesMutationField[type];
};

const filterSameShareType =
  (shareTypeId: string) =>
  ({ card, row }: { card: SeriesDataCard; row: ShareSeriesRow }) =>
    card.shareType?.id === shareTypeId || row.shareSeries.shareType?.id === shareTypeId;

const filterFromTo =
  (...values: string[]) =>
  ({ row }: { row: ShareSeriesRow }) =>
    values.includes(`${row.shareSeries.from} - ${row.shareSeries.to}`);

const mapToShareRangeValidationError = (
  card: SeriesDataCard,
  cardIndex: number,
  row: ShareSeriesRow,
  rowIndex: number,
  validationError: ValidationError,
  field = 'to',
) =>
  ({
    ...validationError,
    card,
    row,
    field: ['cards', cardIndex, 'seriesData', rowIndex, 'shareSeries', field].join('.'),
  } as const);

const createMapShareRangeCards =
  ({
    cards,
    validationError,
    field,
  }: {
    cards: SeriesDataCard[];
    validationError: ValidationError;
    field: string;
  }) =>
  () =>
    cards.flatMap((card, cardIndex) =>
      card.seriesData.map((row: ShareSeriesRow, rowIndex: number) =>
        mapToShareRangeValidationError(card, cardIndex, row, rowIndex, validationError, field),
      ),
    );

const mapShareSeriesValidationError = (
  validationError: ValidationError,
  cards?: SeriesDataCard[],
) => {
  console.warn('validation error!');
  if (!validationError as unknown) {
    console.warn('warning, undefined validation error');
    return [];
  }
  const { code, field } = validationError;
  if (!field || !code || !cards?.length) return [validationError];

  const params = validationErrorParams(validationError);

  const mapCards = createMapShareRangeCards({
    cards,
    validationError,
    // we could always set this to 'from', but the validation error might give the `to` field which be preserved
    field: field.split('/').pop() || 'from',
  });
  switch (code as ValidationErrorCode) {
    case 'SHARE_RANGE_ATTACHED': {
      /**
       * params = {mutation: "Transfer", rangeFrom: 1, rangeTo: 100, shareTypeId: "123}
       */
      return mapCards()
        .filter(filterSameShareType(params.shareTypeId))
        .filter(filterFromTo(`${params.rangeFrom} - ${params.rangeTo}`));
    }
    case 'SHARE_RANGE_NOT_OWNED': {
      /**
       * params = {rangeFrom: 1, rangeTo: 100, shareTypeId: '123'}
       */
      return mapCards()
        .filter(filterSameShareType(params.shareTypeId))
        .filter(filterFromTo(`${params.rangeFrom} - ${params.rangeTo}`));
    }
    case 'MUTATION_RANGE_COLLISION': {
      /**
       * params
       * {conflictingRange: "1 - 1111", range: "1 - 10", shareTypeId: '123'}
       */
      return mapCards()
        .filter(filterSameShareType(params.shareTypeId))
        .filter(filterFromTo(params.conflictingRange, params.range));
    }
    case 'SHARE_RANGE_COLLISION': {
      /**
       * params
       * {new: '1 - 2', existing: '1 - 3', shareTypeId: '123'}
       */
      return mapCards()
        .filter(filterSameShareType(params.shareTypeId))
        .filter(filterFromTo(params.new, params.existing));
    }
    case 'ISSUED_SHARES_MORE_THAN_AUTHORIZED':
    default:
      return [validationError];
  }
};

const mapShareSeriesValidationErrors = <T extends { cards: SeriesDataCard[] }>(
  errors: ValidationError[],
  formValues: T,
) =>
  errors.flatMap((error) => {
    const mappedErrors = mapShareSeriesValidationError(error, formValues.cards);
    if (!mappedErrors.length) return error; // we couldn't map it, so return the original error
    return mappedErrors;
  });

export {
  getShareSeriesMutationField,
  isShareSeriesMutation,
  isShareSeriesMutationType,
  mapShareSeriesValidationError,
  mapShareSeriesValidationErrors,
  shareSeriesMutationField,
  shareSeriesMutationFields,
  shareSeriesMutationTypes,
};
export type { ShareSeriesMutation, ShareSeriesMutationField, ShareSeriesMutationType };
