import type { Conversion, Party, ShareType } from '@elseu/sdu-evidend-graphql';
import { ConversionAction } from '@elseu/sdu-evidend-graphql';
import { Block, Box, Card, List, Text } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import type { SummaryCardsComponentProps } from 'helpers/formatMutation';
import { formatShareTypeName, sortShareTypes } from 'helpers/formatShares';
import { useLabels } from 'hooks/useLabels';
import { uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import { CapitalCardListItem } from 'summaries/CapitalCards/CapitalCardListItem';
import { ShareholderCapitalCard } from 'summaries/CapitalCards/ShareholderCapitalCard';
import { DeedCard } from 'summaries/DeedCard/DeedCard';
import { filterConversionsByAction } from 'wizardsteps/Conversion';

const getConversionsOfParty = (conversions: Conversion[], party: Party): Conversion[] =>
  conversions.filter((conversion) => conversion.owner.id === party.id);

const getConversionsOfShareType = (conversions: Conversion[], shareType: ShareType): Conversion[] =>
  conversions.filter((conversion) => conversion.shareSeries.shareType.id === shareType.id);

interface IConversionSummaryShareTypeCard {
  conversions: Conversion[];
  backgroundVariant: 'white' | 'grey';
  shareType: ShareType;
}
const ConversionSummaryShareTypeCard = ({
  conversions,
  backgroundVariant,
  shareType,
}: IConversionSummaryShareTypeCard) => {
  const conversionsOfShareType = getConversionsOfShareType(conversions, shareType);
  if (!conversionsOfShareType.length) return null;
  return (
    <Card
      backgroundVariant={backgroundVariant}
      showDivider={false}
      spaceAfter={4}
      title={formatShareTypeName(shareType)}
    >
      <List spaceAfter={0} type="ul">
        {conversionsOfShareType.map((conversion) => (
          <CapitalCardListItem
            key={
              conversion.shareSeries.shareType.id +
              conversion.shareSeries.from +
              conversion.shareSeries.to +
              conversion.owner.id
            }
            mutationOptions={{ showPaidStatus: true, showTotalValue: false }}
            paid={conversion.paid}
            shareSeries={conversion.shareSeries}
          />
        ))}
      </List>
    </Card>
  );
};

const conversionIdentifier = (conversion: Conversion) =>
  conversion.shareSeries.shareType.id +
  conversion.shareSeries.from +
  conversion.shareSeries.to +
  conversion.owner.id;

export const ConversionSummaryCards = ({
  backgroundVariant = 'white',
  mutation,
  onDocumentSave,
}: SummaryCardsComponentProps) => {
  const { register } = mutation;
  const { legalEntity, shareTypes } = register;
  const conversions = useMemo(() => mutation.conversion || [], [mutation.conversion]);
  const labels = useLabels('conversion.summary', legalEntity.isFoundation);
  const parties = useMemo(
    () =>
      uniqBy(
        conversions.map((conversion) => conversion.owner),
        'id',
      ),
    [conversions],
  );

  const removedConversions = filterConversionsByAction(conversions, ConversionAction.REMOVE);
  const createdConversions = filterConversionsByAction(conversions, ConversionAction.CREATE);

  return (
    <>
      <>
        <Text color="grey80" spaceAfter={6} type="labelBold">
          <Trans>Geplaatst kapitaal voor conversie</Trans>
        </Text>

        <Box spaceAfter={10}>
          {sortShareTypes(shareTypes).map((shareType) => (
            <ConversionSummaryShareTypeCard
              key={shareType.id + shareType.name}
              backgroundVariant={backgroundVariant}
              conversions={removedConversions}
              shareType={shareType}
            />
          ))}
        </Box>

        <Text color="grey80" spaceAfter={6} type="labelBold">
          <Trans>Geplaatst kapitaal na conversie</Trans>
        </Text>

        <Box spaceAfter={10}>
          {sortShareTypes(shareTypes).map((shareType) => (
            <ConversionSummaryShareTypeCard
              key={shareType.id + shareType.name}
              backgroundVariant={backgroundVariant}
              conversions={createdConversions}
              shareType={shareType}
            />
          ))}
        </Box>

        <Text color="grey80" spaceAfter={6} type="labelBold">
          {labels.sharesHeaderBefore}
        </Text>

        <Box spaceAfter={10}>
          {parties.map((party) => (
            <ShareholderCapitalCard
              key={party.id}
              backgroundVariant={backgroundVariant}
              compareDeceaseDate={mutation.effectiveDate}
              party={party}
            >
              {getConversionsOfParty(removedConversions, party).map((conversion) => (
                <CapitalCardListItem
                  key={conversionIdentifier(conversion)}
                  mutationOptions={{ showPaidStatus: true }}
                  paid={conversion.paid}
                  shareSeries={conversion.shareSeries}
                />
              ))}
            </ShareholderCapitalCard>
          ))}
        </Box>

        <Text color="grey80" spaceAfter={6} type="labelBold">
          {labels.sharesHeaderAfter}
        </Text>

        <Box spaceAfter={10}>
          {parties.map((party) => (
            <ShareholderCapitalCard
              key={party.id}
              backgroundVariant={backgroundVariant}
              compareDeceaseDate={mutation.effectiveDate}
              party={party}
            >
              {getConversionsOfParty(createdConversions, party).map((conversion) => {
                return (
                  <CapitalCardListItem
                    key={conversionIdentifier(conversion)}
                    mutationOptions={{ showPaidStatus: true }}
                    paid={conversion.paid}
                    shareSeries={conversion.shareSeries}
                  />
                );
              })}
            </ShareholderCapitalCard>
          ))}
        </Box>
      </>

      {mutation.document && (
        <Block spaceAfter={10}>
          <DeedCard
            backgroundVariant={backgroundVariant}
            document={mutation.document}
            mutation={mutation}
            onDocumentSave={onDocumentSave}
          />
        </Block>
      )}
    </>
  );
};
