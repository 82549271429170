import type {
  Administration,
  AdministrationDetails,
  AdministrationDetailsInput,
  AdministrationInput,
  Maybe,
} from '@elseu/sdu-evidend-graphql';
import { omit } from 'lodash';

import { mapShareSeriesToShareSeriesInput } from './ShareSeries';

export const mapAdministrationDetailsToAdministrationDetailsInput = (
  administrationDetails?: Maybe<AdministrationDetails>,
): AdministrationDetailsInput | null => {
  if (!administrationDetails) return null;
  return {
    ...omit(administrationDetails, ['administrator']),
    administratorId: administrationDetails.administrator.id,
  };
};

export const mapAdministrationToAdministrationInput = (
  administration: Administration,
): AdministrationInput => ({
  ownerPartyId: administration.owner.id,
  shareSeries: mapShareSeriesToShareSeriesInput(administration.shareSeries),
  details: mapAdministrationDetailsToAdministrationDetailsInput(administration.details),
});
