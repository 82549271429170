import type { EndAttachment, Party } from '@elseu/sdu-evidend-graphql';
import { Block, Box, Text } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import type { SummaryCardsComponentProps } from 'helpers/formatMutation';
import { useLabels } from 'hooks/useLabels';
import { uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import { CapitalCardListItem } from 'summaries/CapitalCards/CapitalCardListItem';
import { ShareholderCapitalCard } from 'summaries/CapitalCards/ShareholderCapitalCard';
import { DeedCard } from 'summaries/DeedCard/DeedCard';
import PartyCard from 'summaries/PartyCard/PartyCard';

const getEndAttachmentsOfParty = (endAttachments: EndAttachment[], party: Party): EndAttachment[] =>
  endAttachments.filter((endAttachment) => endAttachment.owner.id === party.id);

export const EndAttachmentSummaryCards = ({
  backgroundVariant = 'white',
  mutation,
  onDocumentSave,
}: SummaryCardsComponentProps) => {
  const { register } = mutation;
  const { legalEntity } = register;
  const labels = useLabels('endAttachment.summary', legalEntity.isFoundation);
  const endAttachments = useMemo(() => mutation.endAttachment || [], [mutation.endAttachment]);
  const { parties, bailiff, requiredBy } = useMemo(() => {
    const parties = uniqBy(
      endAttachments.map((endAttachment) => endAttachment.owner),
      'id',
    );
    const bailiff = endAttachments[0]?.details?.bailiff;
    const requiredBy = endAttachments[0]?.details?.requiredBy;
    return { parties, bailiff, requiredBy };
  }, [endAttachments]);

  return (
    <>
      {!!parties.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            {labels.shares}
          </Text>

          <Box spaceAfter={10}>
            {parties.map((party) => (
              <ShareholderCapitalCard
                key={party.id}
                backgroundVariant={backgroundVariant}
                compareDeceaseDate={mutation.effectiveDate}
                party={party}
              >
                {getEndAttachmentsOfParty(endAttachments, party).map((endAttachment, index) => (
                  <CapitalCardListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    shareSeries={endAttachment.shareSeries}
                  />
                ))}
              </ShareholderCapitalCard>
            ))}
          </Box>
        </>
      )}

      {!!bailiff && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            <Trans>Deurwaarder</Trans>
          </Text>

          <PartyCard
            backgroundVariant={backgroundVariant}
            compareDeceaseDate={mutation.effectiveDate}
            party={bailiff}
          />
        </>
      )}

      {!!requiredBy?.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            <Trans>Beslaglegger(s)</Trans>
          </Text>

          {requiredBy.map((requiredByParty) => (
            <PartyCard
              key={requiredByParty.id}
              backgroundVariant={backgroundVariant}
              party={requiredByParty}
            />
          ))}
        </>
      )}

      {mutation.document && (
        <Block spaceAfter={10}>
          <DeedCard
            backgroundVariant={backgroundVariant}
            document={mutation.document}
            mutation={mutation}
            onDocumentSave={onDocumentSave}
          />
        </Block>
      )}
    </>
  );
};
