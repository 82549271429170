import type { Party, Transfer } from '@elseu/sdu-evidend-graphql';
import { Block, Box, Text } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { mergeCards } from 'forms/helpers/mergeCards';
import type { SummaryCardsComponentProps } from 'helpers/formatMutation';
import { getPartyName } from 'helpers/getFromParty';
import { generateIdFromShareBatch } from 'helpers/shareBatches';
import React, { useMemo } from 'react';
import { CapitalCardListItem } from 'summaries/CapitalCards/CapitalCardListItem';
import { ShareholderCapitalCard } from 'summaries/CapitalCards/ShareholderCapitalCard';
import { DeedCard } from 'summaries/DeedCard/DeedCard';
import { mapTransferToCard, mergeTransferPredicate } from 'wizardsteps/Transfer/transferCard';

const mapTransfersToSummaryCards = (
  transfers: Transfer[],
): Array<ReturnType<typeof mapTransferToCard> & { to: Party }> =>
  transfers
    .reduce(
      (cards, transfer) => mergeCards(mergeTransferPredicate)(cards, mapTransferToCard(transfer)),
      [] as Array<ReturnType<typeof mapTransferToCard>>,
    )
    .filter((card) => card.to)
    .map((card) => card as any);

export const TransferSummaryCards = ({
  backgroundVariant = 'white',
  mutation,
  onDocumentSave,
}: SummaryCardsComponentProps) => {
  const cards = useMemo(() => mapTransfersToSummaryCards(mutation.transfer || []), [mutation]);
  return (
    <>
      {!!cards.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            <Trans>Overdracht(en)</Trans>
          </Text>
          <Box spaceAfter={10}>
            {cards.map((card, index) => (
              <ShareholderCapitalCard
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                backgroundVariant={backgroundVariant}
                compareDeceaseDate={mutation.effectiveDate}
                party={card.to}
                title={t`${getPartyName(card.from, mutation.effectiveDate)} aan ${getPartyName(
                  card.to,
                  mutation.effectiveDate,
                )}`}
              >
                {card.seriesData.map((transfer) => (
                  <CapitalCardListItem
                    key={generateIdFromShareBatch(transfer)}
                    shareSeries={transfer.shareSeries}
                  />
                ))}
              </ShareholderCapitalCard>
            ))}
          </Box>
        </>
      )}

      {mutation.document && (
        <Block spaceAfter={10}>
          <DeedCard
            backgroundVariant={backgroundVariant}
            document={mutation.document}
            mutation={mutation}
            onDocumentSave={onDocumentSave}
          />
        </Block>
      )}
    </>
  );
};
