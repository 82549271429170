import type { EndPledge, EndPledgeInput } from '@elseu/sdu-evidend-graphql';

import { mapDelegationDetailsToDelegationDetailsInput } from './DelegationDetails';
import { mapShareSeriesToShareSeriesInput } from './ShareSeries';

export const mapEndPledgeToEndPledgeInput = (endPledge: Partial<EndPledge>): EndPledgeInput => ({
  details: (endPledge.details || []).map(mapDelegationDetailsToDelegationDetailsInput),
  ownerPartyId: endPledge.owner?.id || '',
  shareSeries: mapShareSeriesToShareSeriesInput(endPledge.shareSeries as any),
});
