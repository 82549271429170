import type { Party, Register } from '@elseu/sdu-evidend-graphql';
import type { SelectOptionProps } from '@elseu/sdu-titan';
import { Avatar, BusinessIcon, PlusIcon } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { SelectField } from 'components/FormFields/SelectField';
import PartyCreateSidebarForm from 'components/PartyCreateSidebarForm/PartyCreateSidebarForm';
import type { OnPartyCreateFn } from 'forms/mutation/PartyCreateForm';
import { getPartyName, getPartySublabel } from 'helpers/getFromParty';
import { getInitials } from 'helpers/initials';
import { get, sortBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

const getPartySelectSchema = () => {
  return yup.object({
    id: yup.string().nullable().required(),
  });
};

export const partyInputSchema = getPartySelectSchema();

export type PartySelectValues = yup.InferType<typeof partyInputSchema>;

interface PartySelectProps {
  register: Register;
  parties: Party[];
  label: string;
  placeholder: string;
  isCreateAllowed?: boolean;
  isLabelHidden?: boolean;
  nestedField?: string;
  onPartyCreate?: OnPartyCreateFn;
  createLabel?: string;
  required?: boolean;
  isMulti?: boolean;
  compareDeceaseDate?: Date;
  [key: string]: any;
}

export const PartySelect = ({
  register,
  parties,
  nestedField = '',
  label,
  placeholder,
  isCreateAllowed = true,
  isLabelHidden = false,
  onPartyCreate,
  createLabel,
  required,
  isMulti,
  compareDeceaseDate,
  ...props
}: PartySelectProps) => {
  const { setValue, getValues } = useFormContext();
  const [isSidebarShown, setSidebarShown] = useState<boolean>(false);

  const options = useMemo(
    () =>
      sortBy(
        parties.map((party) => {
          const { id, naturalPerson } = party;
          const description = getPartySublabel(party);
          const name = getPartyName(party, compareDeceaseDate);
          const prefixAdornment = naturalPerson ? getInitials(name) : <BusinessIcon />;
          return {
            value: id,
            label: name,
            description,
            prefixAdornment: <Avatar size={40}>{prefixAdornment}</Avatar>,
          };
        }),
        'label',
      ),
    [compareDeceaseDate, parties],
  );

  const createOption = useCallback(
    () =>
      ({
        label: createLabel || t`Toevoegen`,
        prefixAdornment: <PlusIcon />,
      } as SelectOptionProps),
    [createLabel],
  );

  const isValidNewOption = useCallback(() => true, []);

  return (
    <>
      <SelectField
        isSearchable
        createOption={createOption}
        createOptionPosition="first"
        isCreateAllowed={isCreateAllowed}
        isLabelHidden={isLabelHidden}
        isMulti={isMulti as any}
        isValidNewOption={isValidNewOption}
        label={label}
        name={`${nestedField}id`}
        options={options}
        placeholder={placeholder}
        popoverWidth={300}
        required={required}
        onCreateOption={() => setSidebarShown(true)}
        {...props}
      />
      <PartyCreateSidebarForm
        isSidebarShown={isSidebarShown}
        register={register}
        onPartyCreate={(partyId) => {
          const field = `${nestedField}id`;
          const currentValues = getValues();
          const newValue = isMulti ? [...get(currentValues, field), partyId] : partyId;
          setValue(field, newValue, {
            shouldValidate: true,
            shouldDirty: true,
          });
          onPartyCreate?.();
        }}
        onSidebarClose={() => setSidebarShown(false)}
      />
    </>
  );
};
