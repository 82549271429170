import type { LegalEntity, LegalEntityInput } from '@elseu/sdu-evidend-graphql';

const mapLegalEntityToLegalEntityInput = (legalEntity: LegalEntity): LegalEntityInput => ({
  correspondenceAddress: legalEntity.correspondenceAddress,
  name: legalEntity.name,
  countryOfRegistration: legalEntity.countryOfRegistration || 'NL',
  incorporatedOn: legalEntity.incorporatedOn,
  legalForm: legalEntity.legalForm,
  emailAddress: legalEntity.emailAddress,
  phoneNumber: legalEntity.phoneNumber,
  rsinNumber: legalEntity.rsinNumber,
  tradeRegisterNumber: legalEntity.tradeRegisterNumber,
  visitorAddress: legalEntity.visitorAddress,
});
export { mapLegalEntityToLegalEntityInput };
