export { useWizardContext } from './useWizardContext';
export { withWizardContext } from './withWizardContext';
export { WizardContext } from './WizardContext';
export { WizardFooter } from './WizardFooter';
export { WizardHeader } from './WizardHeader';
export { WizardProvider } from './WizardProvider';
export { WizardSummary } from './WizardSummary';

export interface StepLabels {
  header: string;
  introduction: string;
  footer: string;
}
