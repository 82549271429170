import type { AttachmentInput, ShareBatch } from '@elseu/sdu-evidend-graphql';
import { AttachmentType } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import { mapShareSeriesToShareSeriesInput } from 'graphql/input-mappers/ShareSeries';

export const attachmentInputsForShareBatches = (shareBatches: ShareBatch[]): AttachmentInput[] =>
  shareBatches.map((shareBatch) => ({
    details: null,
    shareSeries: mapShareSeriesToShareSeriesInput(shareBatch.shareSeries),
    ownerPartyId: shareBatch.shareholder.id,
    type: AttachmentType.PREJUDGEMENT,
  }));

export const attachmentOptions = () => [
  {
    value: AttachmentType.PREJUDGEMENT,
    label: t`Conservatoir`,
  },
  {
    value: AttachmentType.ENFORCEABLE,
    label: t`Executoriaal`,
  },
];
