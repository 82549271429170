import { useMutation } from '@apollo/client';
import type { LegalEntity, PartyUpdate } from '@elseu/sdu-evidend-graphql';
import { DocumentType } from '@elseu/sdu-evidend-graphql';
import {
  DrawerContainer,
  DrawerContent,
  DrawerFooter,
  EmptyState,
  Flex,
  SteppedForm,
  SteppedFormFooter,
  SteppedFormStep,
} from '@elseu/sdu-titan';
import { yupResolver } from '@hookform/resolvers/yup';
import { t } from '@lingui/macro';
import { setFormErrors } from 'forms/helpers/setFormErrors';
import { PartyUpdateForm, partyUpdateFormSchema } from 'forms/PartyUpdateForm';
import { mapDocumentToDocumentInput } from 'graphql/input-mappers/Document';
import { mapPartyUpdateToPartyUpdateInput } from 'graphql/input-mappers/Party';
import { SAVE_PARTY_UPDATES_MUTATION } from 'graphql/queries/party';
import { useGlobalError } from 'hooks/useGlobalError';
import { useUploadDocument } from 'hooks/useUpdateDocument';
import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import type { SavePartyUpdates, SavePartyUpdatesVariables } from 'types/graphql/SavePartyUpdates';

import { PartyUpdatesCards } from './PartyUpdatesCards';

interface PartyUpdateSidebarFormProps {
  legalEntity: LegalEntity;
  partyUpdates: PartyUpdate[];
  onUpdate?: () => any;
  onSidebarClose: () => any;
  header: string;
  subtitle: string;
  isDocumentRequired: boolean;
}
export const PartyUpdateSidebarForm = ({
  legalEntity,
  onUpdate,
  partyUpdates,
  onSidebarClose,
  header,
  subtitle,
  isDocumentRequired,
}: PartyUpdateSidebarFormProps) => {
  const [, setGlobalError] = useGlobalError();
  const [savePartyUpdatesMutation] = useMutation<SavePartyUpdates, SavePartyUpdatesVariables>(
    SAVE_PARTY_UPDATES_MUTATION,
  );
  const uploadDocument = useUploadDocument();

  const formSchema = partyUpdateFormSchema({
    isDateRequired: !isDocumentRequired,
    isDocumentRequired,
  });
  const defaultValues = isDocumentRequired
    ? {
        document: {
          type: DocumentType.NOTARIAL_DEED,
        },
      }
    : undefined;
  const form = useForm({
    defaultValues,
    resolver: yupResolver(formSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const { isSubmitting, isValid } = form.formState;

  const isSubmitDisabled = !isValid || isSubmitting;

  const savePartyUpdates = useCallback(async () => {
    const { date: formDate, reason, document } = form.getValues() as any;
    const date = formDate || document?.date;
    const file = document?.file;
    const partyUpdateInputs = partyUpdates.map((partyUpdate) => ({
      ...mapPartyUpdateToPartyUpdateInput(partyUpdate),
      date,
      reason,
    }));
    const variables: SavePartyUpdatesVariables = {
      partyUpdates: partyUpdateInputs,
    };
    if (isDocumentRequired) {
      variables.document = mapDocumentToDocumentInput({
        ...document,
        date,
      });
    }
    await savePartyUpdatesMutation({
      variables,
      onCompleted: async ({ savePartyUpdates }) => {
        if (file && savePartyUpdates[0]?.document)
          await uploadDocument({
            document: savePartyUpdates[0].document,
            file,
          });
        if (onUpdate) onUpdate();
        onSidebarClose();
      },
      onError: ({ graphQLErrors }) => {
        setFormErrors({
          errors: graphQLErrors,
          form,
          setGlobalError,
        });
      },
    });
  }, [
    form,
    isDocumentRequired,
    onSidebarClose,
    onUpdate,
    partyUpdates,
    savePartyUpdatesMutation,
    setGlobalError,
    uploadDocument,
  ]);

  const steps = useMemo(() => {
    const steps = [
      <SteppedFormStep
        key="partyUpdatesCards"
        title={t`Aanpassingen`}
        onSubmit={(e, { nextStep }) => {
          e.preventDefault();
          nextStep();
        }}
      >
        {!partyUpdates.length && (
          <Flex justifyContent="center" pt={5}>
            <EmptyState
              isCentered
              description={t`Er zijn geen aanpassingen gevonden bij de Kamer van Koophandel.`}
              title={t`Geen aanpassingen`}
            />
          </Flex>
        )}
        {!!partyUpdates.length && (
          <PartyUpdatesCards
            legalEntity={legalEntity}
            partyUpdates={partyUpdates as PartyUpdate[]}
          />
        )}
      </SteppedFormStep>,
    ];
    if (partyUpdates.length) {
      steps.push(
        <SteppedFormStep
          key="partyUpdatesForm"
          title={t`Gegevens toevoegen`}
          onSubmit={(e) => {
            e.preventDefault();
            savePartyUpdates();
          }}
        >
          <PartyUpdateForm
            isDateRequired={!isDocumentRequired}
            isDocumentRequired={isDocumentRequired}
            isFoundation={legalEntity.isFoundation}
          />
        </SteppedFormStep>,
      );
    }

    return steps;
  }, [isDocumentRequired, partyUpdates, savePartyUpdates, legalEntity]);

  return (
    <SteppedForm
      buttonEdit={{ label: t`Wijzig` }}
      customRenderer={({ steps: reactSteps, isLastStep }) => {
        const isDisabled = steps.length === 1 || (isLastStep && isSubmitDisabled);
        return (
          <FormProvider {...form}>
            <DrawerContainer header={header} subtitle={subtitle}>
              <DrawerContent>{reactSteps}</DrawerContent>
            </DrawerContainer>
            <DrawerFooter>
              <SteppedFormFooter
                hasCancel
                hasPrevious
                buttonCancel={{
                  label: partyUpdates.length ? t`Annuleren` : t`Sluiten`,
                  onClick: () => onSidebarClose(),
                }}
                buttonNext={{
                  label: isLastStep ? t`Opslaan` : t`Volgende`,
                  isDisabled,
                }}
                buttonPrevious={{ label: t`Vorige` }}
              />
            </DrawerFooter>
          </FormProvider>
        );
      }}
      formId="partyUpdateForm"
    >
      {steps}
    </SteppedForm>
  );
};
