import type { Party, PayingUp } from '@elseu/sdu-evidend-graphql';
import { Block, Box, Text } from '@elseu/sdu-titan';
import type { SummaryCardsComponentProps } from 'helpers/formatMutation';
import { useLabels } from 'hooks/useLabels';
import { uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import { CapitalCardListItem } from 'summaries/CapitalCards/CapitalCardListItem';
import { ShareholderCapitalCard } from 'summaries/CapitalCards/ShareholderCapitalCard';
import { DeedCard } from 'summaries/DeedCard/DeedCard';

const getPayingUpsOfParty = (payingUps: PayingUp[], party: Party): PayingUp[] =>
  payingUps.filter((payingUp) => payingUp.owner.id === party.id);

export const PayingUpSummaryCards = ({
  backgroundVariant = 'white',
  mutation,
  onDocumentSave,
}: SummaryCardsComponentProps) => {
  const { register } = mutation;
  const { legalEntity } = register;
  const labels = useLabels('payingUp.summary', legalEntity.isFoundation);
  const payingUps = useMemo(() => mutation.payingUp || [], [mutation.payingUp]);
  const parties = useMemo(
    () =>
      uniqBy(
        payingUps.map((payingUp) => payingUp.owner),
        'id',
      ),
    [payingUps],
  );

  return (
    <>
      {!!parties.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            {labels.shares}
          </Text>

          <Box spaceAfter={10}>
            {parties.map((party) => (
              <ShareholderCapitalCard
                key={party.id}
                backgroundVariant={backgroundVariant}
                compareDeceaseDate={mutation.effectiveDate}
                party={party}
              >
                {getPayingUpsOfParty(payingUps, party).map((payingUp) => (
                  <CapitalCardListItem
                    key={payingUp.shareSeries.shareType.id}
                    shareSeries={payingUp.shareSeries}
                  />
                ))}
              </ShareholderCapitalCard>
            ))}
          </Box>
        </>
      )}

      {mutation.document && (
        <Block spaceAfter={10}>
          <DeedCard
            backgroundVariant={backgroundVariant}
            document={mutation.document}
            mutation={mutation}
            onDocumentSave={onDocumentSave}
          />
        </Block>
      )}
    </>
  );
};
