import { Header, Text } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import React, { useCallback } from 'react';

import { useVisitStep } from './useVisitStep';

interface IWizardHeader {
  title: string;
  children: string | React.ReactElement;
  previousStep?: string;
}

export const WizardHeader = ({ title, previousStep, children }: IWizardHeader) => {
  const visitStep = useVisitStep();

  // The Back button always links to another wizard step.
  const back = useCallback(() => visitStep(previousStep as string), [previousStep, visitStep]);

  return (
    <Header
      backLinkOnClick={previousStep ? back : undefined}
      hasContentWrapper={false}
      label={<Text type="paragraph">{children}</Text>}
      labelBackLink={previousStep ? t`Terug` : undefined}
      labelColor="grey"
      title={title}
      variant="secondary"
    />
  );
};
