import type { Party, Withdrawal } from '@elseu/sdu-evidend-graphql';
import { Block, Box, Text } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import type { SummaryCardsComponentProps } from 'helpers/formatMutation';
import { uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import { CapitalCardListItem } from 'summaries/CapitalCards/CapitalCardListItem';
import { ShareholderCapitalCard } from 'summaries/CapitalCards/ShareholderCapitalCard';
import { DeedCard } from 'summaries/DeedCard/DeedCard';

const getWithdrawalsOfParty = (withdrawals: Withdrawal[], party: Party): Withdrawal[] =>
  withdrawals.filter((withdrawal) => withdrawal.from.id === party.id);

export const WithdrawalSummaryCards = ({
  backgroundVariant = 'white',
  mutation,
  onDocumentSave,
}: SummaryCardsComponentProps) => {
  const withdrawals = useMemo(() => mutation.withdrawal || [], [mutation.withdrawal]);
  const parties = useMemo(
    () =>
      uniqBy(
        withdrawals.map((withdrawal) => withdrawal.from),
        'id',
      ),
    [withdrawals],
  );

  return (
    <>
      {!!parties.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            <Trans>Intrekking(en)</Trans>
          </Text>

          <Box spaceAfter={10}>
            {parties.map((party) => (
              <ShareholderCapitalCard
                key={party.id}
                backgroundVariant={backgroundVariant}
                compareDeceaseDate={mutation.effectiveDate}
                party={party}
              >
                {getWithdrawalsOfParty(withdrawals, party).map((withdrawal, index) => (
                  <CapitalCardListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    mutationOptions={{ showPaidStatus: true }}
                    shareSeries={withdrawal.shareSeries}
                  />
                ))}
              </ShareholderCapitalCard>
            ))}
          </Box>
        </>
      )}

      {mutation.document && (
        <Block spaceAfter={10}>
          <DeedCard
            backgroundVariant={backgroundVariant}
            document={mutation.document}
            mutation={mutation}
            onDocumentSave={onDocumentSave}
          />
        </Block>
      )}
    </>
  );
};
