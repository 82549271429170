import type { ShareType } from '@elseu/sdu-evidend-graphql';
import { Card, List } from '@elseu/sdu-titan';
import { formatShareTypeName } from 'helpers/formatShares';
import React from 'react';

interface IShareTypeCapitalCard {
  backgroundVariant: 'white' | 'grey';
  shareType: ShareType;
  children: React.ReactNode;
}
export const ShareTypeCapitalCard = ({
  backgroundVariant = 'white',
  shareType,
  children,
}: IShareTypeCapitalCard) => {
  return (
    <Card
      backgroundVariant={backgroundVariant}
      showDivider={false}
      spaceAfter={4}
      title={formatShareTypeName(shareType)}
    >
      <List spaceAfter={0} type="ul">
        {children}
      </List>
    </Card>
  );
};
