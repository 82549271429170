import type { QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { MUTATION_QUERY } from 'graphql/queries/mutation';
import type { Mutation, MutationVariables } from 'types/graphql/Mutation';

const useMutationQuery = (options?: QueryHookOptions<Mutation, MutationVariables>) => {
  const mutationQueryResult = useQuery(MUTATION_QUERY, options);
  return [mutationQueryResult.data?.mutation, mutationQueryResult] as const;
};

export { useMutationQuery };
