import type { Party, ShareSeries } from '@elseu/sdu-evidend-graphql';
import { Card, List } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { formatNumber, formatShareSeries, formatShareTypeName } from 'helpers/formatShares';
import { getPartyName } from 'helpers/getFromParty';
import { formatMoney, multiplyMoney } from 'helpers/money';
import { calculateSeriesTotal } from 'helpers/shareBatches';
import React from 'react';

export interface MutationCardTextOptions {
  showNominalValue?: boolean;
  showPaidStatus?: boolean;
  showShareRange?: boolean;
  showTotalValue?: boolean;
}
export const MutationCardText = (
  shareSeries: ShareSeries,
  paid: boolean,
  {
    showNominalValue = true,
    showPaidStatus = false,
    showShareRange = true,
    showTotalValue = false,
  }: MutationCardTextOptions = {},
) => {
  const { from, to, shareType } = shareSeries;
  const total = calculateSeriesTotal(from, to);
  let shareTypeText = `${formatNumber(total)} ${formatShareTypeName(shareType, showNominalValue)}`;
  if (showShareRange) shareTypeText += ` ${t`genummerd ${formatShareSeries(shareSeries)}`}`;
  if (showTotalValue)
    shareTypeText += ` ${t`met een totaalbedrag van ${formatMoney(
      multiplyMoney(shareType.nominalValue, total),
    )}`}`;
  if (showPaidStatus) shareTypeText += ` (${paid ? t`volgestort` : t`niet-volgestort`})`;
  return shareTypeText;
};

interface IShareholderCapitalCard {
  backgroundVariant?: 'white' | 'grey';
  party: Party;
  title?: string;
  children: React.ReactNode;
  compareDeceaseDate?: Date;
}
export const ShareholderCapitalCard = ({
  backgroundVariant = 'white',
  party,
  title,
  children,
  compareDeceaseDate,
}: IShareholderCapitalCard) => {
  return (
    <Card
      backgroundVariant={backgroundVariant}
      showDivider={false}
      spaceAfter={4}
      title={title || getPartyName(party, compareDeceaseDate)}
    >
      <List spaceAfter={0} type="ul">
        {children}
      </List>
    </Card>
  );
};
