import { NotaryRole } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import { InputField } from 'components/FormFields/InputField';
import { SelectField } from 'components/FormFields/SelectField';
import { integerTransform } from 'forms/helpers/integerTransform';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

const getNotaryNameSchema = () =>
  yup.object({
    initials: yup.string().trim().required(),
    preposition: yup.string().trim().nullable(),
    lastName: yup.string().trim().required(),
  });
const notaryNameSchema = getNotaryNameSchema();

const getDocumentDeedPassedFormSchema = () => {
  return yup.object({
    notary: yup.mixed().when('notaryRole', {
      is: (val: NotaryRole) => val !== NotaryRole.UNKNOWN_NOTARY,
      then: yup.object({
        name: notaryNameSchema,
        location: yup.string().trim().required(),
      }),
      otherwise: yup.mixed().nullable().optional(),
    }),
    notaryRole: yup.mixed().oneOf(Object.values(NotaryRole)).required(),
    assignedNotary: yup.mixed().when('notaryRole', {
      is: (val: NotaryRole) => val === NotaryRole.ASSIGNED_NOTARY,
      then: yup.object({
        name: notaryNameSchema,
        location: yup.string().trim().required(),
      }),
      otherwise: yup.mixed().nullable().optional(),
    }),
  });
};

export const documentDeedPassedFormSchema = getDocumentDeedPassedFormSchema();

export type DocumentDeedPassedFormValues = yup.InferType<typeof documentDeedPassedFormSchema>;

interface IDocumentDeedPassedForm {
  nestedField?: string;
}
export const notaryRoleOptions = () => [
  {
    label: t`Notaris`,
    value: NotaryRole.NOTARY,
  },
  {
    label: t`Waarnemer van`,
    value: NotaryRole.DEPUTY_NOTARY,
  },
  {
    label: t`Toegevoegd notaris`,
    value: NotaryRole.ASSIGNED_NOTARY,
  },
  {
    label: t`Notaris onbekend`,
    value: NotaryRole.UNKNOWN_NOTARY,
  },
];

const DocumentDeedPassedForm = ({ nestedField }: IDocumentDeedPassedForm) => {
  const { watch, setValue } = useFormContext();
  const notaryRole = watch(`${nestedField}notaryRole`);
  useEffect(() => {
    if (notaryRole === NotaryRole.UNKNOWN_NOTARY) {
      setValue(`${nestedField}notary`, null);
      return;
    }
    if (notaryRole === NotaryRole.ASSIGNED_NOTARY) return;
    setValue(`${nestedField}assignedNotary`, null);
  }, [nestedField, notaryRole, setValue]);
  const options = notaryRoleOptions();
  return (
    <>
      <SelectField
        required
        defaultValue={options[0].value}
        label={t`Gepasseerd door`}
        name={`${nestedField}notaryRole`}
        options={options}
        placeholder={t`Gepasseerd door`}
        transform={integerTransform}
      />
      {notaryRole !== NotaryRole.UNKNOWN_NOTARY && (
        <NotaryForm nestedField={`${nestedField}notary.`} />
      )}
      {notaryRole === NotaryRole.ASSIGNED_NOTARY && (
        <NotaryForm
          labelNotary={t`Toegevoegd notaris`}
          labelNotaryLocation={t`Vestigingsplaats toegevoegd notaris`}
          nestedField={`${nestedField}assignedNotary.`}
        />
      )}
    </>
  );
};
DocumentDeedPassedForm.defaultProps = {
  nestedField: '',
};

interface INotaryForm {
  nestedField?: string;
  labelNotary?: string;
  labelNotaryLocation?: string;
}
export const NotaryForm = ({
  nestedField,
  labelNotary = t`Notaris`,
  labelNotaryLocation = t`Vestigingsplaats notaris`,
}: INotaryForm) => {
  return (
    <>
      <InputField
        required
        label={labelNotary}
        name={`${nestedField}name.initials`}
        placeholder={t`Initialen`}
        spaceAfter={2}
      />
      <InputField
        isLabelHidden
        label={t`Tussenvoegsel`}
        name={`${nestedField}name.preposition`}
        placeholder={t`Tussenvoegsel`}
        spaceAfter={2}
      />
      <InputField
        isLabelHidden
        required
        label={t`Achternaam`}
        name={`${nestedField}name.lastName`}
        placeholder={t`Achternaam`}
        spaceAfter={6}
      />

      <InputField
        required
        label={labelNotaryLocation}
        name={`${nestedField}location`}
        placeholder={labelNotaryLocation}
        spaceAfter={6}
      />
    </>
  );
};

export { DocumentDeedPassedForm, documentDeedPassedFormSchema as DocumentDeedPassedFormSchema };
