import type { Party } from '@elseu/sdu-evidend-graphql';
import type { Spacings } from '@elseu/sdu-titan';
import { Block, Card } from '@elseu/sdu-titan';
import { getPartyName } from 'helpers/getFromParty';
import React from 'react';

interface PartyCardProps {
  party: Party;
  spaceAfter?: Spacings;
  backgroundVariant: 'white' | 'grey';
  compareDeceaseDate?: Date;
}
const PartyCard = ({
  party,
  spaceAfter = 6,
  backgroundVariant = 'white',
  compareDeceaseDate,
}: PartyCardProps) => {
  return (
    <Card backgroundVariant={backgroundVariant} spaceAfter={spaceAfter}>
      <Block>{getPartyName(party, compareDeceaseDate)}</Block>
    </Card>
  );
};

export default PartyCard;
