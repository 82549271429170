import type { Party } from '@elseu/sdu-evidend-graphql';
import { ParticipationType, PartyType } from '@elseu/sdu-evidend-graphql';
import { Link, Text } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import dayjs from 'dayjs';
import { upperFirst } from 'lodash';

import { formatNaturalPersonName } from './formatName';
import { formatDate } from './formatShares';

const participationTypes = {
  [ParticipationType.BAILIFF]: () => t`deurwaarder`,
  [ParticipationType.GUARDIAN]: () => t`bewindvoerder`,
  [ParticipationType.PLEDGEE]: () => t`pandhouder`,
  [ParticipationType.REQUIRED_IN_ATTACHMENT]: () => t`beslaglegger`,
  [ParticipationType.USUFRUCTEE]: () => t`vruchtgebruiker`,
  [ParticipationType.HISTORICAL]: () => t`geen huidige partij`,
};

export const formatParticipationType = (
  isFoundation: boolean,
  participationType: ParticipationType,
) => {
  if (participationType === ParticipationType.SHAREHOLDER)
    return upperFirst(isFoundation ? t`certificaathouder` : t`aandeelhouder`);
  return upperFirst(participationTypes[participationType]());
};

export const getPartyTypeUrl = (party: Pick<Party, 'type' | 'id'>) => {
  const partyTypeRef = party.type === PartyType.NATURAL_PERSON ? 'persoon' : 'rechtspersoon';
  return `/${partyTypeRef}/${party.id}`;
};

export const getPartyParticipation = (party: Party) => {
  const { naturalPerson, legalEntity } = party;
  if (naturalPerson?.participation) return naturalPerson.participation;
  if (legalEntity?.participation) return legalEntity.participation;
};

export const getPartyName = (party: Party, compareDeceaseDate?: Date) => {
  const { naturalPerson, legalEntity } = party;
  if (naturalPerson) {
    const isDeceased = compareDeceaseDate
      ? naturalPerson.dateOfDecease &&
        naturalPerson.dateOfDecease <= dayjs(compareDeceaseDate).startOf('day').toDate()
      : naturalPerson.dateOfDecease;
    return formatNaturalPersonName(naturalPerson.name, isDeceased);
  }
  if (legalEntity) return legalEntity.name;
  return '';
};

export const getPartySublabel = ({ naturalPerson, legalEntity }: Party): string => {
  if (legalEntity) {
    return legalEntity.tradeRegisterNumber || '';
  }

  if (naturalPerson) {
    const { tag, dateOfBirth } = naturalPerson;
    if (tag) return tag;
    return formatDate(dateOfBirth);
  }
  return '';
};

interface IPartyLink {
  party: Party;
  isSublabelHidden?: boolean;
  compareDeceaseDate?: Date;
}
export const PartyLink = ({ party, isSublabelHidden = false, compareDeceaseDate }: IPartyLink) => {
  const sublabel = getPartySublabel(party);
  const name = getPartyName(party, compareDeceaseDate);
  return (
    <>
      <Link to={getPartyTypeUrl(party)}>{name}</Link>
      {!isSublabelHidden && sublabel && (
        <Text isBlock color="grey70" type="paragraphSmall">
          {sublabel}
        </Text>
      )}
    </>
  );
};
