import type { QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { LEGAL_ENTITY_QUERY } from 'graphql/queries/legalEntity';
import type { LegalEntityQuery, LegalEntityQueryVariables } from 'types/graphql/LegalEntityQuery';

const useLegalEntityQuery = (
  options?: QueryHookOptions<LegalEntityQuery, LegalEntityQueryVariables>,
) => useQuery(LEGAL_ENTITY_QUERY, options);

export { useLegalEntityQuery };
