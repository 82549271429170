import { defaultStyles, Text, textStyle, UploadIcon } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import React from 'react';
import styled from 'styled-components';

const StyledFileInput = styled.input`
  display: none;
`;

const StyledFileLabel = styled.label`
  ${textStyle('label')};
  ${defaultStyles.fontSmoothing};
  ${defaultStyles.boxSizing};
  position: relative;
  outline: none;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  border: 0;
  line-height: 1.25;
  border-radius: ${({ theme }) => theme.components.button.borderRadius};

  &:hover {
    cursor: pointer;
  }
  &:disabled {
    pointer-events: none;
  }
  svg {
    flex: 0 0 auto;
  }

  background-color: ${({ theme }) => theme.components.button.variants.secondary.backgroundColor};
  color: ${({ theme }) => theme.components.button.variants.secondary.textColor};
  &:hover {
    background-color: ${({ theme }) =>
      theme.components.button.variants.secondary.backgroundColorHover};
    box-shadow: ${({ theme }) => theme.components.button.boxShadowHover};
  }
  &:focus {
    box-shadow: inset 0 0 0 1px
        ${({ theme }) => theme.components.button.variants.secondary.backgroundColor},
      inset 0 0 0 2px ${({ theme }) => theme.components.button.variants.secondary.borderFocus};
  }
  &:disabled {
    color: ${({ theme }) => theme.components.button.variants.secondary.textColorDisabled};
    background-color: ${({ theme }) =>
      theme.components.button.variants.secondary.backgroundColorDisabled};
  }

  padding: ${({ theme }) => theme.components.button.sizes.M.padding};
`;

const ButtonText = styled(Text)`
  padding-left: ${({ theme }) => theme.components.button.paddingIcon};
`;

interface UploadFileButtonProps extends React.HTMLAttributes<HTMLInputElement> {
  onFile?: (file: File) => any;
  disabled?: boolean;
}

const UploadFileButton = ({ onFile, onChange, disabled, ...props }: UploadFileButtonProps) => {
  return (
    <>
      <StyledFileLabel htmlFor="file-upload">
        <UploadIcon />
        <ButtonText color="grey80" type="label">
          <Trans>Uploaden</Trans>
        </ButtonText>
      </StyledFileLabel>
      <StyledFileInput
        {...props}
        disabled={disabled}
        id="file-upload"
        type="file"
        onChange={(event) => {
          if (typeof onChange === 'function') {
            onChange(event);
          }
          if (event.target.files !== null && typeof onFile === 'function') {
            onFile(event.target.files[0]);
          }
        }}
      />
    </>
  );
};

export default UploadFileButton;
