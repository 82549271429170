import { t } from '@lingui/macro';

const getShareSeriesLabels = () => ({
  shareholdersHeader: t`Aandeelhouders`,
  partyColumnHeader: t`Aandeelhouder`,
  sharesHeader: t`Aandelen`,
  shareTypeColumnHeader: t`Soort aandelen`,
  shareSeriesColumnHeader: t`Aandelenreeks`,
  shareSeriesType: t`Aandelen`,
  partyRefCreate: t`Aandeelhouder toevoegen`,
  emptyStateTitle: t`Geen aandelen beschikbaar`,
  emptyStateDescription: t`Op de opgegeven datum zijn er geen aandelen die geselecteerd kunnen worden. Ga terug naar de vorige stap en pas de datum aan.`,
});
const getShareSeriesLabelsFoundation = () => ({
  shareholdersHeader: t`Certificaathouders`,
  partyColumnHeader: t`Certificaathouder`,
  sharesHeader: t`Certificaten`,
  shareTypeColumnHeader: t`Soort certificaten`,
  shareSeriesColumnHeader: t`Certificaatreeks`,
  shareSeriesType: t`Certificaten`,
  partyRefCreate: t`Certificaathouder toevoegen`,
  emptyStateTitle: t`Geen certificaten beschikbaar`,
  emptyStateDescription: t`Op de opgegeven datum zijn er geen certificaten die geselecteerd kunnen worden. Ga terug naar de vorige stap en pas de datum aan.`,
});

export const workflowLabels = () =>
  ({
    shareTypes: {
      default: {
        header: t`Soorten aandelen definiëren`,
        introduction: t`Als een wijziging van een soort aandelen onderdeel is van deze mutatie, dan kun je dat in deze stap doen. Als er geen wijzigingen zijn in de soorten aandelen, dan kun je direct verder naar de volgende stap.`,
        footer: t`Sla de soorten aandelen op en ga verder.`,
      },
      foundation: {
        header: t`Soorten certificaten definiëren`,
        introduction: t`Als een wijziging van een soort certificaten onderdeel is van deze mutatie, dan kun je dat in deze stap doen. Als er geen wijzigingen zijn in de soorten certificaten, dan kun je direct verder naar de volgende stap.`,
        footer: t`Sla de soorten certificaten op en ga verder.`,
      },
    },
    incorporation: {
      steps: {
        default: {
          incorporation: t`Aandelen`,
          document: t`Document`,
          documentOnboarding: t`Register`,
          uploadDocument: t`Gegevens document toevoegen`,
          uploadDocumentOnboarding: t`Bestaand register toevoegen`,
          shareTypes: t`Soorten aandelen definiëren`,
          shares: t`Aandelen uitgeven`,
        },
        foundation: {
          incorporation: t`Certificaten van aandelen`,
          shareTypes: t`Soorten certificaten definiëren`,
          shares: t`Certificaten uitgeven`,
        },
      },
      legalEntityContact: {
        default: {
          header: t`Contactgegevens`,
          introduction: t`Vul de contactgegevens van de vennootschap in.`,
          footer: t`Sla de contactgegevens op en ga verder om de aandelen voor de vennootschap te definiëren.`,
        },
        foundation: {
          header: t`Contactgegevens`,
          introduction: t`Vul de contactgegevens van de stichting in.`,
          footer: t`Sla de contactgegevens op en ga verder om de certificaten voor de stichting te definiëren.`,
        },
      },
      shares: {
        default: {
          header: t`Aandelen uitgeven`,
          introduction: t`Selecteer voor elke aandeelhouder de soort aandelen en voer de reeks in. Voeg eventueel nog een extra reeks toe aan een aandeelhouder.`,
          footer: t`Alle uitgegeven aandelen voor de juiste aandeelhouder(s) ingevoerd? Sla op en ga verder om de akte toe te voegen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaten uitgeven`,
          introduction: t`Selecteer voor elke certificaathouder het soort certificaten en voer de reeks in. Voeg eventueel nog een extra reeks toe aan een certificaathouder.`,
          footer: t`Alle uitgegeven certificaten voor de juiste certificaathouder(s) ingevoerd? Sla op en ga verder om de akte toe te voegen.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle gegevens. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
          shareholders: t`Aandeelhouders`,
        },
        foundation: {
          shareholders: t`Certificaathouders`,
        },
      },
    },
    onboarding: {
      shares: {
        default: {
          header: t`Aandelen verdelen`,
          introduction: t`Selecteer voor elke aandeelhouder de soort aandelen en voer de reeks in die deze op het moment van opname in Evidend bezit. Voeg eventueel nog een extra reeks toe aan een aandeelhouder.`,
          footer: t`Alle aandelen voor de juiste aandeelhouder(s) ingevoerd? Sla op en ga verder om een kopie van het register toe te voegen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaten verdelen`,
          introduction: t`Selecteer voor elke certificaathouder het soort certificaten en voer de reeks in die deze op het moment van opname in Evidend bezit. Voeg eventueel nog een extra reeks toe aan een certificaathouder.`,
          footer: t`Alle certificaten voor de juiste certificaathouder(s) ingevoerd? Sla op en ga verder om een kopie van het register toe te voegen.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle gegevens. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
          shareholders: t`Aandeelhouders`,
        },
        foundation: {
          shareholders: t`Certificaathouders`,
        },
      },
    },
    issuance: {
      steps: {
        default: {
          issuance: t`Aandelenuitgifte`,
          shareTypes: t`Soorten aandelen definiëren`,
          shareholders: t`Aandeelhouders`,
          shares: t`Aandelen uitgeven`,
        },
        foundation: {
          issuance: t`Uitgifte van certificaten`,
          shareTypes: t`Soorten certificaten definiëren`,
          shareholders: t`Certificaathouders`,
          shares: t`Certificaten uitgeven`,
        },
      },
      shares: {
        default: {
          header: t`Aandelen uitgeven`,
          introduction: t`Selecteer de aandeelhouder aan wie aandelen zijn uitgegeven. Selecteer de soort en voer de reeks in. Voeg eventueel nog een extra aandeelhouder toe en herhaal de stappen.`,
          footer: t`Alle uitgegeven aandelen voor de juiste aandeelhouder(s) ingevoerd? Sla op en ga verder om de akte toe te voegen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaten uitgeven`,
          introduction: t`Selecteer de certificaathouder aan wie certificaten zijn uitgegeven. Selecteer de soort en voer de reeks in. Voeg eventueel nog een extra verkrijger toe en herhaal de stappen.`,
          footer: t`Alle uitgegeven certificaten voor de juiste certificaathouder(s) ingevoerd? Sla op en ga verder om de akte toe te voegen.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle gegevens. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
          shareholders: t`Aandeelhouders`,
        },
        foundation: {
          shareholders: t`Certificaathouders`,
        },
      },
    },
    transfer: {
      steps: {
        default: {
          transfer: t`Aandelenoverdracht`,
          shareholders: t`Aandeelhouders`,
          shares: t`Aandelen overdragen`,
        },
        foundation: {
          transfer: t`Overdracht van certificaten`,
          shareholders: t`Certificaathouders`,
          shares: t`Certificaten overdragen`,
        },
      },
      shares: {
        default: {
          header: t`Aandelen overdragen`,
          introduction: t`Vul de overdracht in tussen partijen. Voeg daarna eventuele andere overdrachten door op +Toevoegen te klikken.`,
          footer: t`Alle overdrachten toegevoegd? Sla op en ga verder om de akte toe te voegen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle aandeelhouders en aandelen voor deze overdracht. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
        },
        foundation: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle certificaathouders en certificaten voor deze overdracht. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
        },
      },
    },
    pledge: {
      steps: {
        default: {
          shares: t`Aandelen`,
          shareSeries: t`Aandelenreeksen`,
        },
        foundation: {
          shares: t`Certificaten`,
          shareSeries: t`Certificaatreeksen`,
        },
      },
      shareBatches: {
        default: {
          header: t`Aandelen`,
          introduction: t`Selecteer de verpande aandelenreeksen. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          footer: t`Sla de reeksen op en ga verder om de reeksen te verfijnen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaten`,
          introduction: t`Selecteer de verpande certificaatreeksen. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      shares: {
        default: {
          header: t`Aandelenreeksen`,
          introduction: t`Als niet de hele reeks verpand is, kun je hier de reeks bewerken.`,
          footer: t`Sla de verpande reeksen op en ga verder om de pandhouder op te voeren.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaatreeksen`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle gegevens. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
          shares: t`Verpande aandelen`,
          shareholders: t`Aandeelhouders`,
        },
        foundation: {
          shares: t`Verpande certificaten`,
          shareholders: t`Certificaathouders`,
        },
      },
    },
    endPledge: {
      steps: {
        default: {
          shares: t`Aandelen`,
          shareSeries: t`Aandelenreeksen`,
        },
        foundation: {
          shares: t`Certificaten`,
          shareSeries: t`Certificaatreeksen`,
        },
      },
      shareBatches: {
        default: {
          header: t`Aandelen`,
          introduction: t`Selecteer de aandelenreeksen waarvan de verpanding is beëindigd. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          footer: t`Selecteer de reeksen en ga verder om de reeksen te verfijnen.`,
          delegatee: t`Pandhouder`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaten`,
          introduction: t`Selecteer de certificaatreeksen waarvan de verpanding is beëindigd. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      shares: {
        default: {
          header: t`Aandelenreeksen`,
          introduction: t`Als de verpanding niet voor de gehele reeks is beëindigd, kun je hier de reeks bewerken.`,
          footer: t`Sla de reeksen op waarvoor de verpanding beëindigd is en ga verder om de akte toe te voegen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaatreeksen`,
          introduction: t`Als de verpanding niet voor de gehele reeks is beëindigd, kun je hier de reeks bewerken.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle gegevens. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
          shares: t`Aandelen waarvoor de verpanding beëindigd is`,
        },
        foundation: {
          shares: t`Certificaten waarvoor de verpanding beëindigd is`,
        },
      },
    },
    withdrawal: {
      steps: {
        default: {
          withdrawal: t`Intrekking van aandelen`,
          shares: t`Aandelen`,
        },
        foundation: {
          withdrawal: t`Intrekking van certificaten`,
          shares: t`Certificaten`,
        },
      },
      shares: {
        default: {
          header: t`Intrekking`,
          introduction: t`Selecteer de aandeelhouder waarvan aandelen zijn ingetrokken. Selecteer de soort en voer de reeks van de ingetrokken aandelen in. Voeg eventueel nog een extra aandeelhouder toe en herhaal de stappen.`,
          footer: t`Alle ingetrokken aandelen van de juiste aandeelhouder(s) ingevoerd? Sla op en ga verder om de akte toe te voegen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          introduction: t`Selecteer de certificaathouder waarvan certificaten zijn ingetrokken. Selecteer de soort en voer de reeks van de ingetrokken certificaten in. Voeg eventueel nog een extra certificaathouder toe en herhaal de stappen.`,
          footer: t`Alle ingetrokken certificaten van de juiste certificaathouder(s) ingevoerd? Sla op en ga verder om de akte toe te voegen.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle gegevens. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
        },
        foundation: {},
      },
    },
    uploadDocument: {
      default: {
        uploadDocument: t`Document`,
        uploadDocumentOnboarding: t`Bestaand register toevoegen`,
      },
      foundation: {
        uploadDocument: t`Document`,
        uploadDocumentOnboarding: t`Bestaand register toevoegen`,
      },
    },
    usufruct: {
      steps: {
        default: {
          shares: t`Aandelen`,
          shareSeries: t`Aandelenreeksen`,
        },
        foundation: {
          shares: t`Certificaten`,
          shareSeries: t`Certificaatreeksen`,
        },
      },
      shareBatches: {
        default: {
          header: t`Aandelen`,
          introduction: t`Selecteer de aandelenreeksen die met vruchtgebruik belast zijn. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          footer: t`De juiste, met vruchtgebruik te belasten, reeksen geselecteerd? Ga verder om de reeksen te verfijnen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaten`,
          introduction: t`Selecteer de certificaatreeksen die belast zijn met vruchtgebruik. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      shares: {
        default: {
          header: t`Aandelenreeksen`,
          introduction: t`Als niet de hele reeks is belast met vruchtgebruik, kun je hier de reeks bewerken.`,
          footer: t`Sla de reeksen die belast zijn met vruchtgebruik op en ga verder om de vruchtgebruiker op te voeren.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaatreeksen`,
          introduction: t`Als niet de hele reeks is belast met vruchtgebruik, kun je hier de reeks bewerken.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle gegevens. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
          shares: t`Aandelen met vruchtgebruik`,
        },
        foundation: {
          shares: t`Certificaten met vruchtgebruik`,
        },
      },
    },
    endUsufruct: {
      steps: {
        default: {
          shares: t`Aandelen`,
          shareSeries: t`Aandelenreeksen`,
        },
        foundation: {
          shares: t`Certificaten`,
          shareSeries: t`Certificaatreeksen`,
        },
      },
      shareBatches: {
        default: {
          header: t`Aandelen`,
          introduction: t`Selecteer de aandelenreeksen waarvan het vruchtgebruik is beëindigd. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          footer: t`Selecteer de reeksen en ga verder om de reeksen te verfijnen.`,
          delegatee: t`Vruchtgebruiker`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaten`,
          introduction: t`Selecteer de certificaatreeksen waarvan het vruchtgebruik is beëindigd. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      shares: {
        default: {
          header: t`Aandelenreeksen`,
          introduction: t`Als het vruchtgebruik niet voor de gehele reeks is beëindigd, kun je hier de reeks bewerken.`,
          footer: t`Sla de (bewerkte) aandelenreeksen op en ga verder om de akte toe te voegen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaatreeksen`,
          introduction: t`Als het vruchtgebruik niet voor de gehele reeks is beëindigd, kun je hier de reeks bewerken.`,
          footer: t`Sla de (bewerkte) certificaatreeksen op en ga verder om de akte toe te voegen.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle gegevens. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
          shares: t`Aandelen waarvoor het vruchtgebruik beëindigd is`,
        },
        foundation: {
          shares: t`Certificaten waarvoor het vruchtgebruik beëindigd is`,
        },
      },
    },
    attachment: {
      steps: {
        default: {
          shares: t`Aandelen`,
          shareSeries: t`Aandelenreeksen`,
        },
        foundation: {
          shares: t`Certificaten`,
          shareSeries: t`Certificaatreeksen`,
        },
      },
      details: {
        default: {
          header: t`Deurwaarder`,
          introduction: t`Vul de gegevens van de deurwaarder in.`,
          footer: t`Sla de deurwaarder op en ga verder om het exploot toe te voegen.`,
          partyRef: t`Deurwaarder`,
          partyRefCreate: t`Deurwaarder aanmaken`,
          requiredBy: t`Beslaglegger(s)`,
          requiredByPlaceholder: t`Beslaglegger`,
          requiredByCreate: t`Beslaglegger aanmaken`,
        },
        foundation: {},
      },
      shareBatches: {
        default: {
          header: t`Aandelen`,
          introduction: t`Selecteer de aandelenreeksen die in beslag gelegd zijn. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          footer: t`Sla de reeksen op en ga verder om de reeksen te verfijnen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaten`,
          introduction: t`Selecteer de certificaatreeksen die in beslag gelegd zijn. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      shares: {
        default: {
          header: t`Aandelenreeksen`,
          introduction: t`Als niet de hele reeks in beslag gelegd is, kun je hier de reeks bewerken.`,
          footer: t`Sla de reeksen op en ga verder om de deurwaarder op te voeren.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaatreeksen`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle gegevens. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
          shares: t`Aandelen in beslag gelegd`,
          shareholders: t`Aandeelhouders`,
        },
        foundation: {
          shares: t`Certificaten in beslag gelegd`,
          shareholders: t`Certificaathouders`,
        },
      },
    },
    endAttachment: {
      steps: {
        default: {
          shares: t`Aandelen`,
          shareSeries: t`Aandelenreeksen`,
        },
        foundation: {
          shares: t`Certificaten`,
          shareSeries: t`Certificaatreeksen`,
        },
      },
      details: {
        default: {
          header: t`Deurwaarder`,
          introduction: t`Vul de gegevens van de deurwaarder in.`,
          footer: t`Sla de deurwaarder op en ga verder om een document toe te voegen.`,
          partyRef: t`Deurwaarder`,
          partyRefCreate: t`Deurwaarder aanmaken`,
          requiredBy: t`Beslaglegger(s)`,
          requiredByPlaceholder: t`Beslaglegger`,
          requiredByCreate: t`Beslaglegger aanmaken`,
        },
        foundation: {},
      },
      shareBatches: {
        default: {
          header: t`Aandelen`,
          introduction: t`Selecteer de aandelenreeksen waarvan het beslag is opgeheven. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          footer: t`Sla de reeksen op en ga verder om de reeksen te verfijnen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaten`,
          introduction: t`Selecteer de certificaatreeksen waarvan het beslag is opgeheven. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      shares: {
        default: {
          header: t`Aandelenreeksen`,
          introduction: t`Als het beslag niet is opgeheven voor de gehele reeks, kun je hier de reeks bewerken.`,
          footer: t`Sla de reeksen op en ga verder om de deurwaarder op te voeren.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaatreeksen`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle gegevens. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
          shares: t`Beslag opgeheven voor aandelen`,
          shareholders: t`Aandeelhouders`,
        },
        foundation: {
          shares: t`Beslag opgeheven voor certificaten`,
          shareholders: t`Certificaathouders`,
        },
      },
    },
    administration: {
      steps: {
        default: {
          shares: t`Aandelen`,
          shareSeries: t`Aandelenreeksen`,
        },
        foundation: {
          shares: t`Certificaten`,
          shareSeries: t`Certificaatreeksen`,
        },
      },
      details: {
        default: {
          header: t`Bewindvoerder`,
          introduction: t`Kies de rechtsvorm van de bewindvoerder en vul de gegevens in.`,
          footer: t`Sla de bewindvoerder op en ga verder om de akte toe te voegen.`,
          partyRef: t`Bewindvoerder`,
          partyRefCreate: t`Bewindvoerder aanmaken`,
        },
        foundation: {},
      },
      shareBatches: {
        default: {
          header: t`Aandelen`,
          introduction: t`Selecteer de aandelenreeksen die onder bewind komen. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          footer: t`Sla de reeksen op en ga verder om de reeksen te verfijnen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaten`,
          introduction: t`Selecteer de certificaatreeksen die onder bewind komen. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      shares: {
        default: {
          header: t`Aandelenreeksen`,
          introduction: t`Als niet de hele reeks onder bewind staat, kun je hier de reeks bewerken.`,
          footer: t`Sla de reeksen op en ga verder om de bewindvoerder op te voeren.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaatreeksen`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle gegevens. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
          shares: t`Aandelen onder bewind`,
          shareholders: t`Aandeelhouders`,
        },
        foundation: {
          shares: t`Certificaten onder bewind`,
          shareholders: t`Certificaathouders`,
        },
      },
    },
    endAdministration: {
      steps: {
        default: {
          shares: t`Aandelen`,
          shareSeries: t`Aandelenreeksen`,
        },
        foundation: {
          shares: t`Certificaten`,
          shareSeries: t`Certificaatreeksen`,
        },
      },
      shareBatches: {
        default: {
          header: t`Aandelen`,
          introduction: t`Selecteer de aandelenreeksen waarvan het bewind is beëindigd. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          footer: t`Sla de reeksen op en ga verder om de reeksen te verfijnen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaten`,
          introduction: t`Selecteer de certificaatreeksen waarvan het bewind is beëindigd. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      shares: {
        default: {
          header: t`Aandelenreeksen`,
          introduction: t`Als het bewind niet voor de gehele reeks is beëindigd, kun je hier de reeks bewerken.`,
          footer: t`Sla de (bewerkte) reeksen op en ga verder om de akte toe te voegen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaatreeksen`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle gegevens. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
          shares: t`Aandelen waarvoor het bewind beëindigd is`,
        },
        foundation: {
          shares: t`Certificaten waarvoor het bewind beëindigd is`,
        },
      },
    },
    delegatee: {
      pledge: {
        default: {
          header: t`Pandhouder`,
          introduction: t`Kies de rechtsvorm van de pandhouder en vul de gegevens in.`,
          footer: t`Sla de pandhouder op en ga verder om de overgedragen rechten te selecteren.`,
        },
        foundation: {
          header: t`Pandhouder`,
          introduction: t`Kies de rechtsvorm van de pandhouder en vul de gegevens in.`,
          footer: t`Sla de pandhouder op en ga verder om de overgedragen rechten te selecteren.`,
        },
      },
      usufruct: {
        default: {
          header: t`Vruchtgebruiker`,
          introduction: t`Kies de rechtsvorm van de vruchtgebruiker en vul de gegevens in.`,
          footer: t`Sla de vruchtgebruiker op en ga verder om de overgedragen rechten te selecteren.`,
        },
        foundation: {
          header: t`Vruchtgebruiker`,
          introduction: t`Kies de rechtsvorm van de vruchtgebruiker en vul de gegevens in.`,
          footer: t`Sla de vruchtgebruiker op en ga verder om de overgedragen rechten te selecteren.`,
        },
      },
    },
    delegateRights: {
      pledge: {
        default: {
          header: t`Rechten pandhouder`,
          introduction: t`Welke rechten zijn aan de pandhouder overgedragen? Selecteer alleen de rechten die daadwerkelijk zijn overgedragen. Noteer rechten die onder opschortende voorwaarde zijn overgedragen in het opmerkingenveld in de volgende stap.`,
          footer: t`Sla de overgedragen rechten op en ga verder om de akte toe te voegen.`,
          rightsHeader: t`Rechten overdragen`,
          unavailableRightsHeader: t`Niet-overdraagbare rechten`,
          unavailableRightsIntroduction: t`De onderstaande rechten zijn niet overdraagbaar. Deze zijn al belast met vruchtgebruik of niet van toepassing bij de soorten aandelen.`,
          partyRef: t`Pandhouder`,
          partyRefCreate: t`Pandhouder aanmaken`,
        },
        foundation: {
          unavailableRightsIntroduction: t`De onderstaande rechten zijn niet overdraagbaar. Deze zijn al belast met vruchtgebruik of niet van toepassing bij de soorten certificaten.`,
        },
      },
      usufruct: {
        default: {
          header: t`Rechten vruchtgebruiker`,
          introduction: t`Welke rechten zijn aan de vruchtgebruiker overgedragen?`,
          footer: t`Ga verder om de akte toe te voegen.`,
          rightsHeader: t`Rechten overdragen`,
          unavailableRightsHeader: t`Niet-overdraagbare rechten`,
          unavailableRightsIntroduction: t`De onderstaande rechten zijn niet overdraagbaar. Deze zijn al verpand, belast met vruchtgebruik of niet van toepassing bij de soorten aandelen.`,
          partyRef: t`Vruchtgebruiker`,
          partyRefCreate: t`Vruchtgebruiker aanmaken`,
        },
        foundation: {
          header: t`Rechten vruchtgebruiker`,
          introduction: t`Welke rechten zijn aan de vruchtgebruiker overgedragen?`,
          footer: t`Ga verder om de akte toe te voegen.`,
          rightsHeader: t`Rechten overdragen`,
          unavailableRightsIntroduction: t`De onderstaande rechten zijn niet overdraagbaar. Deze zijn al verpand, belast met vruchtgebruik of niet van toepassing bij de soorten certificaten.`,
        },
      },
    },
    conversion: {
      steps: {
        default: {
          conversion: t`Conversie`,
          shareBatches: t`Geconverteerde aandelen`,
          shareTypes: t`Geconverteerde soort aandelen`,
          shares: t`Nieuwe reeksen definiëren`,
          summary: t`Samenvatting`,
        },
        foundation: {
          shareTypes: t`Nieuwe certificaatsoorten definiëren`,
        },
      },
      shareBatches: {
        default: {
          header: t`Geconverteerde aandelen`,
          introduction: t`Selecteer de soort(en) aandelen die geconverteerd worden. Het is mogelijk om alleen bepaalde reeksen te selecteren.`,
          footer: t`Sla de geconverteerde reeksen op om de geconverteerde soort aandelen te definiëren.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Geconverteerde certificaten`,
          introduction: t`Selecteer de soort(en) certificaten die geconverteerd worden. Het is mogelijk om alleen bepaalde reeksen te selecteren.`,
          footer: t`Sla de geconverteerde reeksen op om de geconverteerde soort certificaten te definiëren.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      shares: {
        default: {
          header: t`Nieuwe reeksen definiëren`,
          introduction: t`Geef voor elke aandeelhouder aan hoe de geconverteerde aandelen zijn genummerd. Klik op “nummering behouden” om geen wijzigingen aan te brengen in de nummering.`,
          footer: t`Sla de nummering op en ga verder om de akte toe te voegen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Nieuwe reeksen definiëren`,
          introduction: t`Geef voor elke certificaathouder aan hoe de geconverteerde certificaten zijn genummerd. Klik op “nummering behouden” om geen wijzigingen aan te brengen in de nummering.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer het geplaatst kapitaal en de verdeling voor en na de conversie. `,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
          sharesHeaderBefore: t`Aandelenverdeling voor conversie`,
          sharesHeaderAfter: t`Aandelenverdeling na conversie`,
        },
        foundation: {
          sharesHeaderBefore: t`Certificatenverdeling voor conversie`,
          sharesHeaderAfter: t`Certificatenverdeling na conversie`,
        },
      },
    },
    payingUp: {
      steps: {
        default: {
          shares: t`Aandelen`,
          shareSeries: t`Aandelenreeksen`,
        },
        foundation: {
          shares: t`Certificaten`,
          shareSeries: t`Certificaatreeksen`,
        },
      },
      shareBatches: {
        default: {
          header: t`Aandelen`,
          introduction: t`Selecteer de aandelenreeksen die zijn volgestort. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          footer: t`Selecteer de reeksen en ga verder om de reeksen te verfijnen.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaten`,
          introduction: t`Selecteer de certificaatreeksen die zijn volgestort. In het volgende scherm kun je de reeksen nog bewerken indien gewenst.`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      shares: {
        default: {
          header: t`Aandelenreeksen`,
          introduction: t`Als niet de gehele reeks is volgestort, kun je hier de reeks bewerken.`,
          footer: t`Sla de reeksen op die zijn volgestort en ga verder.`,
          ...getShareSeriesLabels(),
        },
        foundation: {
          header: t`Certificaatreeksen`,
          ...getShareSeriesLabelsFoundation(),
        },
      },
      summary: {
        default: {
          header: t`Samenvatting`,
          introduction: t`Controleer alle gegevens. Gebruik het menu aan de linkerzijde om terug te keren en correcties door te voeren.`,
          footer: t`Alle gegevens correct? Sla op om de mutatie af te ronden.`,
          shares: t`Aandelen die zijn volgestort`,
          shareholders: t`Aandeelhouders`,
        },
        foundation: {
          shares: t`Certificaten die zijn volgestort`,
          shareholders: t`Certificaathouders`,
        },
      },
    },
  } as const);
