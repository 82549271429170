import type { LegalEntity } from '@elseu/sdu-evidend-graphql';
import type { Spacings } from '@elseu/sdu-titan';
import { Link, RotateIcon } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { GeneralInfoContainer } from 'components/GeneralInfoContainer/GeneralInfoContainer';
import { PartyUpdateSidebar } from 'components/PartyUpdateSidebar/PartyUpdateSidebar';
import { isEmptyDate } from 'forms/helpers/dateTransform';
import { formatLegalForm } from 'helpers/formatLegalForm';
import { formatCountryByCode, formatDate } from 'helpers/formatShares';
import { useLegalEntityQuery } from 'legalEntities/hooks/useLegalEntityQuery';
import type { ReactNode } from 'react';
import { useState } from 'react';

import { ForeignSidebarLink } from './ForeignSidebarLink';
import { KvkSidebarLink } from './KvkSidebarLink';

interface LegalEntityPersonalInfoProps {
  canManage?: boolean;
  legalEntityId: string;
  legalEntity: LegalEntity;
  hasHeader?: boolean;
  hasHeaderButton?: boolean;
  hasKvkUpdateButton?: boolean;
  onKvkUpdate?: () => void;
  hasDivider?: boolean;
  headerButton?: ReactNode;
  spaceAfter?: Spacings;
}

const ForeignLegalEntityPersonalInfo = ({
  canManage = false,
  legalEntity,
  hasHeader = true,
  hasHeaderButton = true,
  hasDivider = true,
  headerButton,
  spaceAfter,
}: LegalEntityPersonalInfoProps) => {
  const editButton = <ForeignSidebarLink legalEntity={legalEntity as LegalEntity} />;
  const { name, tradeRegisterNumber, countryOfRegistration } = legalEntity;

  const contentArray = [
    { label: t`Naam`, value: name },
    {
      label: t`Registratienummer`,
      value: tradeRegisterNumber,
    },
    {
      label: t`Land van handelsregister`,
      value: formatCountryByCode(countryOfRegistration),
    },
  ];

  return (
    <GeneralInfoContainer
      contentArray={contentArray}
      hasDivider={hasDivider}
      header={hasHeader ? t`Gegevens` : ''}
      headerButton={hasHeaderButton && canManage ? headerButton || editButton : undefined}
      spaceAfter={spaceAfter}
    />
  );
};

const LocalLegalEntityPersonalInfo = ({
  canManage = false,
  legalEntity,
  hasHeader = true,
  hasHeaderButton = true,
  hasKvkUpdateButton,
  onKvkUpdate,
  hasDivider = true,
  headerButton,
  spaceAfter,
}: LegalEntityPersonalInfoProps) => {
  const [isPartyUpdateSidebarShown, setPartyUpdateSidebarShown] = useState(false);

  const { name, tradeRegisterNumber, incorporatedOn, rsinNumber, lastFetchedFromKvkOn } =
    legalEntity;

  const syncButton = legalEntity.tradeRegisterNumber ? (
    <Link prefixAdornment={<RotateIcon />} onClick={() => setPartyUpdateSidebarShown(true)}>
      <Trans>Bijwerken</Trans>
    </Link>
  ) : undefined;

  const kvkUpdatebutton = <KvkSidebarLink legalEntity={legalEntity} />;
  const isKvkUpdateButtonShown =
    hasKvkUpdateButton && canManage && !legalEntity.tradeRegisterNumber;

  const subHeader = (() => {
    if (!hasHeaderButton) return;
    if (isEmptyDate(lastFetchedFromKvkOn)) return;
    const date = formatDate(lastFetchedFromKvkOn as Date);
    return t`Laatst bijgewerkt op: ${date}`;
  })();

  const contentArray = [
    { label: t`Naam`, value: name },
    {
      label: t`KVK-nummer`,
      value: isKvkUpdateButtonShown ? kvkUpdatebutton : tradeRegisterNumber,
    },
    { label: t`Rechtsvorm`, value: formatLegalForm(legalEntity) },
    {
      label: t`Oprichtingsdatum`,
      value: formatDate(incorporatedOn as Date),
    },
    { label: t`RSIN`, value: rsinNumber },
    {
      label: t`Datum statuten`,
      value: formatDate(legalEntity.register?.articleOfAssociationUpdatedOn as Date),
    },
  ];
  if (legalEntity.register?.trustConditionsUpdatedOn)
    contentArray.push({
      label: t`Datum laatste wijziging administratievoorwaarden`,
      value: formatDate(legalEntity.register.trustConditionsUpdatedOn as Date),
    });
  if (legalEntity.register?.replacement)
    contentArray.push({
      label: t`Vervangend register`,
      value: t`Ja`,
    });

  return (
    <>
      <GeneralInfoContainer
        contentArray={contentArray}
        hasDivider={hasDivider}
        header={hasHeader ? t`Gegevens` : ''}
        headerButton={hasHeaderButton && canManage ? headerButton || syncButton : undefined}
        spaceAfter={spaceAfter}
        subHeader={subHeader}
      />
      <PartyUpdateSidebar
        isSidebarShown={isPartyUpdateSidebarShown}
        legalEntity={legalEntity}
        onSidebarClose={() => {
          if (onKvkUpdate) onKvkUpdate();
          setPartyUpdateSidebarShown(false);
        }}
        onUpdate={onKvkUpdate}
      />
    </>
  );
};

export const LegalEntityPersonalInfo = (
  props: Omit<LegalEntityPersonalInfoProps, 'legalEntity'>,
) => {
  const { legalEntityId } = props;
  const { data: { legalEntity } = {} } = useLegalEntityQuery({
    variables: {
      legalEntityId,
    },
  });

  if (!legalEntity) return null;

  if (legalEntity.countryOfRegistration === 'NL') {
    return <LocalLegalEntityPersonalInfo legalEntity={legalEntity as LegalEntity} {...props} />;
  }

  return <ForeignLegalEntityPersonalInfo legalEntity={legalEntity as LegalEntity} {...props} />;
};
