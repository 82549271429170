import type { Attachment, Party } from '@elseu/sdu-evidend-graphql';
import { Block, Box, Card, Text } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import type { SummaryCardsComponentProps } from 'helpers/formatMutation';
import { useLabels } from 'hooks/useLabels';
import { keyBy, uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import { CapitalCardListItem } from 'summaries/CapitalCards/CapitalCardListItem';
import { ShareholderCapitalCard } from 'summaries/CapitalCards/ShareholderCapitalCard';
import { DeedCard } from 'summaries/DeedCard/DeedCard';
import PartyCard from 'summaries/PartyCard/PartyCard';
import { attachmentOptions } from 'wizards/Attachment/generators';

const getAttachmentsOfParty = (attachments: Attachment[], party: Party): Attachment[] =>
  attachments.filter((attachment) => attachment.owner.id === party.id);

export const AttachmentSummaryCards = ({
  backgroundVariant = 'white',
  mutation,
  onDocumentSave,
}: SummaryCardsComponentProps) => {
  const { register } = mutation;
  const { legalEntity } = register;
  const labels = useLabels('attachment.summary', legalEntity.isFoundation);
  const attachmentOptionsObject = keyBy(attachmentOptions(), 'value');
  const attachments = useMemo(() => mutation.attachment || [], [mutation.attachment]);
  const { parties, bailiff, requiredBy, attachmentTypeLabel } = useMemo(() => {
    const parties = uniqBy(
      attachments.map((attachment) => attachment.owner),
      'id',
    );
    const bailiff = attachments[0]?.details?.bailiff;
    const requiredBy = attachments[0]?.details?.requiredBy;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const attachmentTypeLabel = attachmentOptionsObject[attachments[0]?.type]?.label;
    return { parties, bailiff, requiredBy, attachmentTypeLabel };
  }, [attachmentOptionsObject, attachments]);

  return (
    <>
      {!!attachmentTypeLabel && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            <Trans>Type beslaglegging</Trans>
          </Text>

          <Card spaceAfter={6}>
            <Block>{attachmentTypeLabel}</Block>
          </Card>
        </>
      )}

      {!!parties.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            {labels.shares}
          </Text>

          <Box spaceAfter={10}>
            {parties.map((party) => (
              <ShareholderCapitalCard
                key={party.id}
                backgroundVariant={backgroundVariant}
                compareDeceaseDate={mutation.effectiveDate}
                party={party}
              >
                {getAttachmentsOfParty(attachments, party).map((attachment, index) => (
                  <CapitalCardListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    shareSeries={attachment.shareSeries}
                  />
                ))}
              </ShareholderCapitalCard>
            ))}
          </Box>
        </>
      )}

      {!!bailiff && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            <Trans>Deurwaarder</Trans>
          </Text>

          <PartyCard
            backgroundVariant={backgroundVariant}
            compareDeceaseDate={mutation.effectiveDate}
            party={bailiff}
          />
        </>
      )}

      {!!requiredBy?.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            <Trans>Beslaglegger(s)</Trans>
          </Text>

          {requiredBy.map((requiredByParty) => (
            <PartyCard
              key={requiredByParty.id}
              backgroundVariant={backgroundVariant}
              party={requiredByParty}
            />
          ))}
        </>
      )}

      {mutation.document && (
        <Block spaceAfter={10}>
          <DeedCard
            backgroundVariant={backgroundVariant}
            document={mutation.document}
            mutation={mutation}
            onDocumentSave={onDocumentSave}
          />
        </Block>
      )}
    </>
  );
};
