import { PartyUpdateField } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';

import { formatLegalForm } from './formatLegalForm';
import { formatDate } from './formatShares';

type PartyUpdateFieldObjectProps = {
  [type in PartyUpdateField]: {
    label: () => string;
    formatter: (value: string) => any;
    field: string;
  };
};
export const partyUpdateFieldObject: PartyUpdateFieldObjectProps = {
  [PartyUpdateField.STATUTORY_NAME]: {
    label: () => t`Naam`,
    formatter: (value: string) => value,
    field: 'name',
  },
  [PartyUpdateField.INCORPORATED_ON]: {
    label: () => t`Oprichtingsdatum`,
    formatter: (value: string) => (value ? formatDate(new Date(value)) : ''),
    field: 'incorporatedOn',
  },
  [PartyUpdateField.INCORPORATED_BY]: {
    label: () => '',
    formatter: (value: string) => value,
    field: 'incorporatedBy',
  },
  [PartyUpdateField.COUNTRY_OF_REGISTRATION]: {
    label: () => t`Land van registratie`,
    formatter: (value: string) => value,
    field: 'countryOfRegistration',
  },
  [PartyUpdateField.TRADE_REGISTER_NUMBER]: {
    label: () => t`KVK-nummer`,
    formatter: (value: string) => value,
    field: 'tradeRegisterNumber',
  },
  [PartyUpdateField.VISITOR_ADDRESS_STREET_NAME]: {
    label: () => t`Bezoekadres: straatnaam`,
    formatter: (value: string) => value,
    field: 'visitorAddress.streetName',
  },
  [PartyUpdateField.VISITOR_ADDRESS_STREET_NUMBER]: {
    label: () => t`Bezoekadres: huisnummer`,
    formatter: (value: string) => value,
    field: 'visitorAddress.streetNumber',
  },
  [PartyUpdateField.VISITOR_ADDRESS_STREET_NUMBER_ADDITION]: {
    label: () => t`Bezoekadres: toevoeging`,
    formatter: (value: string) => value,
    field: 'visitorAddress.streetNumberAddition',
  },
  [PartyUpdateField.VISITOR_ADDRESS_POST_OFFICE_BOX_NUMBER]: {
    label: () => t`Bezoekadres: toevoeging`,
    formatter: (value: string) => value,
    field: 'visitorAddress.postOfficeBoxNumber',
  },
  [PartyUpdateField.VISITOR_ADDRESS_POSTAL_CODE]: {
    label: () => t`Bezoekadres: postcode`,
    formatter: (value: string) => value,
    field: 'visitorAddress.postalCode',
  },
  [PartyUpdateField.VISITOR_ADDRESS_CITY]: {
    label: () => t`Bezoekadres: woonplaats`,
    formatter: (value: string) => value,
    field: 'visitorAddress.city',
  },
  [PartyUpdateField.VISITOR_ADDRESS_COUNTRY_CODE]: {
    label: () => t`Bezoekadres: land`,
    formatter: (value: string) => value,
    field: 'visitorAddress.countryCode',
  },
  [PartyUpdateField.VISITOR_ADDRESS_STREET_AND_NUMBER]: {
    label: () => t`Bezoekadres: straat en huisnummer`,
    formatter: (value: string) => value,
    field: 'visitorAddress.streetHouseNumber',
  },
  [PartyUpdateField.VISITOR_ADDRESS_POSTAL_CODE_CITY]: {
    label: () => t`Bezoekadres: postcode en woonplaats`,
    formatter: (value: string) => value,
    field: 'visitorAddress.postalCodeCity',
  },
  [PartyUpdateField.VISITOR_ADDRESS_REGION]: {
    label: () => t`Bezoekadres: regio`,
    formatter: (value: string) => value,
    field: 'visitorAddress.region',
  },
  [PartyUpdateField.CORRESPONDENCE_ADDRESS_STREET_NAME]: {
    label: () => t`Correspondentieadres: straatnaam`,
    formatter: (value: string) => value,
    field: 'correspondenceAddress.streetName',
  },
  [PartyUpdateField.CORRESPONDENCE_ADDRESS_STREET_NUMBER]: {
    label: () => t`Correspondentieadres: huisnummer`,
    formatter: (value: string) => value,
    field: 'correspondenceAddress.streetNumber',
  },
  [PartyUpdateField.CORRESPONDENCE_ADDRESS_STREET_NUMBER_ADDITION]: {
    label: () => t`Correspondentieadres: toevoeging`,
    formatter: (value: string) => value,
    field: 'correspondenceAddress.streetNumberAddition',
  },
  [PartyUpdateField.CORRESPONDENCE_ADDRESS_POST_OFFICE_BOX_NUMBER]: {
    label: () => t`Correspondentieadres: toevoeging`,
    formatter: (value: string) => value,
    field: 'correspondenceAddress.postOfficeBoxNumber',
  },
  [PartyUpdateField.CORRESPONDENCE_ADDRESS_POSTAL_CODE]: {
    label: () => t`Correspondentieadres: postcode`,
    formatter: (value: string) => value,
    field: 'correspondenceAddress.postalCode',
  },
  [PartyUpdateField.CORRESPONDENCE_ADDRESS_CITY]: {
    label: () => t`Correspondentieadres: woonplaats`,
    formatter: (value: string) => value,
    field: 'correspondenceAddress.city',
  },
  [PartyUpdateField.CORRESPONDENCE_ADDRESS_COUNTRY_CODE]: {
    label: () => t`Correspondentieadres: land`,
    formatter: (value: string) => value,
    field: 'correspondenceAddress.countryCode',
  },
  [PartyUpdateField.CORRESPONDENCE_ADDRESS_STREET_AND_NUMBER]: {
    label: () => t`Correspondentieadres: straat en huisnummer`,
    formatter: (value: string) => value,
    field: 'correspondenceAddress.streetHouseNumber',
  },
  [PartyUpdateField.CORRESPONDENCE_ADDRESS_POSTAL_CODE_CITY]: {
    label: () => t`Correspondentieadres: postcode en woonplaats`,
    formatter: (value: string) => value,
    field: 'correspondenceAddress.postalCodeCity',
  },
  [PartyUpdateField.CORRESPONDENCE_ADDRESS_REGION]: {
    label: () => t`Correspondentieadres: regio`,
    formatter: (value: string) => value,
    field: 'correspondenceAddress.region',
  },
  [PartyUpdateField.RSIN_NUMBER]: {
    label: () => t`RSIN`,
    formatter: (value: string) => value,
    field: 'rsinNumber',
  },
  [PartyUpdateField.EMAIL_ADDRESSES]: {
    label: () => t`E-mailadres`,
    formatter: (value: string) => value,
    field: 'emailAddress',
  },
  [PartyUpdateField.PHONE_NUMBER]: {
    label: () => t`Telefoonnummer`,
    formatter: (value: string) => value,
    field: 'phoneNumber',
  },
  [PartyUpdateField.LEGAL_FORM]: {
    label: () => t`Rechtsvorm`,
    formatter: (legalForm: string) => formatLegalForm({ legalForm }),
    field: 'legalForm',
  },
  [PartyUpdateField.REGISTERED_ON]: {
    label: () => t`Datum inschrijving KVK`,
    formatter: (value: string) => (value ? formatDate(new Date(value)) : ''),
    field: 'registeredOn',
  },
};
