import { Input } from '@elseu/sdu-titan';
import { formFieldErrorProps } from 'components/FormFields/formFieldErrorProps';
import type { FocusEvent } from 'react';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { NumberFormatValues } from 'react-number-format';
import NumberFormat from 'react-number-format';

const CustomInput = ({ inputRef, ...props }: any) => <Input {...props} ref={inputRef} />;

type INumberField = {
  // NumberField props.
  isNegativeAllowed?: boolean;
  maxDecimals?: number;
  isErrorHidden?: boolean;
  onBlur?: (event: React.FocusEvent) => any;
  // Input props (IInput is not exported from Titan).
  [key: string]: any;
};

const NumberField = ({
  name,
  isNegativeAllowed = false,
  maxDecimals = 0,
  isErrorHidden = false,
  onBlur,
  ...props
}: INumberField) => {
  const { control } = useFormContext();

  const parseValue = useCallback(({ value, floatValue }: NumberFormatValues) => {
    if (value === '') return null;
    return floatValue;
  }, []);

  const handleBlur = useCallback(
    (handler: (e: FocusEvent) => void, event: FocusEvent) => {
      handler(event);
      onBlur?.(event);
    },
    [onBlur],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { name, value, ref, onBlur, onChange }, formState: { errors } }) => (
        <NumberFormat
          {...formFieldErrorProps(errors, name, isErrorHidden)}
          allowNegative={isNegativeAllowed}
          autoComplete="off"
          customInput={CustomInput}
          decimalScale={maxDecimals}
          decimalSeparator=","
          inputRef={ref}
          name={name}
          spellCheck="false"
          thousandSeparator="."
          value={value}
          onBlur={(event: any) => handleBlur(onBlur, event)}
          onValueChange={(value) => onChange(parseValue(value))}
          {...props}
        />
      )}
    />
  );
};

NumberField.defaultProps = {
  spaceAfter: 6,
};

export { NumberField };
