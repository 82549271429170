import type { Maybe } from '@elseu/sdu-evidend-graphql';
import { DocumentLabel, DocumentType, MutationType } from '@elseu/sdu-evidend-graphql';
import { Block, Box, Card, Checkbox, Flex, Link, Loader, Text } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { CardProgressBar } from 'components/CardProgressBar/CardProgressBar';
import { Column, Columns } from 'components/Columns';
import { DatePickerField } from 'components/FormFields/DatePickerField';
import { InputField } from 'components/FormFields/InputField';
import { SelectField } from 'components/FormFields/SelectField';
import UploadFileButton from 'components/UploadFileButton/UploadFileButton';
import { dateBasicValidation } from 'forms/helpers/dateTransform';
import { documentTypeOptions, formatDocumentType } from 'helpers/document';
import { mutationLabelObject } from 'helpers/formatMutation';
import { useFakeProgress } from 'hooks/useFakeProgress';
import { get } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import * as yup from 'yup';

import { DocumentDeedPassedForm, DocumentDeedPassedFormSchema } from './DocumentDeedPassedForm';

const getDocumentUploadFormSchema = () => {
  return yup.object({
    deedPassed: yup.mixed().when('type', {
      is: (val: any) => val === DocumentType.NOTARIAL_DEED,
      then: DocumentDeedPassedFormSchema,
      otherwise: undefined,
    }),
    type: yup.mixed().oneOf(Object.values(DocumentType)).required(),
    commentary: yup.string().trim().nullable(),
    date: dateBasicValidation.required(),
    labels: yup.array().of(yup.mixed().oneOf(Object.values(DocumentLabel)).required()),
    file: yup
      .object({
        content: yup.mixed<File>().nullable(), // TODO: is this always optional?
        name: yup.string().trim().nullable(),
        mimeType: yup.string().trim().nullable(),
      })
      .nullable(),
  });
};

export const documentUploadFormSchema = getDocumentUploadFormSchema();

export type DocumentUploadFormValues = yup.InferType<typeof documentUploadFormSchema>;

const documentDateLabels = {
  [DocumentType.NOTARIAL_DEED]: () => t`Passeerdatum`,
  [DocumentType.PRIVATE_DEED]: () => t`Datum ondertekening`,
  [DocumentType.OTHER]: () => t`Datum`,
};

interface IDocumentUploadForm {
  isFoundation: boolean;
  mutationType?: MutationType;
  hasColumns?: boolean;
  nestedField?: string;
  isRequired?: boolean;
  canChooseDate?: boolean;
  canChooseType?: boolean;
  cardBackgroundVariant?: 'white' | 'grey';
  children?: React.ReactNode;
}
const DocumentUploadForm = ({
  canChooseDate = true,
  canChooseType,
  cardBackgroundVariant = 'grey',
  hasColumns,
  isRequired,
  isFoundation,
  mutationType,
  nestedField,
  children,
}: IDocumentUploadForm) => {
  const { watch, getFieldState, setValue, clearErrors, trigger, formState } = useFormContext();

  const { isSubmitting, errors } = formState;

  const documentType: Maybe<DocumentType> = watch(`${nestedField}type`);
  const documentLabels: Maybe<DocumentLabel[]> = watch(`${nestedField}labels`);
  const fileName = watch(`${nestedField}file.name`);
  const { isDirty: isContentDirty } = getFieldState(`${nestedField}file.content`);

  const progress = useFakeProgress(isSubmitting);

  // Get the error message for the content field (which is not a real field).
  const contentError = get(errors, `${nestedField}content`);

  const dateLabel = useMemo(() => {
    if (mutationType === MutationType.ONBOARDING) return t`Startdatum in Evidend`;
    if (!documentType) return t`Datum`;
    return documentDateLabels[documentType]();
  }, [documentType, mutationType]);

  const datePlaceholder = useMemo(() => {
    if (!documentType) return DocumentType.OTHER;
    return formatDocumentType(documentType, mutationType);
  }, [documentType, mutationType]);

  const hasAoAOption = useMemo(() => {
    if (documentType !== DocumentType.NOTARIAL_DEED) return false;
    if (!mutationType) return true;
    return (
      mutationType !== MutationType.INCORPORATION && mutationLabelObject[mutationType].canChangeAoA
    );
  }, [documentType, mutationType]);

  const hasTrustOption = useMemo(() => {
    if (!isFoundation) return false;
    if (!mutationType) return true;
    return (
      mutationType !== MutationType.INCORPORATION && mutationLabelObject[mutationType].canChangeAoA
    );
  }, [isFoundation, mutationType]);

  const addDocumentLabel = (value: boolean, label: DocumentLabel) => {
    const currentDocumentLabels = documentLabels ?? [];
    const newDocumentLabels = [
      ...currentDocumentLabels.filter((elem) => elem !== label),
      ...(value ? [label] : []),
    ];
    setValue(`${nestedField}labels`, newDocumentLabels);
  };

  useEffect(() => {
    if (documentType === DocumentType.NOTARIAL_DEED) return;
    setValue(`${nestedField}deedPassed`, null);
  }, [documentType, nestedField, setValue]);
  return (
    <>
      <Columns count={hasColumns ? 2 : 1}>
        <Column>
          {canChooseType && (
            <SelectField
              isSearchable
              required
              label={t`Type`}
              name={`${nestedField}type`}
              options={documentTypeOptions()}
              placeholder={t`Kies een type`}
            />
          )}

          {documentType && canChooseDate && (
            <DatePickerField
              isFutureDisabled
              required
              label={dateLabel}
              name={`${nestedField}date`}
              placeholder={datePlaceholder}
              spaceAfter={6}
            />
          )}

          {children}

          {documentType === DocumentType.NOTARIAL_DEED && (
            <DocumentDeedPassedForm nestedField={`${nestedField}deedPassed.`} />
          )}

          <InputField
            label={t`Opmerkingen`}
            name={`${nestedField}commentary`}
            placeholder={t`Opmerkingen`}
            rows={3}
            type="textarea"
          />
        </Column>
      </Columns>

      <Text color="grey80" spaceAfter={4} type="labelBold">
        <Trans>Document</Trans>
        {!isRequired && (
          <span>
            {' '}
            (<Trans>optioneel</Trans>)
          </span>
        )}
      </Text>
      {fileName && (
        <Card backgroundVariant={cardBackgroundVariant} spaceAfter={6}>
          <Block>
            <Flex justifyContent="space-between">
              {fileName}
              {!isSubmitting && (
                <Link
                  onClick={() => {
                    setValue(`${nestedField}file`, null, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    trigger();
                  }}
                >
                  <Trans>Verwijderen</Trans>
                </Link>
              )}
              {isContentDirty && isSubmitting && (
                <Loader height={24} variant="spinner" width="24" />
              )}
            </Flex>
            {contentError && (
              <Text color="secondary70" type="labelTiny">
                {typeof contentError.message === 'string' ? contentError.message : ''}
              </Text>
            )}
            <CardProgressBar progress={progress} />
          </Block>
        </Card>
      )}
      {!fileName && (
        <Box spaceAfter={6}>
          <UploadFileButton
            disabled={isSubmitting}
            onFile={(file) => {
              setValue(`${nestedField}file.content`, file, { shouldDirty: true });
              setValue(`${nestedField}file.name`, file.name, { shouldDirty: true });
              setValue(`${nestedField}file.mimeType`, file.type, { shouldDirty: true });
              clearErrors(`${nestedField}file.content`);
              trigger();
            }}
          />
        </Box>
      )}
      {(hasAoAOption || hasTrustOption) && (
        <Text color="grey80" spaceAfter={4} type="labelBold">
          <Trans>Overige gegevens</Trans>
        </Text>
      )}
      {hasAoAOption && (
        <Box spaceAfter={4}>
          <Checkbox
            isChecked={documentLabels?.includes(DocumentLabel.ARTICLE_OF_ASSOCIATION)}
            label={t`Bevat een statutenwijziging`}
            name=""
            onChange={(value) => addDocumentLabel(value, DocumentLabel.ARTICLE_OF_ASSOCIATION)}
          />
        </Box>
      )}
      {hasTrustOption && (
        <Box spaceAfter={4}>
          <Checkbox
            isChecked={documentLabels?.includes(DocumentLabel.TRUST_CONDITIONS)}
            label={t`Bevat een wijziging van de administratievoorwaarden`}
            name=""
            onChange={(value) => addDocumentLabel(value, DocumentLabel.TRUST_CONDITIONS)}
          />
        </Box>
      )}
    </>
  );
};
DocumentUploadForm.defaultProps = {
  canChooseType: false,
  hasColumns: true,
  nestedField: '',
};

export { DocumentUploadForm, documentUploadFormSchema as DocumentUploadFormSchema };
