import { Block } from '@elseu/sdu-titan';
import type { SummaryCardsComponentProps } from 'helpers/formatMutation';
import { DeedCard } from 'summaries/DeedCard/DeedCard';

export const ArticlesOfAssociationSummaryCards = ({
  backgroundVariant = 'white',
  mutation,
  onDocumentSave,
}: SummaryCardsComponentProps) => {
  return (
    <>
      {mutation.document && (
        <Block spaceAfter={10}>
          <DeedCard
            backgroundVariant={backgroundVariant}
            document={mutation.document}
            mutation={mutation}
            onDocumentSave={onDocumentSave}
          />
        </Block>
      )}
    </>
  );
};
