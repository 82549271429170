import { t } from '@lingui/macro';

const currencyCodeOptions = () => [
  {
    label: t`Euro`,
    value: 'EUR',
  },
  {
    label: t`Gulden`,
    value: 'NLG',
  },
  {
    label: t`Britse pond`,
    value: 'GBP',
  },
  {
    label: t`Amerikaanse dollar`,
    value: 'USD',
  },
];

export default currencyCodeOptions;
