import type { EndAdministration, EndAdministrationInput } from '@elseu/sdu-evidend-graphql';

import { mapShareSeriesToShareSeriesInput } from './ShareSeries';

export const mapEndAdministrationToEndAdministrationInput = (
  endAdministration: EndAdministration,
): EndAdministrationInput => ({
  ownerPartyId: endAdministration.owner.id,
  shareSeries: mapShareSeriesToShareSeriesInput(endAdministration.shareSeries),
});
