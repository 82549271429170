import type { DelegationDetails, DelegationDetailsInput } from '@elseu/sdu-evidend-graphql';
import { pick } from 'lodash';

export const mapDelegationDetailsToDelegationDetailsInput = (
  delegationDetails: DelegationDetails,
): DelegationDetailsInput => {
  return {
    ...pick(delegationDetails, ['voting', 'dividend', 'meeting']),
    delegateeId: delegationDetails.delegatee.id,
  };
};
