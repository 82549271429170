/**
 * Given a predicate function, returns a function that takes an array of objects and a new object.
 * - If any object matches the predicate, the new object is merged with the old object.
 * - If no object matches the predicate, the new object is added to the array.
 * - Only the first match is replaced.
 * @example ```ts
 * const addWithdrawalCard = mergeCards<WithdrawalCard>((a, b) => a.from.id === b.from.id)
 * // later
 * const cards = addWithdrawalCard(cards, newCard)
 * ```
 **/

export const mergeCards =
  <T extends { seriesData: unknown[] }>(predicate: (a: T, b: T) => boolean) =>
  (cards: T[], card: T): T[] => {
    const cardExistsAlready = cards.find((oldCard) => predicate(oldCard, card));
    if (cardExistsAlready)
      cardExistsAlready.seriesData = [...cardExistsAlready.seriesData, ...card.seriesData];
    return cardExistsAlready ? cards : [...cards, card];
  };
