import type { Issuance, Party, ShareType } from '@elseu/sdu-evidend-graphql';
import { Block, Box, Text } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import type { SummaryCardsComponentProps } from 'helpers/formatMutation';
import { sortShareTypes } from 'helpers/formatShares';
import { useLabels } from 'hooks/useLabels';
import { uniqBy } from 'lodash';
import { useMemo } from 'react';
import { CapitalCardListItem } from 'summaries/CapitalCards/CapitalCardListItem';
import { ShareholderCapitalCard } from 'summaries/CapitalCards/ShareholderCapitalCard';
import { ShareTypeCapitalCard } from 'summaries/CapitalCards/ShareTypeCapitalCard';
import { DeedCard } from 'summaries/DeedCard/DeedCard';

const getIssuancesOfParty = (issuances: Issuance[], party: Party): Issuance[] =>
  issuances.filter((issuance) => issuance.to.id === party.id);

const getIssuancesOfShareType = (issuances: Issuance[], shareType: ShareType): Issuance[] =>
  issuances.filter((issuance) => issuance.shareSeries.shareType.id === shareType.id);

export const IssuanceSummaryCards = ({
  backgroundVariant = 'white',
  mutation,
  onDocumentSave,
}: SummaryCardsComponentProps) => {
  const { register } = mutation;
  const { legalEntity, shareTypes } = register;
  const labels = useLabels('issuance.summary', legalEntity.isFoundation);
  const issuances = useMemo(() => mutation.issuance || [], [mutation.issuance]);
  const parties = useMemo(
    () =>
      uniqBy(
        issuances.map((issuance) => issuance.to),
        'id',
      ),
    [issuances],
  );

  return (
    <>
      <Text color="grey80" spaceAfter={6} type="labelBold">
        <Trans>Uitgegeven kapitaal</Trans>
      </Text>

      <Box spaceAfter={10}>
        {sortShareTypes(shareTypes).map((shareType) => (
          <ShareTypeCapitalCard
            key={shareType.id}
            backgroundVariant={backgroundVariant}
            shareType={shareType}
          >
            {getIssuancesOfShareType(issuances, shareType).map((issuance, index) => (
              <CapitalCardListItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                mutationOptions={{ showPaidStatus: true }}
                paid={issuance.paid}
                shareSeries={issuance.shareSeries}
              />
            ))}
          </ShareTypeCapitalCard>
        ))}
      </Box>

      {!!parties.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            {labels.shareholders}
          </Text>

          <Box spaceAfter={10}>
            {parties.map((party) => (
              <ShareholderCapitalCard
                key={party.id}
                backgroundVariant={backgroundVariant}
                compareDeceaseDate={mutation.effectiveDate}
                party={party}
              >
                {getIssuancesOfParty(issuances, party).map((issuance) => (
                  <CapitalCardListItem
                    key={issuance.shareSeries.shareType.id}
                    mutationOptions={{ showPaidStatus: true }}
                    paid={issuance.paid}
                    shareSeries={issuance.shareSeries}
                  />
                ))}
              </ShareholderCapitalCard>
            ))}
          </Box>
        </>
      )}

      {mutation.document && (
        <Block spaceAfter={10}>
          <DeedCard
            backgroundVariant={backgroundVariant}
            document={mutation.document}
            mutation={mutation}
            onDocumentSave={onDocumentSave}
          />
        </Block>
      )}
    </>
  );
};
