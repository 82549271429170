import type { EndUsufruct, EndUsufructInput } from '@elseu/sdu-evidend-graphql';

import { mapDelegationDetailsToDelegationDetailsInput } from './DelegationDetails';
import { mapShareSeriesToShareSeriesInput } from './ShareSeries';

export const mapEndUsufructToEndUsufructInput = (
  endUsufruct: Partial<EndUsufruct>,
): EndUsufructInput => ({
  details: (endUsufruct.details || []).map(mapDelegationDetailsToDelegationDetailsInput),
  ownerPartyId: endUsufruct.owner?.id || '',
  shareSeries: mapShareSeriesToShareSeriesInput(endUsufruct.shareSeries as any),
});
