import { useQuery } from '@apollo/client';
import type { LegalEntity, PartyUpdate } from '@elseu/sdu-evidend-graphql';
import { Drawer, DrawerContainer, DrawerContent, DrawerLoader } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { KVK_UPDATES_QUERY } from 'graphql/queries/party';
import { partyUpdateFieldObject } from 'helpers/partyUpdate';
import { useMemo } from 'react';
import type { KvkUpdates, KvkUpdatesVariables } from 'types/graphql/KvkUpdates';

import { PartyUpdateSidebarForm } from './PartyUpdateSidebarForm';

interface PartyUpdateSidebarProps {
  legalEntity: LegalEntity;
  isSidebarShown: boolean;
  onSidebarClose: () => any;
  onUpdate?: () => any;
}
export const PartyUpdateSidebar = ({
  isSidebarShown,
  onSidebarClose,
  ...props
}: PartyUpdateSidebarProps) => {
  return (
    <Drawer isShown={isSidebarShown} position="right" width={700} onClose={onSidebarClose}>
      {isSidebarShown && <PartyUpdateSidebarContent onSidebarClose={onSidebarClose} {...props} />}
    </Drawer>
  );
};

const PartyUpdateSidebarContent = ({
  legalEntity,
  onSidebarClose,
  onUpdate,
}: Omit<PartyUpdateSidebarProps, 'isSidebarShown'>) => {
  const { data: { kvkUpdates } = {} } = useQuery<KvkUpdates, KvkUpdatesVariables>(
    KVK_UPDATES_QUERY,
    {
      variables: {
        partyId: legalEntity.id,
      },
    },
  );

  const filteredUpdates = useMemo(() => {
    return (
      kvkUpdates?.filter((partyUpdate) => partyUpdateFieldObject[partyUpdate.field].label()) || []
    );
  }, [kvkUpdates]);

  const isDocumentRequired = useMemo(() => {
    return !!kvkUpdates?.find((partyUpdate) => partyUpdate.requiresDocument);
  }, [kvkUpdates]);

  const header = t`Update gegevens`;
  const subtitle = t`De wijzigingen van de gegevens worden opgehaald bij de Kamer van Koophandel.`;
  if (!kvkUpdates)
    return (
      <DrawerContainer header={header} subtitle={subtitle}>
        <DrawerContent>
          <DrawerLoader />
        </DrawerContent>
      </DrawerContainer>
    );

  return (
    <PartyUpdateSidebarForm
      header={header}
      isDocumentRequired={isDocumentRequired}
      legalEntity={legalEntity}
      partyUpdates={filteredUpdates as PartyUpdate[]}
      subtitle={subtitle}
      onSidebarClose={onSidebarClose}
      onUpdate={onUpdate}
    />
  );
};
