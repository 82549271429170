import type { Incorporation, IncorporationInput } from '@elseu/sdu-evidend-graphql';

import { mapShareSeriesToShareSeriesInput } from './ShareSeries';

export const mapIncorporationToIncorporationInput = (
  incorporation: Incorporation,
): IncorporationInput => ({
  toPartyId: incorporation.to.id,
  shareSeries: mapShareSeriesToShareSeriesInput(incorporation.shareSeries),
  paid: incorporation.paid,
});
