import type { Document, RegisterMutation } from '@elseu/sdu-evidend-graphql';
import { DocumentType, MutationType, NotaryRole } from '@elseu/sdu-evidend-graphql';
import type { Spacings } from '@elseu/sdu-titan';
import { Block, Box, Card, Flex, Link, PencilIcon, Text } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import type { OnDocumentSaveFn } from 'components/DocumentSidebarForm/DocumentSidebarForm';
import { DocumentSidebarForm } from 'components/DocumentSidebarForm/DocumentSidebarForm';
import { DownloadFileLink } from 'components/DownloadFileLink/DownloadFileLink';
import { formatDocumentType } from 'helpers/document';
import { mutationLabelObject } from 'helpers/formatMutation';
import { formatNotaryName } from 'helpers/formatName';
import { capitalizeString, formatDate } from 'helpers/formatShares';
import React, { useState } from 'react';

const formatAttachedAt = (mutation: RegisterMutation) => {
  if (![MutationType.ATTACHMENT, MutationType.END_ATTACHMENT].includes(mutation.type)) return '';
  const mutationType = mutation.type as MutationType.ATTACHMENT | MutationType.END_ATTACHMENT;
  const mutationField = mutationLabelObject[mutationType].field;
  const attachedAt = mutation[mutationField]?.[0].details?.attachedAt;
  if (!attachedAt) return null;
  return (
    <span>
      &nbsp;
      <Trans>om {attachedAt}</Trans>
    </span>
  );
};

interface IDeedCard {
  document: Document;
  mutation: RegisterMutation;
  spaceAfter?: Spacings;
  backgroundVariant: 'white' | 'grey';
  onDocumentSave?: OnDocumentSaveFn;
}
export const DeedCard = ({
  document,
  spaceAfter,
  backgroundVariant,
  mutation,
  onDocumentSave,
}: IDeedCard) => {
  const [isSidebarShown, setSidebarShown] = useState<boolean>(false);
  const hasNotaryFields = document.type === DocumentType.NOTARIAL_DEED;
  const { register, type: mutationType } = mutation;
  return (
    <>
      {onDocumentSave && (
        <DocumentSidebarForm
          document={document}
          isSidebarShown={isSidebarShown}
          register={register}
          onDocumentSave={onDocumentSave}
          onSidebarClose={() => setSidebarShown(false)}
        />
      )}
      <Flex justifyContent="space-between" spaceAfter={6}>
        <Text color="grey80" type="labelBold">
          {capitalizeString(formatDocumentType(document.type, mutationType))}
        </Text>
        {onDocumentSave && (
          <Link prefixAdornment={<PencilIcon />} onClick={() => setSidebarShown(true)}>
            <Trans>Aanpassen</Trans>
          </Link>
        )}
      </Flex>
      <Card backgroundVariant={backgroundVariant} spaceAfter={spaceAfter}>
        <Block>
          {document.file?.name && (
            <Box spaceAfter={2}>
              <Flex justifyContent="space-between">
                <Text color="grey80" type="labelBold">
                  {document.file.name}
                </Text>

                <DownloadFileLink documentId={document.id}>
                  <Trans>Downloaden</Trans>
                </DownloadFileLink>
              </Flex>
            </Box>
          )}

          {hasNotaryFields && (
            <Text color="grey70" type="label">
              {NotaryRole.NOTARY === document.deedPassed?.notaryRole && (
                <Trans>
                  Gepasseerd door: {formatNotaryName(document.deedPassed.notary)}, notaris te{' '}
                  {document.deedPassed.notary?.location} op {formatDate(document.date)}
                </Trans>
              )}
              {NotaryRole.ASSIGNED_NOTARY === document.deedPassed?.notaryRole &&
                document.deedPassed.assignedNotary && (
                  <Trans>
                    Gepasseerd door toegevoegd notaris:{' '}
                    {formatNotaryName(document.deedPassed.assignedNotary)}, notaris te{' '}
                    {document.deedPassed.assignedNotary.location} op {formatDate(document.date)}{' '}
                    onder protocol van {formatNotaryName(document.deedPassed.notary)}, notaris te{' '}
                    {document.deedPassed.notary?.location}
                  </Trans>
                )}
              {NotaryRole.DEPUTY_NOTARY === document.deedPassed?.notaryRole && (
                <Trans>
                  Gepasseerd door een waarnemer van: {formatNotaryName(document.deedPassed.notary)},
                  notaris te {document.deedPassed.notary?.location} op {formatDate(document.date)}
                </Trans>
              )}
              {NotaryRole.UNKNOWN_NOTARY === document.deedPassed?.notaryRole && (
                <Trans>
                  Gepasseerd door: {formatNotaryName(document.deedPassed.notary)} op{' '}
                  {formatDate(document.date)}
                </Trans>
              )}
            </Text>
          )}

          {!hasNotaryFields && (
            <Text color="grey70" type="label">
              <Trans>Overeenkomst getekend op {formatDate(document.date)}</Trans>
              {formatAttachedAt(mutation)}
            </Text>
          )}
        </Block>
      </Card>
    </>
  );
};
DeedCard.defaultProps = {
  spaceAfter: 20,
  backgroundVariant: 'white',
};
