import { CancelIcon } from '@elseu/sdu-titan';
import React from 'react';
import styled from 'styled-components';

const RedCancelCircle = styled(CancelIcon)`
  path {
    fill: ${(props) => props.theme.designTokens.colors.danger50};
  }
`;

export const RedCancelCircleIcon = () => {
  return <RedCancelCircle />;
};
