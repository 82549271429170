import { t } from '@lingui/macro';
import { addressFormSchema } from 'forms/address/AddressBaseForm';
import { AddressForm } from 'forms/address/AddressForm';
import { countryCodeOptions } from 'forms/helpers/countryCodeOptions';
import * as yup from 'yup';

const getLegalEntityAddressFormSchema = () => {
  return yup.object({
    visitorAddress: addressFormSchema,
    correspondenceAddress: yup.object({
      streetName: yup.string().nullable().trim(),
      streetNumber: yup.string().nullable().trim(),
      streetNumberAddition: yup.string().nullable().trim(),
      city: yup.string().nullable().trim(),
      postalCode: yup.string().nullable().trim(),
      countryCode: yup.string().nullable().trim(),
    }),
  });
};

export const legalEntityAddressFormSchema = getLegalEntityAddressFormSchema();

export type LegalEntityAddressFormValues = yup.InferType<typeof legalEntityAddressFormSchema>;

export enum LegalEntityAddressProperties {
  visitorAddress = 'visitorAddress',
  correspondenceAddress = 'correspondenceAddress',
}

const defaultShowOptions = [
  LegalEntityAddressProperties.visitorAddress,
  LegalEntityAddressProperties.correspondenceAddress,
];

interface ILegalEntityAddressForm {
  nestedField?: string;
  show?: LegalEntityAddressProperties[];
  hideCountries?: string[];
  hideCountryField?: boolean;
  isManualEntry?: boolean;
  hasPartyUpdateFields?: boolean;
  cardBackgroundVariant?: 'grey' | 'white';
}
const LegalEntityAddressForm = ({
  nestedField = '',
  show = defaultShowOptions,
  hideCountries = [],
  hideCountryField = false,
  isManualEntry = false,
  cardBackgroundVariant = 'white',
}: ILegalEntityAddressForm) => {
  const legalEntityAddressPropertyLabels = {
    visitorAddress: t`Bezoekadres`,
    correspondenceAddress: t`Correspondentieadres`,
  };

  return (
    <>
      {show.map((key: LegalEntityAddressProperties) => (
        <AddressForm
          key={key}
          cardBackgroundVariant={cardBackgroundVariant}
          countryOptions={countryCodeOptions().filter(
            ({ value }) => !hideCountries.includes(value),
          )}
          hideCountryField={hideCountryField}
          isManualEntry={isManualEntry}
          label={legalEntityAddressPropertyLabels[key]}
          nestedField={`${nestedField}${key}.`}
          required={key === 'visitorAddress'}
        />
      ))}
    </>
  );
};

export { LegalEntityAddressForm, legalEntityAddressFormSchema as LegalEntityAddressFormSchema };
