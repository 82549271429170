import { useMutation } from '@apollo/client';
import type { Register } from '@elseu/sdu-evidend-graphql';
import { PartyType } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import { SidebarFormContainer, SidebarFormContent } from 'components/SidebarForm/SidebarForm';
import type { PartyCreateFormValues } from 'forms/mutation/PartyCreateForm';
import { PartyCreateForm, partyCreateFormSchema } from 'forms/mutation/PartyCreateForm';
import { ADD_LEGAL_ENTITY_TO_REGISTER } from 'graphql/queries/legalEntity';
import { useGlobalError } from 'hooks/useGlobalError';
import React from 'react';
import type {
  AddLegalEntityToRegister,
  AddLegalEntityToRegisterVariables,
} from 'types/graphql/AddLegalEntityToRegister';
import { useCreateLegalEntity, useCreateNaturalPerson } from 'wizardsteps/helpers/useCreateParty';

interface PartyCreateSidebarFormProps {
  register: Register;
  isSidebarShown: boolean;
  onSidebarClose: () => void;
  onPartyCreate: (partyId: string) => void;
}
export const PartyCreateSidebarForm = ({
  isSidebarShown,
  ...props
}: PartyCreateSidebarFormProps) => {
  return (
    <SidebarFormContainer isDrawerShown={isSidebarShown} width={720}>
      <PartyCreateSidebarFormContent {...props} />
    </SidebarFormContainer>
  );
};

const PartyCreateSidebarFormContent = ({
  register,
  onSidebarClose,
  onPartyCreate,
}: Omit<PartyCreateSidebarFormProps, 'isSidebarShown'>) => {
  const [, setGlobalError] = useGlobalError();
  const createLegalEntity = useCreateLegalEntity(register.id);
  const createNaturalPerson = useCreateNaturalPerson(register.id);
  const [addLegalEntityToRegister] = useMutation<
    AddLegalEntityToRegister,
    AddLegalEntityToRegisterVariables
  >(ADD_LEGAL_ENTITY_TO_REGISTER);

  const handlePartyCreate = async (partyCreateValues: PartyCreateFormValues) => {
    if (partyCreateValues.legalEntity?.id) {
      await addLegalEntityToRegister({
        variables: {
          legalEntityId: partyCreateValues.legalEntity.id,
          registerId: register.id,
        },
        onCompleted: ({ addLegalEntityToRegister }) => {
          onPartyCreate(addLegalEntityToRegister.id);
          onSidebarClose();
        },
      });
      return;
    }
    const createParty =
      partyCreateValues.partyType === PartyType.LEGAL_ENTITY
        ? createLegalEntity
        : createNaturalPerson;

    const response = await createParty(partyCreateValues);
    if (!response.id) return setGlobalError(response.message);
    onPartyCreate(response.id);
    onSidebarClose();
  };

  const hasAdministerRights = register.entitlement === 'ADMINISTER';
  return (
    <SidebarFormContent
      defaultValues={{}}
      form={<PartyCreateForm hasAdministerRights={hasAdministerRights} />}
      formSchema={partyCreateFormSchema}
      header={t`Partij aanmaken`}
      setDrawerClosed={onSidebarClose}
      subtitle={t`Kies een type om vervolgens de andere gegevens in te vullen.`}
      onSubmit={handlePartyCreate}
    />
  );
};

export default PartyCreateSidebarForm;
