import { useMutation } from '@apollo/client';
import type { Register, ShareType, ShareTypeInput } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import type { SidebarFormProps } from 'components/SidebarForm/SidebarForm';
import { SidebarFormContainer, SidebarFormContent } from 'components/SidebarForm/SidebarForm';
import { setFormErrors } from 'forms/helpers/setFormErrors';
import type { ShareTypeFormProps } from 'forms/sharetype/ShareTypeEditForm';
import {
  mapShareTypeEditFormValuesToShareTypeInput,
  mapShareTypeVersionToShareTypeEditFormValues,
  ShareTypeEditForm,
  ShareTypeEditFormSchema,
  shareTypeEditFormSchema,
  ShareTypeFormMode,
} from 'forms/sharetype/ShareTypeEditForm';
import {
  CORRECT_SHARE_TYPE_VERSION,
  CREATE_SHARE_TYPE,
  UPDATE_SHARE_TYPE,
} from 'graphql/queries/shareType';
import type { ShareTypeVersionWithShareType } from 'helpers/shareTypes';
import { useGlobalError } from 'hooks/useGlobalError';
import { useMemo } from 'react';
import type {
  CorrectShareTypeVersion,
  CorrectShareTypeVersionVariables,
} from 'types/graphql/CorrectShareTypeVersion';
import type { CreateShareType, CreateShareTypeVariables } from 'types/graphql/CreateShareType';
import type { UpdateShareType, UpdateShareTypeVariables } from 'types/graphql/UpdateShareType';

interface GetShareTypeFromResponseProps {
  shareTypeVersion?: ShareTypeVersionWithShareType;
  shareTypeInput: ShareTypeInput;
  registerId: string;
}
const useShareTypeSidebarFormMutation = (mode: ShareTypeFormMode) => {
  const [createShareType] = useMutation<CreateShareType, CreateShareTypeVariables>(
    CREATE_SHARE_TYPE,
  );
  const [correctShareTypeVersion] = useMutation<
    CorrectShareTypeVersion,
    CorrectShareTypeVersionVariables
  >(CORRECT_SHARE_TYPE_VERSION);
  const [updateShareType] = useMutation<UpdateShareType, UpdateShareTypeVariables>(
    UPDATE_SHARE_TYPE,
  );
  switch (mode) {
    case ShareTypeFormMode.Create:
      return {
        updateMutation: createShareType,
        getShareTypeFromResponse: (response: CreateShareType) => response.createShareType,
        getVariables: ({ shareTypeInput, registerId }: GetShareTypeFromResponseProps) => ({
          shareType: shareTypeInput,
          registerId,
        }),
      };
    case ShareTypeFormMode.Correct:
      return {
        updateMutation: correctShareTypeVersion,
        getShareTypeFromResponse: (response: CorrectShareTypeVersion) =>
          response.correctShareTypeVersion,
        getVariables: ({ shareTypeInput, shareTypeVersion }: GetShareTypeFromResponseProps) => ({
          shareType: shareTypeInput,
          shareTypeVersionId: shareTypeVersion?.id,
        }),
      };
    case ShareTypeFormMode.Update:
      return {
        updateMutation: updateShareType,
        getShareTypeFromResponse: (response: UpdateShareType) => response.updateShareType,
        getVariables: ({ shareTypeInput, shareTypeVersion }: GetShareTypeFromResponseProps) => ({
          shareType: shareTypeInput,
          shareTypeId: shareTypeVersion?.shareType.id,
        }),
      };
    default:
      throw new Error(`Invalid mode: ${mode}`);
  }
};

interface GetShareTypeVersionDefaultValuesProps {
  mutationId?: string;
  isFoundation: boolean;
}
const getShareTypeVersionDefaultValues = ({
  mutationId,
  isFoundation,
}: GetShareTypeVersionDefaultValuesProps) => {
  const defaultValues = {
    ...shareTypeEditFormSchema.getDefault(),
    createdInMutationId: mutationId,
  };
  if (isFoundation) defaultValues.name = t`Certificaten`;
  return defaultValues;
};

interface ShareTypeSidebarFormProps {
  register: Register;
  shareTypeVersion?: ShareTypeVersionWithShareType;
  isSidebarShown: boolean;
  onSidebarClose: () => any;
  onUpdate: (shareType: ShareType) => any;
  mutationId?: string;
  mutations?: ShareTypeFormProps['mutations'];
  mode: ShareTypeFormMode;
}
export const ShareTypeSidebarForm = ({ isSidebarShown, ...props }: ShareTypeSidebarFormProps) => {
  return (
    <SidebarFormContainer isDrawerShown={isSidebarShown} width={720}>
      {isSidebarShown && <ShareTypeSidebarFormContent {...props} />}
    </SidebarFormContainer>
  );
};

const ShareTypeSidebarFormContent = ({
  register,
  shareTypeVersion,
  onSidebarClose,
  onUpdate,
  mutationId,
  mutations,
  mode,
}: Omit<ShareTypeSidebarFormProps, 'isSidebarShown'>) => {
  const [, setGlobalError] = useGlobalError();
  const { isFoundation } = register.legalEntity;
  const { updateMutation, getShareTypeFromResponse, getVariables } =
    useShareTypeSidebarFormMutation(mode);

  const defaultValues = useMemo(
    () =>
      shareTypeVersion
        ? mapShareTypeVersionToShareTypeEditFormValues(shareTypeVersion, mutationId)
        : getShareTypeVersionDefaultValues({ mutationId, isFoundation }),
    [isFoundation, mutationId, shareTypeVersion],
  );

  const form: SidebarFormProps<typeof ShareTypeEditFormSchema> = {
    defaultValues,
    header:
      mode === ShareTypeFormMode.Create ? t`Aandelensoort aanmaken` : t`Aandelensoort wijzigen`,
    subtitle: t`Vul de eigenschappen in van de soort aandelen.`,
    form: (
      <ShareTypeEditForm
        canEditCreatedInMutation={mode === ShareTypeFormMode.Correct && !mutationId}
        isFoundation={isFoundation}
        mutations={mutations}
      />
    ),
    formSchema: ShareTypeEditFormSchema,
    onSubmit: async (formValues, form) => {
      const shareTypeInput = mapShareTypeEditFormValuesToShareTypeInput(formValues);
      const variables: any = getVariables({
        shareTypeInput,
        shareTypeVersion,
        registerId: register.id,
      });
      await updateMutation({
        variables,
        onCompleted: (response: any) => {
          const shareType = getShareTypeFromResponse(response);
          onUpdate(shareType as ShareType);
          onSidebarClose();
        },
        onError: ({ graphQLErrors }) => {
          setFormErrors({ errors: graphQLErrors, setGlobalError, form });
        },
      });
    },
    setDrawerClosed: onSidebarClose,
  };

  return <SidebarFormContent {...form} />;
};
