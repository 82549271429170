import { ButtonGroup, ContentWrapper, spacing, Text } from '@elseu/sdu-titan';
import { useFormLeaveConfirmRoute } from 'components/LeavePrompt';
import { PageLoader } from 'components/PageLoader/PageLoader';
import { throttle } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import { useEffectOnce, useEvent } from 'react-use';
import styled from 'styled-components';

interface IWizardFooter {
  isLoading?: boolean;
  label: string;
  children: React.ReactNode;
}

const StyledInstructions = styled.div`
  flex: 1 1 auto;
`;

const StyledText = styled(Text)`
  margin-left: auto;
  max-width: 280px;
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: right;
  align-items: center;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  flex: 0 1 auto;
  margin-left: ${spacing(6)};
`;

/**
 * FullscreenLayoutFooter in Titan uses react-unstuck, which only supports stick to top.
 * Titan works around this by calculating the window height and subtracting the footer height,
 * but this apparently doesn't work on iPads which report an incorrect window height.
 *
 * Instead, we use a custom implementation that uses CSS to stick the footer to the bottom.
 */
const FullscreenLayoutFooter = styled(ContentWrapper)`
  background: ${({ theme }) => theme.components.fullscreenLayout.backgroundColor};
  border-top: ${({ theme }) => theme.components.fullscreenLayout.footerBorder};
  padding: ${({ theme }) => theme.components.fullscreenLayout.footerPaddingVertical} 0;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  width: 100%;
`;

/**
 * We need a placeholder for the footer so the actual footer doesn't slide over the content
 * above it.
 */
interface IPlaceholder {
  height: number;
}
const Placeholder = styled.div<IPlaceholder>`
  height: ${(props) => props.height}px;
  /* Make sure the div is always rendered. */
  content: '200b';
`;

export const WizardFooter = ({ isLoading, label, children }: IWizardFooter) => {
  // We want to enable this globally for all wizards. Since the footer is
  // always within the form context, and the leave prompt functionality only
  // makes sense if there *is* a footer with a save button, this is a
  // logical place to add this.
  useFormLeaveConfirmRoute();
  const placeholderRef = useRef<HTMLElement>(null);
  const footerRef = useRef<HTMLElement>(null);

  const [width, setWidth] = useState(600);
  const [height, setHeight] = useState(0);

  const onResize = useCallback(() => {
    if (placeholderRef.current) {
      setWidth(placeholderRef.current.offsetWidth);
    }
    if (footerRef.current) {
      setHeight(footerRef.current.offsetHeight);
    }
  }, []);

  useEffectOnce(onResize);

  const throttledResize = throttle(onResize, 250);
  useEvent('resize', throttledResize);

  return (
    <>
      <Placeholder ref={placeholderRef as any} height={height} />

      {isLoading && <PageLoader hasBackground isAnimated />}

      <FullscreenLayoutFooter ref={footerRef} maxWidth={width}>
        <StyledFooter>
          <StyledInstructions>
            <StyledText color="grey70" type="labelSmall">
              {label}
            </StyledText>
          </StyledInstructions>
          <StyledButtonGroup spaceBetween={2}>{children}</StyledButtonGroup>
        </StyledFooter>
      </FullscreenLayoutFooter>
    </>
  );
};
