import type { RegisterMutation, ShareType } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import { SelectField } from 'components/FormFields/SelectField';
import { booleanOptions, booleanTransform } from 'forms/helpers/booleanOptions';
import { ShareSeriesInput } from 'forms/mutation/ShareSeriesInput';
import { ShareTypeSelect } from 'forms/mutation/ShareTypeSelect';

import { FieldRowRemoveButton } from './FieldRowRemoveButton';
import type { OnPartyCreateFn } from './PartyCreateForm';
import { PartySelect } from './PartySelect';

export interface ShareSeriesInputRowLabels {
  shareTypeColumnHeader?: string;
  shareSeriesColumnHeader?: string;
  shareSeriesType?: string;
  partyRefCreate?: string;
}

interface IShareSeriesInputRow {
  mutation: RegisterMutation;
  labels?: ShareSeriesInputRowLabels;
  nestedField?: string;
  isLabelHidden?: boolean;
  isRemovable?: boolean;
  hasPaid?: boolean;
  required?: boolean;
  hasShareTypeSelect?: boolean;
  isShareTypeCreateAllowed?: boolean;
  onShareTypeCreate?: (shareType: ShareType) => void;
  hasPartySelect?: boolean;
  isPartyCreateAllowed?: boolean;
  onPartyCreate?: OnPartyCreateFn;
  onRemove: () => void;
}

const ShareSeriesInputRow = ({
  mutation,
  labels,
  isLabelHidden,
  nestedField,
  isRemovable,
  hasPaid,
  required,
  hasShareTypeSelect = true,
  isShareTypeCreateAllowed,
  onShareTypeCreate,
  hasPartySelect = false,
  isPartyCreateAllowed,
  onPartyCreate,
  onRemove,
}: IShareSeriesInputRow) => {
  const { register } = mutation;
  const columnTemplate = `150px auto ${hasPaid ? '85px ' : ''}16px`;

  return (
    <Columns data-test-id="shareSeriesInputRow" gap={4} template={columnTemplate}>
      {hasShareTypeSelect && (
        <Column>
          <ShareTypeSelect
            createLabel={t`Soort toevoegen`}
            isCreateAllowed={isShareTypeCreateAllowed}
            isLabelHidden={isLabelHidden}
            label={labels?.shareTypeColumnHeader}
            mutationId={mutation.id}
            nestedField={`${nestedField}shareSeries.`}
            register={register}
            required={required}
            shareTypes={register.shareTypes}
            onShareTypeCreate={onShareTypeCreate}
          />
        </Column>
      )}
      {hasPartySelect && (
        <Column>
          <PartySelect
            required
            compareDeceaseDate={mutation.effectiveDate}
            createLabel={labels?.partyRefCreate}
            isCreateAllowed={isPartyCreateAllowed}
            isLabelHidden={isLabelHidden}
            label={t`Verkrijger`}
            nestedField={`${nestedField}owner.`}
            parties={register.shareholders}
            placeholder={t`Verkrijger`}
            register={register}
            onPartyCreate={onPartyCreate}
          />
        </Column>
      )}
      <Column>
        <ShareSeriesInput
          isLabelHidden={isLabelHidden}
          label={labels?.shareSeriesColumnHeader}
          nestedField={`${nestedField}shareSeries.`}
          required={required}
          typesLabel={labels?.shareSeriesType}
        />
      </Column>

      {hasPaid && (
        <Column>
          <SelectField
            isLabelHidden={isLabelHidden}
            label={t`Volgestort`}
            name={`${nestedField}paid`}
            options={booleanOptions()}
            placeholder={t`Kies`}
            testId="selectPaid"
            transform={booleanTransform}
          />
        </Column>
      )}

      <Column>
        <FieldRowRemoveButton
          isDisabled={!isRemovable}
          isLabelHidden={isLabelHidden}
          onRemove={onRemove}
        />
      </Column>
    </Columns>
  );
};

ShareSeriesInputRow.defaultProps = {
  isLabelHidden: false,
  nestedField: '',
  isRemovable: true,
  hasPaid: true,
};

export { ShareSeriesInputRow };
