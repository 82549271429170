import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useInterval } from 'react-use';

export const useFakeProgress = (enabled: boolean) => {
  const minProgress = 0.08;
  const trickleDelay = 800;

  const [progress, setProgress] = useState(0);

  // Reset progress when enabled or disabled.
  useEffect(() => {
    setProgress(0);
  }, [enabled]);

  // Provide instant feedback after starting.
  useLayoutEffect(() => {
    if (enabled) setProgress(minProgress);
  }, [enabled]);

  const trickle = useCallback(() => {
    let inc = 0;

    // Slow down trickle as we near the end.
    // Borrowed from nprogress.
    if (progress >= 0 && progress < 0.2) inc = 0.1;
    else if (progress >= 0.2 && progress < 0.5) inc = 0.04;
    else if (progress >= 0.5 && progress < 0.8) inc = 0.02;
    else if (progress >= 0.8 && progress < 0.99) inc = 0.005;

    setProgress(progress + inc);
  }, [progress, setProgress]);

  useInterval(trickle, enabled ? trickleDelay : null);

  return progress;
};
