import type { LegalEntity } from '@elseu/sdu-evidend-graphql';
import { PartyType } from '@elseu/sdu-evidend-graphql';
import { Divider, EmptyState, Flex, TileGroup } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import { KvkInputField } from 'components/FormFields/KvkInputField';
import { AddressForm } from 'forms/address/AddressForm';
import { ContactInfoForm, contactInfoFormSchema } from 'forms/ContactInfoForm';
import {
  LegalEntityAddressForm,
  legalEntityAddressFormSchema,
  LegalEntityAddressProperties,
} from 'forms/legal-entity/LegalEntityAddressForm';
import {
  LegalEntityForeignGeneralInfoForm,
  legalEntityForeignGeneralInfoFormSchema,
} from 'forms/legal-entity/LegalEntityForeignGeneralInfoForm';
import { legalEntityGeneralInfoFormSchema } from 'forms/legal-entity/LegalEntityGeneralInfoForm';
import {
  NaturalPersonPersonalInfoForm,
  naturalPersonPersonalInfoFormSchema,
} from 'forms/natural-person/NaturalPersonPersonalInfoForm';
import { LegalEntityPersonalInfo } from 'legalEntities/components/LegalEntityPersonalInfo';
import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import * as yup from 'yup';

const StyledEmptyState = styled(EmptyState)`
  padding-top: ${(props) => props.theme.spacing[20]};
`;

export const partyTypeOptions = () => [
  {
    label: t`Rechtspersoon (met KVK-nummer)`,
    value: 'legalEntity-kvk',
  },
  {
    label: t`Buitenlandse rechtspersoon`,
    value: 'legalEntity-foreign',
  },
  {
    label: t`Natuurlijk persoon`,
    value: 'naturalPerson',
  },
];

const getPartyCreateFormSchema = () => {
  return yup.object({
    partyType: yup.mixed<PartyType>().oneOf(Object.values(PartyType)).required(),
    legalEntity: yup.lazy((legalEntity) => {
      if (legalEntity?.countryOfRegistration === 'NL') {
        return yup
          .object()
          .concat(legalEntityGeneralInfoFormSchema)
          .notRequired()
          .default(undefined);
      }

      return yup
        .object()
        .concat(legalEntityAddressFormSchema.pick(['visitorAddress']))
        .concat(legalEntityForeignGeneralInfoFormSchema)
        .concat(contactInfoFormSchema)
        .notRequired()
        .default(undefined);
    }),
    naturalPerson: yup
      .object()
      .concat(naturalPersonPersonalInfoFormSchema)
      .concat(contactInfoFormSchema)
      .notRequired()
      .default(undefined),
  });
};

export const partyCreateFormSchema = getPartyCreateFormSchema();

export type PartyCreateFormValues = yup.InferType<typeof partyCreateFormSchema>;
export type OnAddPartyFn = (party: PartyCreateFormValues) => Promise<void>;
export type OnPartyCreateFn = () => void;
interface NaturalPersonFormProps {
  nestedField?: string;
  hasAdministerRights: boolean;
}
const NaturalPersonForm = ({ nestedField = '', hasAdministerRights }: NaturalPersonFormProps) => {
  return (
    <>
      <NaturalPersonPersonalInfoForm
        canViewPersonalData={hasAdministerRights}
        nestedField={nestedField}
      />
      <Columns count={2}>
        <Column>
          <AddressForm label={t`Woonadres`} nestedField={`${nestedField}postalAddress.`} />
        </Column>
        {hasAdministerRights && (
          <Column>
            <ContactInfoForm groupLabels hasColumns={false} nestedField={nestedField} />
          </Column>
        )}
      </Columns>
    </>
  );
};

interface ILegalEntityKvkForm {
  nestedField?: string;
  onLegalEntityFromKvk: (legalEntity: LegalEntity) => any;
}
const LegalEntityKvkForm = ({ nestedField = '', onLegalEntityFromKvk }: ILegalEntityKvkForm) => {
  const { control } = useFormContext();
  const legalEntity: LegalEntity = useWatch({
    control,
    // Remove trailing period.
    name: nestedField.replace(/\.$/, ''),
  });

  const onLookup = (legalEntity: LegalEntity) => {
    onLegalEntityFromKvk(legalEntity);
  };

  return (
    <>
      <Columns count={2} spaceAfter={6}>
        <Column>
          <KvkInputField
            defaultValue={legalEntity.tradeRegisterNumber as any}
            spaceAfter={4}
            onLookup={onLookup}
          />
        </Column>
      </Columns>
      <Divider spaceAfter={10} />
      <LegalEntityPersonalInfo
        hasDivider={false}
        hasHeader={false}
        hasHeaderButton={false}
        legalEntityId={legalEntity.id}
      />
    </>
  );
};

interface ILegalEntityForeignForm {
  nestedField?: string;
  hasAdministerRights: boolean;
}
const LegalEntityForeignForm = ({
  nestedField = '',
  hasAdministerRights,
}: ILegalEntityForeignForm) => {
  return (
    <>
      <LegalEntityForeignGeneralInfoForm nestedField={nestedField} />
      <Columns count={2}>
        <Column>
          <LegalEntityAddressForm
            isManualEntry={true}
            nestedField={nestedField}
            show={[LegalEntityAddressProperties.visitorAddress]}
          />
        </Column>
        {hasAdministerRights && (
          <Column>
            <ContactInfoForm groupLabels hasColumns={false} nestedField={nestedField} />
          </Column>
        )}
      </Columns>
    </>
  );
};

interface IPartyCreateForm {
  nestedField?: string;
  hasAdministerRights: boolean;
}
export const PartyCreateForm = ({ nestedField = '', hasAdministerRights }: IPartyCreateForm) => {
  const { control, setValue, reset } = useFormContext();
  const partyType: PartyType | null = useWatch({
    control,
    name: `${nestedField}partyType`,
  });
  const countryOfRegistration = useWatch({
    control,
    name: `${nestedField}legalEntity.countryOfRegistration`,
  });
  const isLegalEntityForeign = countryOfRegistration !== 'NL';

  const onLegalEntityFromKvk = useCallback(
    (legalEntity: LegalEntity) => {
      setValue(`legalEntity`, legalEntity, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    },
    [setValue],
  );

  const onPartyTypeChange = useCallback(
    (value: string | undefined) => {
      const partyType =
        value === 'naturalPerson' ? PartyType.NATURAL_PERSON : PartyType.LEGAL_ENTITY;
      const party: any = {
        partyType,
      };
      if (value === 'legalEntity') {
        party.legalEntity = { countryOfRegistration: 'NL' };
      }

      if (value === 'legalEntityForeign') {
        party.legalEntity = { visitorAddress: { countryCode: null } };
      }
      reset(party);
    },
    [reset],
  );

  const options = [
    {
      label: t`Natuurlijk persoon`,
      value: 'naturalPerson',
    },
    {
      label: t`Rechtspersoon`,
      value: 'legalEntity',
    },
    {
      label: t`Buitenlandse rechtspersoon`,
      value: 'legalEntityForeign',
    },
  ];
  return (
    <>
      <TileGroup
        label={t`Type`}
        name="partyType"
        options={options}
        spaceAfter={10}
        variant="horizontal-stretch"
        onChange={onPartyTypeChange}
      />
      <Divider spaceAfter={10} />
      {partyType === PartyType.NATURAL_PERSON && (
        <NaturalPersonForm
          hasAdministerRights={hasAdministerRights}
          nestedField={`${nestedField}naturalPerson.`}
        />
      )}
      {partyType === PartyType.LEGAL_ENTITY && (
        <>
          {isLegalEntityForeign && (
            <LegalEntityForeignForm
              hasAdministerRights={hasAdministerRights}
              nestedField={`${nestedField}legalEntity.`}
            />
          )}
          {!isLegalEntityForeign && (
            <LegalEntityKvkForm
              nestedField={`${nestedField}legalEntity.`}
              onLegalEntityFromKvk={onLegalEntityFromKvk}
            />
          )}
        </>
      )}
      {!partyType && (
        <Flex alignItems="center" justifyContent="center">
          <StyledEmptyState
            isCentered
            description={t`Om vervolgens de andere gegevens in te kunnen vullen.`}
            title={t`Kies een type`}
          />
        </Flex>
      )}
    </>
  );
};
