import type { $, Object as HktObject, String } from 'hkt-toolbelt';
import { workflowLabels } from 'labels/workflows';
import { get } from 'lodash';
import { useMemo } from 'react';

type WorkflowLabelObject = ReturnType<typeof workflowLabels>;
/**
 * Type safe label fetcher
 */
type WorkflowLabel<Path extends string> = $<
  HktObject.AtPath<$<String.Split<'.'>, Path>>,
  WorkflowLabelObject
>;

type GetLabels<
  Path extends string,
  IsFoundation extends boolean = false,
  Labels = WorkflowLabel<Path>,
> = Labels extends { default: object; foundation: object }
  ? IsFoundation extends true
    ? Labels['default'] & Labels['foundation']
    : Labels['default']
  : Labels extends Record<string, unknown>
  ? Labels
  : never;

export const getLabels = <Path extends string, IsFoundation extends boolean>(
  path: Path,
  isFoundation?: IsFoundation,
): GetLabels<Path, IsFoundation> => {
  type Return = GetLabels<Path, IsFoundation>;
  const labels = workflowLabels();
  // since we do not want to check the validity of the path string here, we use `as unknown as Record<string, string>`
  // the return type is already defined through generics
  // if we don't want this, the line `Path extends string` must include all valid strings before hand
  const values = get(labels, path) as unknown as Record<string, unknown> | undefined | string;
  if (!values) return undefined as GetLabels<Path, IsFoundation>;
  if (typeof values === 'string') return values as Return;
  if ('default' in values) {
    return isFoundation
      ? (Object.assign({}, values.default, values.foundation) as Return)
      : (values.default as Return);
  }
  return values as Return;
};

export const useLabels = <Path extends string, IsFoundation extends boolean>(
  path: Path,
  isFoundation?: IsFoundation,
) => {
  return useMemo(() => getLabels(path, isFoundation), [isFoundation, path]);
};
