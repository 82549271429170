import { Button } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { LoaderIcon } from 'components/LoaderIcon/LoaderIcon';
import { useWizardContext, WizardFooter, WizardHeader } from 'components/Wizard';
import { useExecuteMutationForm } from 'forms/mutation/useExecuteMutationForm';
import React from 'react';
import { FormProvider } from 'react-hook-form';

interface IWizardSummary {
  children?: React.ReactNode;
}

export const WizardSummary = ({ children }: IWizardSummary) => {
  const { previousStep } = useWizardContext();
  const [mutationForm, { handleMutationSubmit, labels }] = useExecuteMutationForm();
  const {
    formState: { isSubmitting },
  } = mutationForm;

  return (
    <FormProvider {...mutationForm}>
      <WizardHeader previousStep={previousStep} title={labels.header}>
        {labels.introduction}
      </WizardHeader>

      {children}

      <WizardFooter isLoading={isSubmitting} label={labels.footer}>
        <Button
          iconLeft={isSubmitting ? <LoaderIcon /> : undefined}
          isDisabled={isSubmitting}
          variant="primary"
          onClick={handleMutationSubmit}
        >
          <Trans>Opslaan</Trans>
        </Button>
      </WizardFooter>
    </FormProvider>
  );
};
