import { useMutation } from '@apollo/client';
import type { Document } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import type {
  OnDocumentSaveFn,
  OnDocumentSaveFnDocument,
} from 'components/DocumentSidebarForm/DocumentSidebarForm';
import type { DocumentUploadFormValues } from 'forms/document/DocumentUploadForm';
import { setFormErrors } from 'forms/helpers/setFormErrors';
import {
  CREATE_DOCUMENT,
  DELETE_FILE,
  UPDATE_DOCUMENT,
  UPLOAD_FILE,
} from 'graphql/queries/document';
import { pick } from 'lodash';
import { useCallback } from 'react';
import type { CreateDocument, CreateDocumentVariables } from 'types/graphql/CreateDocument';
import type { DeleteFile, DeleteFileVariables } from 'types/graphql/DeleteFile';
import type { UpdateDocument, UpdateDocumentVariables } from 'types/graphql/UpdateDocument';
import type { UploadFile, UploadFileVariables } from 'types/graphql/UploadFile';

import { useGlobalError } from './useGlobalError';

interface OnDocumentUpdateCompletedArgs {
  document: OnDocumentSaveFnDocument;
  file: DocumentUploadFormValues['file'];
  onDocumentSave?: OnDocumentSaveFn;
}
export const useUploadDocument = () => {
  const [, setGlobalError] = useGlobalError();
  const [uploadFile] = useMutation<UploadFile, UploadFileVariables>(UPLOAD_FILE);

  return useCallback(
    async ({ document, file, onDocumentSave }: OnDocumentUpdateCompletedArgs) => {
      if (file?.content) {
        await uploadFile({
          variables: { file: file.content, documentId: document.id },
          context: { includeTypename: true, useMultipart: true },
          onCompleted: () => {
            if (onDocumentSave) onDocumentSave(document);
          },
          onError: ({ graphQLErrors }) => {
            setFormErrors({
              errors: graphQLErrors,
              setGlobalError,
            });
          },
        });
      }
    },
    [setGlobalError, uploadFile],
  );
};

interface UpdateDocumentArgs {
  values: DocumentUploadFormValues;
  document?: Document;
  onDocumentSave?: OnDocumentSaveFn;
}
export const useUpdateDocument = (registerId: string) => {
  const [, setGlobalError] = useGlobalError();
  const [createDocument] = useMutation<CreateDocument, CreateDocumentVariables>(CREATE_DOCUMENT);
  const [updateDocument] = useMutation<UpdateDocument, UpdateDocumentVariables>(UPDATE_DOCUMENT);
  const uploadDocument = useUploadDocument();
  const [deleteFile] = useMutation<DeleteFile, DeleteFileVariables>(DELETE_FILE);
  const errorMessage = t`Het document kon niet worden opgeslagen. Probeer het alstublieft opnieuw.`;

  const onDocumentCompleted = useCallback(
    async ({ document, file, onDocumentSave }: OnDocumentUpdateCompletedArgs) => {
      if (file?.content) {
        uploadDocument({ document, file, onDocumentSave });
      } else {
        if (onDocumentSave) onDocumentSave(document);
      }
    },
    [uploadDocument],
  );

  return useCallback(
    async ({ values, document, onDocumentSave }: UpdateDocumentArgs) => {
      // Clear any previous error.
      setGlobalError(undefined);
      const documentInput = {
        ...pick(values, ['type', 'date', 'commentary', 'deedPassed']),
        labels: values.labels || [],
      };

      if (!document) {
        if (!registerId) return;
        await createDocument({
          variables: {
            registerId,
            document: documentInput,
          },
          onCompleted: ({ createDocument }) => {
            onDocumentCompleted({ document: createDocument, file: values.file, onDocumentSave });
          },
          onError: () => {
            setGlobalError(errorMessage);
          },
        }).catch(() => setGlobalError(errorMessage));
      } else {
        if (document.file && !values.file) {
          await deleteFile({ variables: { documentId: document.id } });
        }
        updateDocument({
          variables: { documentId: document.id, document: documentInput },
          onCompleted: ({ updateDocument }) =>
            onDocumentCompleted({ document: updateDocument, file: values.file, onDocumentSave }),
          onError: () => {
            setGlobalError(errorMessage);
          },
        }).catch(() => setGlobalError(errorMessage));
      }
    },
    [
      createDocument,
      deleteFile,
      errorMessage,
      onDocumentCompleted,
      registerId,
      setGlobalError,
      updateDocument,
    ],
  );
};
