import type {
  Conversion,
  ConversionInput,
  Maybe,
  RegisterMutation,
} from '@elseu/sdu-evidend-graphql';
import { ConversionAction } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import { mergeCards } from 'forms/helpers/mergeCards';
import { cloneDeep, set } from 'lodash';
import { shareSeriesInputRowPaidSchema, shareSeriesInputSchema } from 'mutations/seriesDataCards';
import {
  filterConversionsByAction,
  hasSingleShareType,
} from 'wizardsteps/Conversion/conversionFilters';
import * as yup from 'yup';

export type ConversionCard = yup.Asserts<ReturnType<typeof getConversionCardSchema>>;

const mergeConversionCards = mergeCards<ConversionCard>(
  (a: ConversionCard, b: ConversionCard) => a.shareType.id === b.shareType.id,
);

const conversionSeriesDataSchema = () =>
  yup
    .object({
      shareSeries: shareSeriesInputSchema,
      owner: yup.object({
        id: yup
          .string()
          .nullable()
          .required(t`Kies een partij als vervreemder`)
          .default(null),
      }),
    })
    .concat(shareSeriesInputRowPaidSchema);

export const getConversionCardSchema = () => {
  const seriesDataSchema = conversionSeriesDataSchema();
  return yup.object({
    shareType: yup.object({
      id: yup.string().nullable().required().default(null),
    }),
    action: yup
      .mixed<ConversionAction>()
      .oneOf(Object.values(ConversionAction))
      .required()
      .default(ConversionAction.CREATE),
    seriesData: yup
      .array()
      .min(1)
      .of(seriesDataSchema)
      .required()
      .default([seriesDataSchema.getDefault()]),
  });
};

export const mapCardToConversionInput = ({
  seriesData,
  action,
  shareType,
}: ConversionCard): ConversionInput[] =>
  seriesData.map(({ paid, shareSeries, owner }) => ({
    action,
    ownerPartyId: owner.id,
    paid,
    shareSeries: {
      from: shareSeries.from as unknown as bigint,
      shareTypeId: shareType.id,
      to: shareSeries.to as unknown as bigint,
    },
  }));

// Set values to be the same as the removed conversion
export const setConversionsToExisting = (conversions: Conversion[]): ConversionCard[] => {
  const filteredConversions = cloneDeep(
    filterConversionsByAction(conversions, ConversionAction.REMOVE),
  );
  return filteredConversions
    .map((conversion) => set(conversion, 'action', ConversionAction.CREATE))
    .reduce(
      (cards, conversion) => mergeConversionCards(cards, mapConversionToCard(conversion)),
      [] as ConversionCard[],
    )
    .map((card) => {
      card.seriesData.sort((a, b) => a.shareSeries.from - b.shareSeries.from);
      return card;
    });
};

// We only allow retain existing values if there is only one sharetype
export const getDefaultConversionCards = (mutation: RegisterMutation): ConversionCard[] => {
  if (!hasSingleShareType(mutation.conversion))
    return [getConversionCardSchema().getDefault() as ConversionCard];
  return setConversionsToExisting(mutation.conversion || []);
};

export const mapConversionToCard = (conversion: Conversion): ConversionCard => ({
  shareType: { id: conversion.shareSeries.shareType.id },
  action: conversion.action,
  seriesData: [
    {
      paid: conversion.paid as Maybe<boolean>,
      owner: {
        id: conversion.owner.id,
      },
      shareSeries: {
        from: conversion.shareSeries.from as number,
        to: conversion.shareSeries.to as number,
      },
    },
  ],
});

export const cardsToConversionInput = (
  cards: ConversionCard[],
  previousConversions: Conversion[] = [],
): ConversionInput[] => {
  // map the REMOVE mutation to card structure
  const removes = filterConversionsByAction(previousConversions, ConversionAction.REMOVE).map(
    (card) => mapConversionToCard(card),
  );
  // Add CREATE mutation after the REMOVE mutation
  return [...removes, ...cards].flatMap(mapCardToConversionInput);
};

export const mapMutationToConversionCards = (
  conversions: Conversion[],
  defaultCards: ConversionCard[] = [],
): ConversionCard[] => {
  const creates = filterConversionsByAction(conversions, ConversionAction.CREATE);
  if (!creates.length) return defaultCards;
  return creates.reduce(
    (cards, conversion) => mergeConversionCards(cards, mapConversionToCard(conversion)),
    [] as ConversionCard[],
  );
};
