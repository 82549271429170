import { gql } from '@apollo/client';

import {
  MUTATION_FRAGMENT,
  MUTATION_WITH_DOCUMENT_FRAGMENT,
  REGISTER_FRAGMENT,
  VALIDATION_ERRORS_FRAGMENT,
} from '../fragments';

export const MUTATION_QUERY = gql`
  query Mutation($mutationId: ID!) {
    mutation(mutationId: $mutationId) {
      ...MutationWithDocumentFields
      register {
        ...RegisterFields
      }
    }
  }
  ${MUTATION_WITH_DOCUMENT_FRAGMENT}
  ${REGISTER_FRAGMENT}
`;

export const MUTATIONS_QUERY = gql`
  query Mutations($filter: MutationFilterInput, $pagination: PaginationInput!) {
    mutations(filter: $filter, pagination: $pagination) {
      items {
        ...MutationFields

        register {
          legalEntity {
            id
            name
          }
        }
      }
      pagination {
        totalCount
      }
    }
  }
  ${MUTATION_FRAGMENT}
`;

export const CREATE_MUTATION = gql`
  mutation CreateMutation(
    $legalEntityId: ID!
    $mutationType: MutationType!
    $mutation: RegisterMutationInput
  ) {
    createMutation(
      legalEntityId: $legalEntityId
      mutationType: $mutationType
      mutation: $mutation
    ) {
      ...MutationWithDocumentFields
      register {
        ...RegisterFields
      }
      validationErrors {
        ...ValidationErrorsFields
      }
    }
  }
  ${MUTATION_WITH_DOCUMENT_FRAGMENT}
  ${REGISTER_FRAGMENT}
  ${VALIDATION_ERRORS_FRAGMENT}
`;

export const UPDATE_MUTATION = gql`
  mutation UpdateMutation($mutationId: ID!, $mutation: RegisterMutationInput!) {
    updateMutation(mutationId: $mutationId, mutation: $mutation) {
      ...MutationWithDocumentFields
      register {
        ...RegisterFields
      }
      validationErrors {
        ...ValidationErrorsFields
      }
    }
  }
  ${MUTATION_WITH_DOCUMENT_FRAGMENT}
  ${REGISTER_FRAGMENT}
  ${VALIDATION_ERRORS_FRAGMENT}
`;

export const DELETE_MUTATION = gql`
  mutation DeleteMutation($mutationId: ID!) {
    deleteMutation(mutationId: $mutationId) {
      ...MutationWithDocumentFields
    }
  }
  ${MUTATION_WITH_DOCUMENT_FRAGMENT}
`;
