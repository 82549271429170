import type { StepProps } from '@elseu/sdu-titan';
import React from 'react';

import { WizardProvider } from './WizardProvider';

export const withWizardContext = (
  Component: React.ElementType,
  determineSteps: (...args: any[]) => StepProps[],
) =>
  function _withWizardContext(props: any) {
    return (
      <WizardProvider determineSteps={determineSteps}>
        <Component {...props} />
      </WizardProvider>
    );
  };
