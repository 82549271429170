import type { Conversion } from '@elseu/sdu-evidend-graphql';
import { ConversionAction } from '@elseu/sdu-evidend-graphql';
import { uniqBy } from 'lodash';

export const filterConversionsByAction = (conversions: Conversion[], action: ConversionAction) =>
  conversions.filter((conversion) => conversion.action === action);

export const hasSingleShareType = (conversions: Conversion[] | null = []) =>
  !!conversions?.length &&
  uniqBy(filterConversionsByAction(conversions, ConversionAction.REMOVE), 'shareSeries.shareType')
    .length === 1;
