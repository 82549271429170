import { MutationType } from '@elseu/sdu-evidend-graphql';
import { Button, Flex, Link, PlusIcon, Text } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { useWizardContext, WizardFooter, WizardHeader } from 'components/Wizard';
import { useMutationCardForm } from 'forms/mutation/useMutationCardForm';
import { isMutationType } from 'mutations/isMutationType';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import {
  cardsToConversionInput,
  getConversionCardSchema,
  getDefaultConversionCards,
  mapMutationToConversionCards,
} from 'wizardsteps/Conversion/conversionCards';
import { ConversionShareCard } from 'wizardsteps/Conversion/ConversionShareCard';

export const ConversionShares = () => {
  const { previousStep, visitNextStep, mutation, refetchMutation } = useWizardContext();
  const { register } = mutation;
  const { shareTypes } = register;
  if (!isMutationType(mutation, MutationType.CONVERSION)) throw new Error('Invalid mutation type');

  const defaultCards = getDefaultConversionCards(mutation);
  const [
    mutationForm,
    { fields, addCard, removeCard, isLoading, handleMutationSubmit, labels, isSubmitDisabled },
  ] = useMutationCardForm({
    mutation,
    mutationType: mutation.type,
    onSuccess: visitNextStep,
    cardsToMutationSeries: cardsToConversionInput,
    mutationSeriesToCards: (conversions) =>
      mapMutationToConversionCards(conversions, getDefaultConversionCards(mutation)),
    defaultCards,
    cardSchema: getConversionCardSchema(),
  });

  return (
    <FormProvider {...mutationForm}>
      <WizardHeader previousStep={previousStep} title={labels.header}>
        {labels.introduction}
      </WizardHeader>

      <Flex justifyContent="space-between">
        <Text color="grey80" spaceAfter={6} type="labelBold">
          <Trans>Conversie(s)</Trans>
        </Text>
        <Link prefixAdornment={<PlusIcon />} onClick={addCard}>
          <Trans>Toevoegen</Trans>
        </Link>
      </Flex>

      {fields.map(({ key }, cardIndex: number) => (
        <ConversionShareCard
          key={key}
          labels={labels}
          mutation={mutation}
          nestedField={`cards[${cardIndex}].`}
          shareTypes={shareTypes}
          onDelete={() => removeCard(cardIndex)}
          onPartyCreate={refetchMutation}
          onShareTypeCreate={() => refetchMutation()}
        />
      ))}
      <WizardFooter isLoading={isLoading} label={labels.footer}>
        <Button isDisabled={isSubmitDisabled} variant="primary" onClick={handleMutationSubmit}>
          <Trans>Volgende</Trans>
        </Button>
      </WizardFooter>
    </FormProvider>
  );
};
