import { spacing } from '@elseu/sdu-titan';
import { ProgressBar } from 'components/ProgressBar/ProgressBar';
import React from 'react';
import styled, { css } from 'styled-components';

interface ICardProgressBar {
  noPadding?: boolean;
  progress: number;
}

const Wrapper = styled.div<Pick<ICardProgressBar, 'noPadding'>>`
  margin-top: ${spacing(4)};
  ${({ noPadding }) =>
    !noPadding &&
    css`
      margin-bottom: -${spacing(4)};
      margin-right: -${spacing(6)};
      margin-left: -${spacing(6)};
    `};
  border-bottom-left-radius: ${({ theme }) => theme.components.card.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.components.card.borderRadius};
  overflow: hidden;
`;

export const CardProgressBar = ({ progress, noPadding }: ICardProgressBar) => {
  return (
    <Wrapper noPadding={noPadding}>
      <ProgressBar value={progress} />
    </Wrapper>
  );
};
