import type { Maybe, Party, RegisterMutation } from '@elseu/sdu-evidend-graphql';
import { DocumentLabel, DocumentType, MutationType } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import type { OnDocumentSaveFn } from 'components/DocumentSidebarForm/DocumentSidebarForm';
import { uniqBy } from 'lodash';
import type { MutationInputFields } from 'mutations/mutationFields';
import { AdministrationSummaryCards } from 'summaries/AdministrationSummaryCards/AdministrationSummaryCards';
import { ArticlesOfAssociationSummaryCards } from 'summaries/ArticlesOfAssociationSummaryCards/ArticlesOfAssociationSummaryCards';
import { AttachmentSummaryCards } from 'summaries/AttachmentSummaryCards/AttachmentSummaryCards';
import { ConversionSummaryCards } from 'summaries/ConversionSummaryCards/ConversionSummaryCards';
import { EndAdministrationSummaryCards } from 'summaries/EndAdministrationSummaryCards/EndAdministrationSummaryCards';
import { EndAttachmentSummaryCards } from 'summaries/EndAttachmentSummaryCards/EndAttachmentSummaryCards';
import { EndPledgeSummaryCards } from 'summaries/EndPledgeSummaryCards/EndPledgeSummaryCards';
import { EndUsufructSummaryCards } from 'summaries/EndUsufructSummaryCards/EndUsufructSummaryCards';
import { IncorporationSummaryCards } from 'summaries/IncorporationSummaryCards/IncorporationSummaryCards';
import { IssuanceSummaryCards } from 'summaries/IssuanceSummaryCards/IssuanceSummaryCards';
import { LiquidationSummaryCards } from 'summaries/LiquidationSummaryCards/LiquidationSummaryCards';
import { PayingUpSummaryCards } from 'summaries/PayingUpSummaryCards/PayingUpSummaryCards';
import { PledgeSummaryCards } from 'summaries/PledgeSummaryCards/PledgeSummaryCards';
import { TransferSummaryCards } from 'summaries/TransferSummaryCards/TransferSummaryCards';
import { UsufructSummaryCards } from 'summaries/UsufructSummaryCards/UsufructSummaryCards';
import { WithdrawalSummaryCards } from 'summaries/WithdrawalSummaryCards/WithdrawalSummaryCards';

export interface SummaryCardsComponentProps {
  mutation: RegisterMutation;
  backgroundVariant?: 'white' | 'grey';
  onDocumentSave?: OnDocumentSaveFn;
}
type MutationLabelObject = {
  [type in MutationType]: {
    value: type;
    field: MutationInputFields[type];
    link: string;
    label: () => string;
    documentLabel?: () => string;
    deedLabel?: () => string;
    hasDocumentChoiceStep: (isFoundation: boolean) => boolean;
    isNoDocumentAllowed?: boolean;
    shouldAcknowledge: boolean;
    canChangeAoA?: boolean;
    defaultDocumentType?: DocumentType;
    defaultLabels: (isFoundation: boolean) => DocumentLabel[];
    SummaryCardsComponent: React.FC<SummaryCardsComponentProps>;
  };
};
export const mutationLabelObject: MutationLabelObject = {
  ADMINISTRATION: {
    value: MutationType.ADMINISTRATION,
    field: 'administration',
    link: 'bewind',
    label: () => t`Bewind`,
    deedLabel: () => t`akte van bewind`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || false,
    shouldAcknowledge: true,
    SummaryCardsComponent: AdministrationSummaryCards,
    defaultLabels: () => [],
  },
  ARTICLE_OF_ASSOCIATION: {
    value: MutationType.ARTICLE_OF_ASSOCIATION,
    field: 'onboarding',
    link: 'wijziging-statuten',
    label: () => t`Wijziging statuten`,
    deedLabel: () => t`statutenwijziging`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || false,
    shouldAcknowledge: false,
    SummaryCardsComponent: ArticlesOfAssociationSummaryCards,
    defaultDocumentType: DocumentType.NOTARIAL_DEED,
    defaultLabels: () => [DocumentLabel.ARTICLE_OF_ASSOCIATION],
  },
  ATTACHMENT: {
    value: MutationType.ATTACHMENT,
    field: 'attachment',
    link: 'beslag',
    label: () => t`Beslag`,
    documentLabel: () => t`beslagexploot`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || false,
    shouldAcknowledge: true,
    defaultDocumentType: DocumentType.PRIVATE_DEED,
    SummaryCardsComponent: AttachmentSummaryCards,
    defaultLabels: () => [],
  },
  CONVERSION: {
    value: MutationType.CONVERSION,
    field: 'conversion',
    link: 'conversie',
    label: () => t`Conversie`,
    deedLabel: () => t`statutenwijziging`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || true,
    shouldAcknowledge: false,
    canChangeAoA: true,
    SummaryCardsComponent: ConversionSummaryCards,
    defaultLabels: () => [],
  },
  END_ADMINISTRATION: {
    value: MutationType.END_ADMINISTRATION,
    field: 'endAdministration',
    link: 'beeindiging-bewind',
    label: () => t`Beëindiging bewind`,
    deedLabel: () => t`akte van beëindiging bewind`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || false,
    shouldAcknowledge: true,
    SummaryCardsComponent: EndAdministrationSummaryCards,
    defaultLabels: () => [],
  },
  END_ATTACHMENT: {
    value: MutationType.END_ATTACHMENT,
    field: 'endAttachment',
    link: 'beslag-opheffen',
    label: () => t`Beslag opheffen`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || false,
    shouldAcknowledge: true,
    defaultDocumentType: DocumentType.OTHER,
    SummaryCardsComponent: EndAttachmentSummaryCards,
    defaultLabels: () => [],
  },
  END_PLEDGE: {
    value: MutationType.END_PLEDGE,
    field: 'endPledge',
    link: 'beeindiging-verpanding',
    label: () => t`Beëindiging verpanding`,
    deedLabel: () => t`akte van beëindiging verpanding`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || true,
    shouldAcknowledge: true,
    SummaryCardsComponent: EndPledgeSummaryCards,
    defaultLabels: () => [],
  },
  END_USUFRUCT: {
    value: MutationType.END_USUFRUCT,
    field: 'endUsufruct',
    link: 'beeindiging-vruchtgebruik',
    label: () => t`Beëindiging vruchtgebruik`,
    deedLabel: () => t`akte van beëindiging vruchtgebruik`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || true,
    shouldAcknowledge: true,
    SummaryCardsComponent: EndUsufructSummaryCards,
    defaultLabels: () => [],
  },
  INCORPORATION: {
    value: MutationType.INCORPORATION,
    field: 'incorporation',
    link: 'initiele-uitgifte',
    label: () => t`Initiële uitgifte`,
    deedLabel: () => t`akte van oprichting`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || false,
    shouldAcknowledge: false,
    canChangeAoA: true,
    SummaryCardsComponent: IncorporationSummaryCards,
    defaultLabels: (isFoundation) =>
      isFoundation
        ? [DocumentLabel.ARTICLE_OF_ASSOCIATION, DocumentLabel.TRUST_CONDITIONS]
        : [DocumentLabel.ARTICLE_OF_ASSOCIATION],
  },
  ISSUANCE: {
    value: MutationType.ISSUANCE,
    field: 'issuance',
    link: 'uitgifte',
    label: () => t`Uitgifte`,
    deedLabel: () => t`akte van uitgifte`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || false,
    shouldAcknowledge: false,
    canChangeAoA: true,
    SummaryCardsComponent: IssuanceSummaryCards,
    defaultLabels: () => [],
  },
  LIQUIDATION: {
    value: MutationType.LIQUIDATION,
    field: 'liquidation',
    link: 'beeindiging-register',
    label: () => t`Beëindiging register`,
    deedLabel: () => t`akte van ontbinding`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || true,
    shouldAcknowledge: false,
    SummaryCardsComponent: LiquidationSummaryCards,
    defaultLabels: () => [],
  },
  ONBOARDING: {
    value: MutationType.ONBOARDING,
    field: 'onboarding',
    link: 'bestaand-register',
    label: () => t`Registratie van bestaand register`,
    documentLabel: () => t`digitale kopie van het bestaande register`,
    hasDocumentChoiceStep: () => false,
    shouldAcknowledge: false,
    defaultDocumentType: DocumentType.OTHER,
    SummaryCardsComponent: IncorporationSummaryCards,
    defaultLabels: () => [],
  },
  PAYING_UP: {
    value: MutationType.PAYING_UP,
    field: 'payingUp',
    link: 'volstorting',
    label: () => t`Volstorting`,
    deedLabel: () => t`akte van volstorting`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || true,
    isNoDocumentAllowed: true,
    shouldAcknowledge: true,
    SummaryCardsComponent: PayingUpSummaryCards,
    defaultLabels: () => [],
  },
  PLEDGE: {
    value: MutationType.PLEDGE,
    field: 'pledge',
    link: 'verpanding',
    label: () => t`Verpanding`,
    deedLabel: () => t`akte van verpanding`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || true,
    shouldAcknowledge: true,
    SummaryCardsComponent: PledgeSummaryCards,
    defaultLabels: () => [],
  },
  TRANSFER: {
    value: MutationType.TRANSFER,
    field: 'transfer',
    link: 'overdracht',
    label: () => t`Overdracht`,
    deedLabel: () => t`akte van overdracht`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || false,
    shouldAcknowledge: true,
    SummaryCardsComponent: TransferSummaryCards,
    defaultLabels: () => [],
  },
  TRUST_CONDITIONS: {
    value: MutationType.TRUST_CONDITIONS,
    field: 'onboarding',
    link: 'wijziging-administratievoorwaarden',
    label: () => t`Wijziging administratievoorwaarden`,
    deedLabel: () => t`Wijziging administratievoorwaarden`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || true,
    shouldAcknowledge: false,
    SummaryCardsComponent: ArticlesOfAssociationSummaryCards,
    defaultDocumentType: DocumentType.NOTARIAL_DEED,
    defaultLabels: () => [DocumentLabel.TRUST_CONDITIONS],
  },
  USUFRUCT: {
    value: MutationType.USUFRUCT,
    field: 'usufruct',
    link: 'vruchtgebruik',
    label: () => t`Vruchtgebruik`,
    deedLabel: () => t`akte van vruchtgebruik`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || true,
    shouldAcknowledge: true,
    SummaryCardsComponent: UsufructSummaryCards,
    defaultLabels: () => [],
  },
  WITHDRAWAL: {
    value: MutationType.WITHDRAWAL,
    field: 'withdrawal',
    link: 'intrekking',
    label: () => t`Intrekking`,
    deedLabel: () => t`akte van intrekking`,
    hasDocumentChoiceStep: (isFoundation) => isFoundation || true,
    shouldAcknowledge: true,
    canChangeAoA: true,
    SummaryCardsComponent: WithdrawalSummaryCards,
    defaultLabels: () => [],
  },
};

export const formatMutationType = ({ type, title }: Pick<RegisterMutation, 'type' | 'title'>) => {
  const mutationLabel = mutationLabelObject[type];
  return mutationLabel.label() + (title ? `: ${title}` : '');
};

export const formatMutationTypeLink = (type: MutationType) => {
  const mutationLabel = mutationLabelObject[type];
  return mutationLabel.link;
};

export const getUniquePartiesFromMutation = (mutation: RegisterMutation): Party[] => {
  const mutationLabel = mutationLabelObject[mutation.type];
  if (mutationLabel.field === 'liquidation') return [];

  const field = mutationLabel.field;
  const mutationObjects: Maybe<any[]> | undefined = mutation[field];
  if (!mutationObjects) return [];
  return uniqBy(
    mutationObjects.reduce((parties: Party[], mutationObject) => {
      const { to, from, owner } = mutationObject;
      const delegatee = mutationObject.details?.delegatee;
      const administrator = mutationObject.details?.administrator;
      const bailiff = mutationObject.details?.bailiff;
      const requiredBy = mutationObject.details?.requiredBy;
      const allParties = [
        ...parties,
        ...[to, from, owner, delegatee, administrator, bailiff].filter((x) => x),
      ];
      if (requiredBy) return [...allParties, ...requiredBy];
      return allParties;
    }, []) as Party[],
    'id',
  );
};
