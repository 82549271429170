import type { ValidationError } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import type { SetFormErrorsProps } from 'forms/helpers/setFormErrors';
import { setFormErrors } from 'forms/helpers/setFormErrors';
import { useGlobalError } from 'hooks/useGlobalError';
import type { SeriesDataCard } from 'mutations/seriesDataCards';
import { useCallback } from 'react';

export type UseSetFormValidationErrorsProps = {
  validationErrorMapper?: <T extends { cards: SeriesDataCard[] }>(
    errors: ValidationError[],
    formValues: T,
  ) => ValidationError[];
  onError?: (errors: ValidationError[]) => void;
  addParams?: Array<{
    __typename?: string;
    name: string;
    value: string;
  }>;
} & Omit<SetFormErrorsProps, 'errors' | 'setGlobalError'>;

export const useSetFormValidationErrors = (props: UseSetFormValidationErrorsProps) => {
  const [, setGlobalError] = useGlobalError();
  return useCallback(
    (_errors: ValidationError[] | undefined) => {
      const { form, replaceValidationPart, validationErrorMapper, onError } = props;
      if (!_errors || !Array.isArray(_errors)) {
        return;
      }
      const formValues = form?.getValues() || {};
      const errors = validationErrorMapper ? validationErrorMapper(_errors, formValues) : _errors;
      if (errors.length === 0) {
        console.warn('no errors to report, but setFormValidationErrors was called');
        return;
      }
      setFormErrors({
        errors:
          errors.length > 0
            ? errors
            : [
                {
                  field: 'mutation',
                  message: t`Er ging iets mis bij het updaten van de mutatie.`,
                  code: 'UNKNOWN_ERROR',
                  params: [],
                },
              ],
        form,
        setGlobalError,
        replaceValidationPart,
        addParams: props.addParams || [],
      });
      if (onError) onError(errors);
    },
    [setGlobalError, props],
  );
};
