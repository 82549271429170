import type { Maybe, ShareSeries } from '@elseu/sdu-evidend-graphql';
import { ListItem } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { formatNumber, formatShareSeries, formatShareTypeName } from 'helpers/formatShares';
import { formatMoney, multiplyMoney } from 'helpers/money';
import { calculateSeriesTotal } from 'helpers/shareBatches';

interface MutationCardTextOptions {
  showNominalValue?: boolean;
  showPaidStatus?: boolean;
  showShareRange?: boolean;
  showTotalValue?: boolean;
}
export const MutationCardText = (
  shareSeries: ShareSeries,
  paid?: Maybe<boolean>,
  {
    showNominalValue = true,
    showPaidStatus = false,
    showShareRange = true,
    showTotalValue = false,
  }: MutationCardTextOptions = {},
) => {
  const { from, to, shareType } = shareSeries;
  const total = calculateSeriesTotal(from, to);
  let shareTypeText = `${formatNumber(total)} ${formatShareTypeName(shareType, showNominalValue)}`;
  if (showShareRange) shareTypeText += ` ${t`genummerd ${formatShareSeries(shareSeries)}`}`;
  if (showTotalValue)
    shareTypeText += ` ${t`met een totaalbedrag van ${formatMoney(
      multiplyMoney(shareType.nominalValue, total),
    )}`}`;
  const isPaidBoolean = typeof paid === 'boolean';
  if (showPaidStatus && isPaidBoolean)
    shareTypeText += ` (${paid ? t`volgestort` : t`niet-volgestort`})`;
  return shareTypeText;
};

interface ICapitalCardListItem {
  mutationOptions?: MutationCardTextOptions;
  shareSeries: ShareSeries;
  paid?: Maybe<boolean>;
}
export const CapitalCardListItem = ({
  mutationOptions,
  shareSeries,
  paid,
}: ICapitalCardListItem) => {
  return (
    <ListItem prefixAdornment="•">{MutationCardText(shareSeries, paid, mutationOptions)}</ListItem>
  );
};
