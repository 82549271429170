import type { FetchResult } from '@apollo/client';
import type { ApolloError, GraphQLErrors } from '@apollo/client/errors';
import type { ValidationError } from '@elseu/sdu-evidend-graphql';
import type { ValidationErrorCode } from 'forms/helpers/setFormErrors';
import type { UseSetFormValidationErrorsProps } from 'forms/mutation/useSetFormValidationErrors';
import { useSetFormValidationErrors } from 'forms/mutation/useSetFormValidationErrors';
import { useCallback } from 'react';

const stripAllowedErrors = (errors: unknown[], allowedErrors: string[]) =>
  errors.filter(
    (error): error is ValidationError =>
      !(
        typeof error === 'object' &&
        error !== null &&
        'code' in error &&
        allowedErrors.includes(error.code as never)
      ),
  );

type UseHandleMutationResponseProps<OnSuccess extends (data: never) => unknown> = {
  onSuccess?: OnSuccess;
  graphQlErrorMapper?: (errors: GraphQLErrors) => ValidationError[];
  onApolloError?: (error: ApolloError) => void;
  allowedErrors?: ValidationErrorCode[];
} & UseSetFormValidationErrorsProps;

export const useHandleMutationResponse = <OnSuccess extends (data: never) => unknown>({
  onSuccess,
  allowedErrors = [],
  onApolloError,
  graphQlErrorMapper = (graphQLErrors) =>
    graphQLErrors.map(({ extensions: { validationError } }) => validationError as ValidationError),
  ...props
}: UseHandleMutationResponseProps<OnSuccess>) => {
  const setFormValidationErrors = useSetFormValidationErrors(props);
  const handleResponse = useCallback(
    ({ data, errors }: FetchResult) => {
      if (errors) {
        setFormValidationErrors(graphQlErrorMapper(errors as ApolloError['graphQLErrors']));
        return;
      }
      // we need to check if there are any validation errors in the response, but we don't (have to) know the key since they are in the error object
      const errorInResponse = Object.values(data!).find(
        (v) => typeof v === 'object' && v !== null && 'validationErrors' in v,
      ) as { validationErrors: ValidationError[] } | undefined;
      if (errorInResponse?.validationErrors.length) {
        const forbiddenErrors = allowedErrors.length
          ? stripAllowedErrors(errorInResponse.validationErrors, allowedErrors)
          : errorInResponse.validationErrors;
        if (forbiddenErrors.length) {
          setFormValidationErrors(forbiddenErrors);
          return;
        }
      }
      if (!data) {
        setFormValidationErrors([]);
        return;
      }
      if (onSuccess) onSuccess(data as never);
      return data;
    },
    [allowedErrors, graphQlErrorMapper, onSuccess, setFormValidationErrors],
  );
  const handleError = useCallback(
    (error: Error) => {
      console.error(error);
      if ('graphQLErrors' in error) {
        setFormValidationErrors(
          graphQlErrorMapper(error.graphQLErrors as ApolloError['graphQLErrors']),
        );
      } else {
        setFormValidationErrors([]);
      }
      if (error.name === 'ApolloError' && onApolloError) onApolloError(error as ApolloError);
    },
    [graphQlErrorMapper, onApolloError, setFormValidationErrors],
  );

  return [handleResponse, handleError] as const;
};
