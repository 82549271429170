import { useLazyQuery } from '@apollo/client';
import type { LegalEntity } from '@elseu/sdu-evidend-graphql';
import type { Spacings } from '@elseu/sdu-titan';
import { Input } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { formErrorMessage } from 'forms/helpers/setFormErrors';
import { KVK_LOOKUP_QUERY } from 'graphql/queries/legalEntity';
import React, { useCallback, useEffect, useState } from 'react';
import type { NumberFormatValues } from 'react-number-format';
import NumberFormat from 'react-number-format';
import { useUpdateEffect } from 'react-use';
import type { KvkLookup, KvkLookupVariables } from 'types/graphql/KvkLookup';
import * as yup from 'yup';

const kvkSchema = yup.string().min(8).max(8).required();
export type KvkSchema = typeof kvkSchema;
interface KvkInputFieldProps {
  defaultValue?: string;
  onLookup: (legalEntity: LegalEntity) => void;
  onChange?: (value: string) => void;
  spaceAfter?: Spacings;
}

const KvkInputField = ({
  defaultValue = '',
  onLookup,
  spaceAfter = 10,
  onChange,
}: KvkInputFieldProps) => {
  const [kvkLookup] = useLazyQuery<KvkLookup, KvkLookupVariables>(KVK_LOOKUP_QUERY, {});

  const [value, setValue] = useState<string>(defaultValue);
  const [validatedValue, setValidatedValue] = useState<string>(defaultValue);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const validate = async () => {
      setError(undefined);
      setValidatedValue('');
      if (!value) return;

      try {
        await kvkSchema.validate(value);
        setValidatedValue(value.trim());
        setIsLoading(true);
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          setError(err.message);
        }
      }
    };
    validate();
  }, [value]);

  const search = useCallback(async () => {
    setError(undefined);
    setIsLoading(true);

    await kvkLookup({
      variables: { kvkNumber: validatedValue },
      onCompleted: ({ kvkLookup }) => {
        setIsLoading(false);
        onLookup(kvkLookup as LegalEntity);
      },
      onError: (error) => {
        setIsLoading(false);
        if (error.graphQLErrors.length) {
          const firstError = error.graphQLErrors[0];
          setError(formErrorMessage(firstError.extensions.validationError));
        }
      },
    });
  }, [kvkLookup, validatedValue, onLookup]);

  useUpdateEffect(() => {
    if (validatedValue) {
      search();
    }
  }, [validatedValue]);

  const onValueChange = useCallback(
    ({ value }: NumberFormatValues) => {
      setValue(value);
      if (onChange) onChange(value);
    },
    [setValue, onChange],
  );

  const CustomInput = useCallback((props: any) => <Input type="number" {...props} />, []);

  return (
    <NumberFormat
      allowLeadingZeros={true}
      allowNegative={false}
      autoComplete="off"
      customInput={CustomInput}
      decimalScale={0}
      errorText={error}
      hasError={!!error}
      isLoading={isLoading}
      isSuccess={!!value && !!validatedValue && !error && !isLoading}
      label={t`KVK-nummer`}
      name="_kvksearch"
      placeholder={t`KVK-nummer`}
      spaceAfter={spaceAfter}
      spellCheck="false"
      value={value}
      onValueChange={onValueChange}
    />
  );
};

export { KvkInputField };
