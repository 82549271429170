import type { LegalEntity } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';

export const getLegalFormObject = (): Record<any, string> => ({
  beslotenvennootschap: t`Besloten Vennootschap`,
  commanditairevennootschap: t`Commanditaire Vennootschap`,
  coöperatie: t`Coöperatie`,
  eenmanszaak: t`Eenmanszaak`,
  eenmanszaakmetmeerdereeigenaren: t`Eenmanszaak`,
  europeeseconomischsamenwerkingsverband: t`Europees economisch samenwerkingsverband`,
  'europesecoöperatievevennootschap(sce)': t`Europese coöperatieve vennootschap (SCE)`,
  'europesenaamlozevennootschap(se)': t`Europese naamloze vennootschap (SE)`,
  kapitaalvennootschapbinneneer: t`Kapitaalvennootschap binnen EER`,
  kapitaalvennootschapbuiteneer: t`Kapitaalvennootschap buiten EER`,
  kerkgenootschap: t`Kerkgenootschap`,
  maatschap: t`Maatschap`,
  naamlozevennootschap: t`Naamloze Vennootschap`,
  onderlingewaarborgmaatschappij: t`Onderlinge Waarborg Maatschappij`,
  overigebuitenlandserechtspersoonofvennootschap: t`Overige buitenlandse rechtspersoon of vennootschap`,
  overigeprivaatrechtelijkerechtspersoon: t`Overige Privaatrechtelijke Rechtspersoon`,
  publiekrechtelijkerechtspersoon: t`Publiekrechtelijke Rechtspersoon`,
  rechtspersooninoprichting: t`Rechtspersoon in oprichting`,
  rederij: t`Rederij`,
  stichting: t`Stichting`,
  vennootschaponderfirma: t`Vennootschap onder firma`,
  vereniging: t`Vereniging`,
  verenigingvaneigenaars: t`Vereniging van Eigenaars`,
});
export const formatLegalForm = ({ legalForm }: Pick<LegalEntity, 'legalForm'>) => {
  if (!legalForm) return legalForm;
  const legalFormObject = getLegalFormObject();
  const legalFormValue = legalForm.toLowerCase().split(' ').join('');
  if (legalFormObject[legalFormValue]) return legalFormObject[legalFormValue];
  return legalForm;
};
