import { useLazyQuery } from '@apollo/client';
import { Link } from '@elseu/sdu-titan';
import { DOWNLOAD_FILE_QUERY } from 'graphql/queries/document';
import React from 'react';
import type { DownloadFile, DownloadFileVariables } from 'types/graphql/DownloadFile';

interface IDownloadFileLink {
  children: React.ReactNode;
  documentId: string;
}

export const DownloadFileLink = ({ children, documentId }: IDownloadFileLink) => {
  const [getDownloadLink] = useLazyQuery<DownloadFile, DownloadFileVariables>(DOWNLOAD_FILE_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      documentId,
    },
    onCompleted: ({ downloadFile }) => {
      window.open(downloadFile.toString(), '_blank');
    },
  });

  return <Link onClick={() => getDownloadLink()}>{children}</Link>;
};
