import type {
  RegisterMutation,
  ShareType,
  ShareTypeInput,
  ShareTypeVersion,
} from '@elseu/sdu-evidend-graphql';
import { MutationType } from '@elseu/sdu-evidend-graphql';
import { Divider, Text, TileGroup } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import { FakeInput } from 'components/FakeInput/FakeInput';
import { InputField } from 'components/FormFields/InputField';
import { NumberField } from 'components/FormFields/NumberField';
import { SelectField } from 'components/FormFields/SelectField';
import { booleanOptions, booleanTransform } from 'forms/helpers/booleanOptions';
import currencyCodeOptions from 'forms/helpers/currencyCodeOptions';
import { formatMutationType, mutationLabelObject } from 'helpers/formatMutation';
import { formatDate } from 'helpers/formatShares';
import {
  defaultCurrencyCode,
  formatMoney,
  moneyToNumber,
  multiplyMoney,
  numberToMoney,
} from 'helpers/money';
import { certificateTypeOptions, shareTypeOptions } from 'helpers/shareTypes';
import { omit, pick } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import type { DeepPartial } from 'react-hook-form';
import { useFormContext, useWatch } from 'react-hook-form';
import * as yup from 'yup';

const getShareTypeEditFormSchema = () => {
  return yup.object({}).shape(
    {
      type: yup.string().trim().nullable(),
      classifier: yup.string().trim().nullable(),
      voting: yup
        .boolean()
        .when('dividend', {
          is: false,
          then: yup.boolean().oneOf([true], () => t`Stem- en/of dividendrecht is vereist`),
        })
        .default(true),
      dividend: yup
        .boolean()
        .when('voting', {
          is: false,
          then: yup.boolean().oneOf([true], () => ''),
        })
        .default(true),
      meeting: yup.boolean().nullable().default(false),
      currencyCode: yup.string().trim().required().default(defaultCurrencyCode),
      name: yup.string().trim().required(),
      nominalValue: yup.number().moreThan(0).required(),
      hasAuthorizedCapital: yup.boolean().default(false),
      authorizedCapitalAmount: yup
        .mixed()
        .when('hasAuthorizedCapital', {
          is: true,
          then: yup.number().integer().min(1).required(),
          otherwise: yup.number().nullable().optional(),
        })
        .default(0),
      createdInMutationId: yup.string().trim().required(),
    },
    ['voting', 'dividend'] as any,
  );
};

export const shareTypeEditFormSchema = getShareTypeEditFormSchema();

export type ShareTypeEditFormValues = yup.TypeOf<typeof shareTypeEditFormSchema>;

export enum ShareTypeFormMode {
  Create = 'CREATE',
  Correct = 'CORRECT',
  Update = 'UPDATE',
}

export interface ShareTypeFormProps {
  isFoundation: boolean;
  nestedField?: string;
  mutations?: Array<Pick<RegisterMutation, 'id' | 'type' | 'title' | 'effectiveDate'>>;
  canEditCreatedInMutation: boolean;
}

const ShareTypeEditForm = ({
  isFoundation,
  nestedField = '',
  mutations = [],
  canEditCreatedInMutation = false,
}: ShareTypeFormProps) => {
  const { control, trigger, reset, getValues } = useFormContext();
  const hasAuthorizedCapital = useWatch({ control, name: `${nestedField}hasAuthorizedCapital` });
  const authorizedCapitalAmount = useWatch({
    control,
    name: `${nestedField}authorizedCapitalAmount`,
  });
  const [nominalValue, currencyCode, voting, dividend, name, type] = useWatch({
    control,
    name: [
      `${nestedField}nominalValue`,
      `${nestedField}currencyCode`,
      `${nestedField}voting`,
      `${nestedField}dividend`,
      `${nestedField}name`,
      `${nestedField}type`,
    ],
  });

  const authorizedCapital = useMemo(() => {
    if (authorizedCapitalAmount && nominalValue) {
      return formatMoney(
        multiplyMoney(
          numberToMoney({ value: nominalValue, currencyCode }),
          authorizedCapitalAmount,
        ),
      );
    }
  }, [authorizedCapitalAmount, nominalValue, currencyCode]);

  const mutationOptions = useMemo(
    () =>
      mutations
        .filter(
          (mutation) =>
            mutationLabelObject[mutation.type].canChangeAoA ||
            mutation.type === MutationType.ONBOARDING,
        )
        .map((mutation) => ({
          label: `${formatDate(mutation.effectiveDate)} (${formatMutationType(mutation)})`,
          value: mutation.id,
        })),
    [mutations],
  );

  const options = useMemo(() => {
    return isFoundation ? certificateTypeOptions() : shareTypeOptions();
  }, [isFoundation]);

  const handleTypeChange = useCallback(
    (value?: string) => {
      const option = options.find((option) => option.value === value);
      if (!option) return;
      const names = options.map((option) => option.name);
      const { label: __label, value: __value, name: optionName, ...shareTypeFields } = option;
      const resetValues: DeepPartial<ShareType> = {
        ...shareTypeFields,
      };
      if (!name || names.includes(name)) resetValues.name = optionName;
      reset({ ...getValues(), ...resetValues }, { keepDirty: true });
    },
    [name, getValues, options, reset],
  );

  useEffect(() => {
    trigger(`${nestedField}dividend`);
  }, [voting, nestedField, trigger]);
  useEffect(() => {
    trigger(`${nestedField}voting`);
  }, [dividend, nestedField, trigger]);

  return (
    <>
      {!!options.length && (
        <TileGroup
          defaultValue={type}
          label={t`Type`}
          name="type"
          options={options.map(({ label, value }) => ({ label, value }))}
          spaceAfter={10}
          variant="horizontal-stretch"
          onChange={handleTypeChange}
        />
      )}

      <Columns count={2} spaceAfter={isFoundation ? 0 : 6}>
        <Column>
          {isFoundation && (
            <SelectField
              label={t`Stemrecht`}
              name={`${nestedField}voting`}
              options={booleanOptions()}
              placeholder={t`Stemrecht`}
              readOnly={!isFoundation}
              transform={booleanTransform}
            />
          )}
          {!isFoundation && <FakeInput label={t`Stemrecht`} value={voting ? t`Ja` : t`Nee`} />}
        </Column>
        <Column>
          {isFoundation && (
            <SelectField
              label={t`Dividendrecht`}
              name={`${nestedField}dividend`}
              options={booleanOptions()}
              placeholder={t`Dividendrecht`}
              readOnly={!isFoundation}
              transform={booleanTransform}
            />
          )}
          {!isFoundation && (
            <FakeInput label={t`Dividendrecht`} value={dividend ? t`Ja` : t`Nee`} />
          )}
        </Column>
      </Columns>

      {isFoundation && (
        <Columns count={2} spaceAfter={6}>
          <Column>
            <SelectField
              label={t`Vergaderrecht`}
              name={`${nestedField}meeting`}
              options={booleanOptions()}
              placeholder={t`Vergaderrecht`}
              transform={booleanTransform}
            />
          </Column>
        </Columns>
      )}

      <Divider spaceAfter={6} />
      <Columns count={2}>
        <Column>
          <SelectField
            required
            label={t`Valuta`}
            name={`${nestedField}currencyCode`}
            options={currencyCodeOptions()}
            placeholder={t`Valuta`}
          />
        </Column>
        <Column>
          <NumberField
            required
            label={t`Nominale waarde`}
            maxDecimals={9}
            name={`${nestedField}nominalValue`}
            placeholder={t`Nominale waarde`}
          />
        </Column>
      </Columns>

      <Columns count={2}>
        <Column>
          <InputField
            label={t`Letter`}
            name={`${nestedField}classifier`}
            placeholder={t`Letter`}
            spaceAfter={0}
          />
        </Column>
        <Column>
          <InputField
            required
            label={t`Benaming`}
            name={`${nestedField}name`}
            placeholder={t`Benaming`}
            spaceAfter={0}
          />
        </Column>
      </Columns>

      {!isFoundation && (
        <>
          <Divider spaceAfter={6} spaceBefore={6} />

          <Columns count={2}>
            <Column>
              <SelectField
                label={t`Maatschappelijk kapitaal`}
                name={`${nestedField}hasAuthorizedCapital`}
                options={booleanOptions()}
                spaceAfter={0}
                transform={booleanTransform}
              />
            </Column>
            <Column>
              {hasAuthorizedCapital && (
                <>
                  <NumberField
                    required
                    label={t`Aantal aandelen`}
                    name={`${nestedField}authorizedCapitalAmount`}
                    placeholder={t`Aantal aandelen`}
                    spaceAfter={0}
                  />
                  {authorizedCapital && (
                    <Text color="grey80" type="labelTiny">
                      <Trans>Maatschappelijk kapitaal</Trans>: {authorizedCapital}
                    </Text>
                  )}
                </>
              )}
            </Column>
          </Columns>
        </>
      )}

      {canEditCreatedInMutation && (
        <>
          <Divider spaceAfter={6} spaceBefore={6} />

          <Columns count={2}>
            <Column>
              <SelectField
                label={t`Aangemaakt in mutatie`}
                name={`${nestedField}createdInMutationId`}
                options={mutationOptions}
                popoverPlacement="auto-start"
                spaceAfter={0}
              />
            </Column>
          </Columns>
        </>
      )}
    </>
  );
};

export const mapShareTypeVersionToShareTypeEditFormValues = (
  shareTypeVersion: ShareTypeVersion,
  mutationId?: string,
): ShareTypeEditFormValues =>
  ({
    ...pick(shareTypeVersion, [
      'classifier',
      'dividend',
      'meeting',
      'name',
      'voting',
      'authorizedCapitalAmount',
    ]),
    nominalValue: moneyToNumber(shareTypeVersion.nominalValue),
    currencyCode: shareTypeVersion.nominalValue.currencyCode,
    createdInMutationId: mutationId || shareTypeVersion.createdInMutation?.id || null,
    hasAuthorizedCapital: !!shareTypeVersion.authorizedCapitalAmount,
    type: shareTypeOptions().find(
      (option) =>
        option.voting === shareTypeVersion.voting && option.dividend === shareTypeVersion.dividend,
    )?.value,
  } as ShareTypeEditFormValues);

export const mapShareTypeEditFormValuesToShareTypeInput = ({
  nominalValue,
  currencyCode,
  hasAuthorizedCapital,
  authorizedCapitalAmount,
  ...formValues
}: ShareTypeEditFormValues): ShareTypeInput =>
  ({
    ...omit(formValues, ['type', 'hasAuthorizedCapital']),
    nominalValue: numberToMoney({ value: nominalValue, currencyCode } as any),
    authorizedCapitalAmount: hasAuthorizedCapital ? authorizedCapitalAmount : 0,
  } as ShareTypeInput);

export { ShareTypeEditForm, shareTypeEditFormSchema as ShareTypeEditFormSchema };
