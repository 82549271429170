import type { StepProps } from '@elseu/sdu-titan';
import { flatten } from 'lodash';

const getStepIds = (steps: StepProps[]) => {
  // If a step has subSteps, we ignore the id of the step itself.
  return flatten(
    steps.map((step) =>
      step.subSteps?.length ? step.subSteps.map((substep) => substep.id) : step.id,
    ),
  );
};

export const incrementStep = (delta: -1 | 1, steps: StepProps[], step: string): string => {
  const ids = getStepIds(steps);
  // If the current step exists, increment or decrement the position.
  if (ids.includes(step)) {
    // Out of bounds will yield undefined.
    return ids[ids.indexOf(step) + delta] || '';
  }
  return '';
};

export const substepAllowed = (steps: StepProps[], id: string): boolean => {
  const parentStep = steps.find((step) => step.subSteps?.find((substep) => substep.id === id));

  // Step id is not of a substep.
  if (!parentStep) return true;

  // Typescript falsely thinks subSteps can still be undefined.
  // Add condition to simplify code below.
  if (!parentStep.subSteps) return true;

  // Right here we know parentStep contains a substep with the provided id.
  const substeps = parentStep.subSteps;
  const index = substeps.findIndex((substep) => substep.id === id);
  const substep = substeps[index];

  // If the requested id is disabled, it is not allowed.
  if (substep.isDisabled) return false;

  return true;
};

export const stepExistsInSteps = (steps: StepProps[], step: string): boolean => {
  const ids = getStepIds(steps);
  return ids.includes(step);
};
