import { gql } from '@apollo/client';

import { DOCUMENT_FRAGMENT } from '../fragments';

export const DOWNLOAD_FILE_QUERY = gql`
  query DownloadFile($documentId: ID!) {
    downloadFile(documentId: $documentId)
  }
`;

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument($registerId: ID!, $document: DocumentInput!) {
    createDocument(registerId: $registerId, document: $document) {
      ...DocumentFields
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($documentId: ID!, $document: DocumentInput!) {
    updateDocument(documentId: $documentId, document: $document) {
      ...DocumentFields
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($documentId: ID!) {
    deleteDocument(documentId: $documentId) {
      ...DocumentFields
    }
  }
  ${DOCUMENT_FRAGMENT}
`;

export const UPLOAD_FILE = gql`
  mutation UploadFile($documentId: ID!, $file: Upload!) {
    uploadFile(documentId: $documentId, file: $file) {
      name
      mimeType
      size
      uploadedAt
    }
  }
`;

export const DELETE_FILE = gql`
  mutation DeleteFile($documentId: ID!) {
    deleteFile(documentId: $documentId) {
      name
      mimeType
      size
      uploadedAt
    }
  }
`;
