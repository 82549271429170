import { t } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import { InputField } from 'components/FormFields/InputField';
import { SelectField } from 'components/FormFields/SelectField';
import { countryCodeOptions } from 'forms/helpers/countryCodeOptions';
import React from 'react';
import * as yup from 'yup';

const getLegalEntityForeignGeneralInfoFormSchema = () => {
  return yup.object({
    tradeRegisterNumber: yup.string().nullable().trim().required(),
    name: yup.string().nullable().trim().required(),
    countryOfRegistration: yup.string().nullable().trim().required(),
  });
};

export const legalEntityForeignGeneralInfoFormSchema = getLegalEntityForeignGeneralInfoFormSchema();

export type LegalEntityForeignGeneralInfoFormValues = yup.InferType<
  typeof legalEntityForeignGeneralInfoFormSchema
>;

interface ILegalEntityForeignGeneralInfoForm {
  nestedField?: string;
  hasColumns?: boolean;
}
const LegalEntityForeignGeneralInfoForm = ({
  nestedField = '',
  hasColumns = true,
}: ILegalEntityForeignGeneralInfoForm) => {
  return (
    <>
      <Columns count={hasColumns ? 2 : 1}>
        <Column>
          <InputField required label={t`Naam`} name={`${nestedField}name`} placeholder={t`Naam`} />
        </Column>
      </Columns>
      <Columns count={hasColumns ? 2 : 1} spaceAfter={4}>
        <Column>
          <SelectField
            isSearchable
            required
            label={t`Land van handelsregister`}
            name={`${nestedField}countryOfRegistration`}
            options={countryCodeOptions().filter(({ value }) => value !== 'NL')}
            placeholder={t`Land`}
          />
        </Column>
        <Column>
          <InputField
            required
            label={t`Registratienummer`}
            name={`${nestedField}tradeRegisterNumber`}
            placeholder={t`Registratienummer`}
          />
        </Column>
      </Columns>
    </>
  );
};

export {
  LegalEntityForeignGeneralInfoForm,
  legalEntityForeignGeneralInfoFormSchema as LegalEntityForeignGeneralInfoFormSchema,
};
