import type { ShareType, ShareTypeVersion } from '@elseu/sdu-evidend-graphql';
import { t } from '@lingui/macro';
import { flatten } from 'lodash';
import type { DeepPartial } from 'react-hook-form';

export interface ShareTypeOption
  extends DeepPartial<Pick<ShareType, 'meeting' | 'voting' | 'dividend' | 'name'>> {
  label: string;
  value: string;
}

export const certificateTypeOptions = (): ShareTypeOption[] => [];

export const shareTypeOptions = (): ShareTypeOption[] => [
  {
    value: 'ordinary',
    label: t`Gewone aandelen`,
    name: t`Gewone aandelen`,
    meeting: true,
    voting: true,
    dividend: true,
  },
  {
    value: 'profitless',
    label: t`Winstrechtloze aandelen`,
    name: t`Winstrechtloze aandelen`,
    meeting: true,
    voting: true,
    dividend: false,
  },
  {
    value: 'non-voting',
    label: t`Stemrechtloze aandelen`,
    name: t`Stemrechtloze aandelen`,
    meeting: true,
    dividend: true,
    voting: false,
  },
];

export type ShareTypeVersionWithShareType = ShareTypeVersion & {
  shareType: ShareType;
};

export const getShareTypeVersions = (shareTypes?: ShareType[]): ShareTypeVersionWithShareType[] =>
  flatten(
    (shareTypes || []).map((shareType) =>
      shareType.versions.map((version) => ({
        ...version,
        shareType,
      })),
    ),
  );
