import type { Spacings } from '@elseu/sdu-titan';
import { Block, InputGroup, InputGroupDivider, Text } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';
import { formFieldErrorProps } from 'components/FormFields/formFieldErrorProps';
import { NumberField } from 'components/FormFields/NumberField';
import { formatNumber } from 'helpers/formatShares';
import { calculateSeriesTotal } from 'helpers/shareBatches';
import { useCallback, useMemo } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
interface IShareSeriesInput {
  label?: string;
  typesLabel?: string;
  isLabelHidden?: boolean;
  nestedField?: string;
  required?: boolean;
  spaceAfter?: Spacings;
}

const ShareSeriesInput = ({
  label,
  isLabelHidden = false,
  nestedField = '',
  required,
  typesLabel = t`aandelen`,
  spaceAfter = 2,
}: IShareSeriesInput) => {
  const fromField = `${nestedField}from`;
  const toField = `${nestedField}to`;

  const { control, trigger } = useFormContext();
  const [from, to] = useWatch({
    control,
    name: [fromField, toField],
  });

  // Get the error for any of the two fields.
  const { errors } = useFormState({ control });

  // Combined validation between `from` and `to` is implemented on `to`.
  // This means that whenever we change the value of `from`, we should manuall trigger
  // the validation on `to` since it's not done automatically by react-hook-form.
  // We do this onBlur because validation the entire form with each keypress is too slow.
  const validateTo = useCallback(() => trigger(toField), [trigger, toField]);
  const errorProps = formFieldErrorProps(errors, [fromField, toField]);
  const hasError = errorProps.hasError;
  // InputGroup does not support isLabelHidden or required, so we implement it here.
  const inputGroupLabel = useMemo(() => {
    if (isLabelHidden) return undefined;
    const text = label || t`Aandelenreeks`;
    if (required) return `${text} *`;
    return text;
  }, [isLabelHidden, label, required]);

  return (
    <Block spaceAfter={spaceAfter}>
      <InputGroup {...formFieldErrorProps(errors, [fromField, toField])} label={inputGroupLabel}>
        <NumberField
          isCentered
          isErrorHidden
          isLabelHidden
          label={t`Begin`}
          name={fromField}
          placeholder={t`Begin`}
          required={required}
          spaceAfter={0}
          onChange={validateTo}
        />

        <InputGroupDivider label=" - " />

        <NumberField
          isCentered
          isErrorHidden
          isLabelHidden
          label={t`Eind`}
          name={toField}
          placeholder={t`Eind`}
          required={required}
          spaceAfter={0}
        />
      </InputGroup>

      {!hasError && (
        <Text color="grey80" type="labelTiny">
          {formatNumber(calculateSeriesTotal(from, to))} {typesLabel}
        </Text>
      )}
    </Block>
  );
};

export { ShareSeriesInput };
