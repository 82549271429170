import { gql } from '@apollo/client';

import { SHARE_TYPE_FRAGMENT } from '../fragments';

export const CREATE_SHARE_TYPE = gql`
  mutation CreateShareType($registerId: ID!, $shareType: ShareTypeInput!) {
    createShareType(registerId: $registerId, shareType: $shareType) {
      ...ShareTypeFields
    }
  }
  ${SHARE_TYPE_FRAGMENT}
`;

export const UPDATE_SHARE_TYPE = gql`
  mutation UpdateShareType($shareTypeId: ID!, $shareType: ShareTypeInput!) {
    updateShareType(shareTypeId: $shareTypeId, shareType: $shareType) {
      ...ShareTypeFields
    }
  }
  ${SHARE_TYPE_FRAGMENT}
`;

export const CORRECT_SHARE_TYPE_VERSION = gql`
  mutation CorrectShareTypeVersion($shareTypeVersionId: ID!, $shareType: ShareTypeInput!) {
    correctShareTypeVersion(shareTypeVersionId: $shareTypeVersionId, shareType: $shareType) {
      ...ShareTypeFields
    }
  }
  ${SHARE_TYPE_FRAGMENT}
`;

export const DELETE_SHARE_TYPE_VERSION = gql`
  mutation DeleteShareTypeVersion($shareTypeVersionId: ID!) {
    deleteShareTypeVersion(shareTypeVersionId: $shareTypeVersionId) {
      ...ShareTypeFields
    }
  }
  ${SHARE_TYPE_FRAGMENT}
`;

export const DELETE_SHARE_TYPE = gql`
  mutation DeleteShareType($shareTypeId: ID!) {
    deleteShareType(shareTypeId: $shareTypeId) {
      ...ShareTypeFields
    }
  }
  ${SHARE_TYPE_FRAGMENT}
`;
