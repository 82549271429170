import type { FetchResult } from '@apollo/client';
import type { ApolloError, GraphQLErrors } from '@apollo/client/errors';
import type { ValidationError } from '@elseu/sdu-evidend-graphql';
import { yupResolver } from '@hookform/resolvers/yup';
import type { SetFormErrorsProps, ValidationErrorCode } from 'forms/helpers/setFormErrors';
import { useHandleMutationResponse } from 'forms/mutation/useHandleMutationResponse';
import type { DeepPartial } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { AnyObjectSchema, Asserts, TypeOf } from 'yup';

/**
 * Boilerplate to create a form that can handle creating a mutation and do validation
 * @param props
 * @returns
 */
const useMutationForm = <
  MutationSchema extends AnyObjectSchema,
  OnError extends (errors: ValidationError[], mutationId?: string) => void,
  Return extends {
    [k in keyof Return]: { [k2 in keyof Return[k] | 'validationErrors']: Return[k][k2] };
  },
>({
  onSubmit,
  onSuccess,
  onError,
  onApolloError,
  validationErrorMapper,
  graphQlErrorMapper = (graphQLErrors) =>
    graphQLErrors.map(({ extensions: { validationError } }) => validationError as ValidationError),
  schema,
  defaultValues,
  replaceValidationPart,
  allowedErrors = [],
}: {
  onSuccess?: (mutation: Return) => void;
  onError?: OnError;
  onApolloError?: (error: ApolloError) => void;
  onSubmit: (formValues: Asserts<MutationSchema>) => Promise<FetchResult<Return>>;
  schema: MutationSchema;
  defaultValues?: DeepPartial<TypeOf<MutationSchema>>;
  replaceValidationPart?: SetFormErrorsProps['replaceValidationPart'];
  graphQlErrorMapper?: (error: GraphQLErrors) => ValidationError[];
  validationErrorMapper?: (
    errors: ValidationError[],
    formValues: TypeOf<MutationSchema>,
  ) => ValidationError[];
  allowedErrors?: ValidationErrorCode[];
}) => {
  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues,
  });
  const [handleResponse, handleError] = useHandleMutationResponse({
    form,
    onError,
    validationErrorMapper,
    replaceValidationPart,
    allowedErrors,
    onApolloError,
    onSuccess,
    graphQlErrorMapper,
  });
  const { handleSubmit } = form;

  /** handles the submit as if it was a form, and tries to ease the coupling of backend validation errors to form fields  */
  const handleMutationSubmit = handleSubmit(async (formValues) => {
    form.reset(formValues); // this will tell the form that the fields are no longer dirty, so we can route away without a warning
    return onSubmit(formValues).then(handleResponse).catch(handleError);
  });

  /** the same as handleMutationSubmit, but you can pass in the formValues instead of using the form  */
  const submitMutation = (formValues: TypeOf<MutationSchema>) => {
    form.reset(formValues); // this will update the fields before doing the submit, and ensure the form is not dirty
    return handleMutationSubmit();
  };

  return [
    form,
    {
      handleMutationSubmit,
      submitMutation,
    },
  ] as const;
};

export { useMutationForm };
