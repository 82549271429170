import { Clickable, CloseIcon, Flex, Label } from '@elseu/sdu-titan';
import React from 'react';
import styled from 'styled-components';

const StyledClickable = styled(Clickable)`
  height: 48px;
`;

const DisabledClickable = styled(StyledClickable)`
  cursor: default;
  color: ${(props) => props.theme.designTokens.colors.grey40};
`;

interface IFieldRowRemoveButton {
  onRemove: () => void;
  isLabelHidden?: boolean;
  isDisabled?: boolean;
}
const FieldRowRemoveButton = ({
  isLabelHidden = false,
  isDisabled = false,
  onRemove,
}: IFieldRowRemoveButton) => {
  return (
    <>
      {!isLabelHidden && <Label spaceAfter={2}>&nbsp;</Label>}
      <Flex alignItems="center">
        {isDisabled ? (
          <DisabledClickable>
            <CloseIcon />
          </DisabledClickable>
        ) : (
          <StyledClickable onClick={() => onRemove()}>
            <CloseIcon />
          </StyledClickable>
        )}
      </Flex>
    </>
  );
};

export { FieldRowRemoveButton };
