import { useMutation } from '@apollo/client';
import type { LegalEntity } from '@elseu/sdu-evidend-graphql';
import { Link } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { SidebarFormContainer, SidebarFormContent } from 'components/SidebarForm/SidebarForm';
import { setFormErrors } from 'forms/helpers/setFormErrors';
import { ADD_KVK_NUMBER_TO_LEGAL_ENTITY } from 'graphql/queries/legalEntity';
import { useGlobalError } from 'hooks/useGlobalError';
import { useRouter } from 'next/router';
import { useState } from 'react';
import type {
  AddKvkNumberToLegalEntity,
  AddKvkNumberToLegalEntityVariables,
} from 'types/graphql/AddKvkNumberToLegalEntity';

import { kvkFormSchema, KvkSidebarForm } from './KvkSidebarForm';

interface IKvkSidebarLink {
  legalEntity: LegalEntity;
}

export const KvkSidebarLink = ({ legalEntity }: IKvkSidebarLink) => {
  const router = useRouter();
  const [, setGlobalError] = useGlobalError();
  const [isFormDrawerShown, setFormDrawerShown] = useState<boolean>(false);
  const [addKvkNumberToLegalEntity] = useMutation<
    AddKvkNumberToLegalEntity,
    AddKvkNumberToLegalEntityVariables
  >(ADD_KVK_NUMBER_TO_LEGAL_ENTITY);
  return (
    <>
      <SidebarFormContainer isDrawerShown={isFormDrawerShown}>
        <SidebarFormContent
          form={<KvkSidebarForm />}
          formSchema={kvkFormSchema}
          header={t`KVK-nummer toevoegen`}
          setDrawerClosed={() => setFormDrawerShown(false)}
          onSubmit={({ kvkNumber }) => {
            addKvkNumberToLegalEntity({
              variables: {
                legalEntityId: legalEntity.id,
                kvkNumber,
              },
              onCompleted: ({ addKvkNumberToLegalEntity }) => {
                setFormDrawerShown(false);
                router.push({
                  pathname: `/rechtspersoon/${addKvkNumberToLegalEntity.id}`,
                });
              },
              onError: ({ graphQLErrors }) => {
                setFormErrors({ errors: graphQLErrors, setGlobalError });
              },
            });
          }}
        />
      </SidebarFormContainer>

      <Link onClick={() => setFormDrawerShown(true)}>
        <Trans>KVK-nummer toevoegen</Trans>
      </Link>
    </>
  );
};
