import { mergeCards } from 'forms/helpers/mergeCards';
import type { ShareSeriesMutation } from 'mutations/shareSeries';
import * as yup from 'yup';

// A seriesdata ard is a set of share series grouped by the party id field. (to.id)
// Each shareSerie can be set to paid or not individually, but each card is for one party only.
// Before sending the cards back to server, the seriesData should be mapped to multiple mutation inputs with the same party id.

export const addSeriesDataCard = mergeCards<SeriesDataCard>((a, b) => a.to.id === b.to.id);
export const mapShareSeriesToCard = (mutationSeries: ShareSeriesMutation) => ({
  to: { id: mutationSeries.to.id },
  seriesData: [
    {
      paid: mutationSeries.paid,
      shareSeries: {
        from: mutationSeries.shareSeries.from as number,
        to: mutationSeries.shareSeries.to as number,
        shareType: { id: mutationSeries.shareSeries.shareType.id },
      },
    },
  ],
});

export const shareSeriesToCards = (
  mutationSeries: ShareSeriesMutation[],
  defaultCards: SeriesDataCard[] = [],
) =>
  mutationSeries.reduce(
    (cards, data) => addSeriesDataCard(cards, mapShareSeriesToCard(data) as any),
    defaultCards,
  );

export const cardsToShareSeries = (cards: SeriesDataCard[]) =>
  cards.flatMap(({ to, seriesData }) =>
    seriesData.flatMap(({ shareSeries, paid }) => ({
      toPartyId: to.id,
      shareSeries: {
        shareTypeId: shareSeries.shareType.id,
        from: shareSeries.from as unknown as bigint,
        to: shareSeries.to as unknown as bigint,
      },
      paid,
    })),
  );

// share range
const getShareSeriesInputSchema = () =>
  yup.object({
    from: yup.number().nullable().required().integer().min(1).default(null),
    to: yup.number().nullable().required().integer().min(yup.ref('from')).default(null),
  });
export const shareSeriesInputSchema = getShareSeriesInputSchema();

// share type select
const getShareTypeSelectSchema = () =>
  yup.object({
    shareType: yup.object({
      id: yup.string().trim().nullable().required().default(null),
    }),
  });
export const shareTypeSelectSchema = getShareTypeSelectSchema();

const getShareSeriesInputRowPaidSchema = () =>
  yup.object({
    paid: yup.boolean().nullable().optional().default(true),
  });
export const shareSeriesInputRowPaidSchema = getShareSeriesInputRowPaidSchema();

// card share serie row
const getShareSeriesInputRowSchema = () =>
  yup.object({
    shareSeries: shareSeriesInputSchema.concat(shareTypeSelectSchema),
  });

export const shareSeriesInputRowSchemaNoPaid = getShareSeriesInputRowSchema();
export const shareSeriesInputRowSchema = shareSeriesInputRowSchemaNoPaid.concat(
  shareSeriesInputRowPaidSchema,
);
export const defaultSeriesData = shareSeriesInputRowSchema.getDefault();

// card
export const getSeriesDataCardSchema = () =>
  yup.object({
    to: yup
      .object({
        id: yup.string().trim().nullable().required().default(null),
      })
      .required(),
    seriesData: yup
      .array()
      .min(1)
      .of(shareSeriesInputRowSchema)
      .required()
      .default([shareSeriesInputRowSchema.getDefault()]),
  });

export type SeriesDataCardRow = yup.InferType<typeof shareSeriesInputRowSchema>;
export type SeriesDataCard = yup.InferType<ReturnType<typeof getSeriesDataCardSchema>>;
