import type { EndUsufruct, Party } from '@elseu/sdu-evidend-graphql';
import { Block, Box, Text } from '@elseu/sdu-titan';
import type { SummaryCardsComponentProps } from 'helpers/formatMutation';
import { useLabels } from 'hooks/useLabels';
import { uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import { CapitalCardListItem } from 'summaries/CapitalCards/CapitalCardListItem';
import { ShareholderCapitalCard } from 'summaries/CapitalCards/ShareholderCapitalCard';
import { DeedCard } from 'summaries/DeedCard/DeedCard';

const getEndUsufructsOfParty = (endUsufructs: EndUsufruct[], party: Party): EndUsufruct[] =>
  endUsufructs.filter((endUsufruct) => endUsufruct.owner.id === party.id);

export const EndUsufructSummaryCards = ({
  backgroundVariant = 'white',
  mutation,
  onDocumentSave,
}: SummaryCardsComponentProps) => {
  const { register } = mutation;
  const { legalEntity } = register;
  const labels = useLabels('endUsufruct.summary', legalEntity.isFoundation);
  const endUsufructs = useMemo(() => mutation.endUsufruct || [], [mutation.endUsufruct]);
  const parties = useMemo(
    () =>
      uniqBy(
        endUsufructs.map((endUsufruct) => endUsufruct.owner),
        'id',
      ),
    [endUsufructs],
  );

  return (
    <>
      {!!parties.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            {labels.shares}
          </Text>

          <Box spaceAfter={10}>
            {parties.map((party) => (
              <ShareholderCapitalCard
                key={party.id}
                backgroundVariant={backgroundVariant}
                compareDeceaseDate={mutation.effectiveDate}
                party={party}
              >
                {getEndUsufructsOfParty(endUsufructs, party).map((endUsufruct) => (
                  <CapitalCardListItem
                    key={endUsufruct.shareSeries.shareType.id}
                    mutationOptions={{ showPaidStatus: true }}
                    shareSeries={endUsufruct.shareSeries}
                  />
                ))}
              </ShareholderCapitalCard>
            ))}
          </Box>
        </>
      )}

      {mutation.document && (
        <Block spaceAfter={10}>
          <DeedCard
            backgroundVariant={backgroundVariant}
            document={mutation.document}
            mutation={mutation}
            onDocumentSave={onDocumentSave}
          />
        </Block>
      )}
    </>
  );
};
