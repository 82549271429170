import { useMutation } from '@apollo/client';
import type { LegalEntity } from '@elseu/sdu-evidend-graphql';
import { Card, Link, PencilIcon } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { SidebarFormContainer, SidebarFormContent } from 'components/SidebarForm/SidebarForm';
import { setFormErrors } from 'forms/helpers/setFormErrors';
import type { LegalEntityForeignGeneralInfoFormValues } from 'forms/legal-entity/LegalEntityForeignGeneralInfoForm';
import {
  LegalEntityForeignGeneralInfoForm,
  legalEntityForeignGeneralInfoFormSchema,
} from 'forms/legal-entity/LegalEntityForeignGeneralInfoForm';
import { PartyUpdateForm, partyUpdateFormSchema } from 'forms/PartyUpdateForm';
import { mapLegalEntityToLegalEntityInput } from 'graphql/input-mappers/LegalEntity';
import { UPDATE_LEGAL_ENTITY } from 'graphql/queries/legalEntity';
import { useGlobalError } from 'hooks/useGlobalError';
import { useState } from 'react';
import type {
  UpdateLegalEntity,
  UpdateLegalEntityVariables,
} from 'types/graphql/UpdateLegalEntity';

interface IForeignSidebarLink {
  legalEntity: LegalEntity;
}

export const ForeignSidebarLink = ({ legalEntity }: IForeignSidebarLink) => {
  const [, setGlobalError] = useGlobalError();
  const [updateLegalEntity] = useMutation<UpdateLegalEntity, UpdateLegalEntityVariables>(
    UPDATE_LEGAL_ENTITY,
  );
  const [isFormDrawerShown, setFormDrawerShown] = useState<boolean>(false);

  const formSchema = legalEntityForeignGeneralInfoFormSchema.concat(partyUpdateFormSchema({}));
  return (
    <>
      <SidebarFormContainer isDrawerShown={isFormDrawerShown}>
        <SidebarFormContent
          defaultValues={legalEntity as LegalEntityForeignGeneralInfoFormValues}
          form={
            <>
              <LegalEntityForeignGeneralInfoForm hasColumns={false} />
              <Card backgroundVariant="grey">
                <PartyUpdateForm isFoundation={legalEntity.isFoundation} />
              </Card>
            </>
          }
          formSchema={formSchema}
          header={t`Gegevens wijzigen`}
          setDrawerClosed={() => setFormDrawerShown(false)}
          onSubmit={({ date, reason, tradeRegisterNumber, name, countryOfRegistration }, form) => {
            const legalEntityInput = mapLegalEntityToLegalEntityInput({
              ...legalEntity,
              tradeRegisterNumber,
              name,
              countryOfRegistration,
            });
            updateLegalEntity({
              variables: {
                legalEntityId: legalEntity.id,
                legalEntity: legalEntityInput,
                effectiveDate: date,
                reason,
              },
              onCompleted: () => {
                setFormDrawerShown(false);
              },
              onError: ({ graphQLErrors }) => {
                setFormErrors({
                  errors: graphQLErrors,
                  form,
                  setGlobalError,
                });
              },
            });
          }}
        />
      </SidebarFormContainer>

      <Link prefixAdornment={<PencilIcon />} onClick={() => setFormDrawerShown(true)}>
        <Trans>Wijzig</Trans>
      </Link>
    </>
  );
};
