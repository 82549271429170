import type { DelegationDetails } from '@elseu/sdu-evidend-graphql';
import type { Spacings } from '@elseu/sdu-titan';
import { Card, List, ListItem } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { GreenOkCircleIcon } from 'components/GreenOkCircleIcon/GreenOkCircleIcon';
import { RedCancelCircleIcon } from 'components/RedCancelCircleIcon/RedCancelCircleIcon';
import React from 'react';

interface IDelegateRightsCard {
  rights: DelegationDetails;
  spaceAfter?: Spacings;
  backgroundVariant: 'white' | 'grey';
}
const DelegateRightsCard = ({ rights, spaceAfter, backgroundVariant }: IDelegateRightsCard) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { meeting, voting, dividend } = rights;
  return (
    <Card backgroundVariant={backgroundVariant} spaceAfter={spaceAfter}>
      <List spaceAfter={0} type="ul">
        <ListItem prefixAdornment={voting ? <GreenOkCircleIcon /> : <RedCancelCircleIcon />}>
          <Trans>Stemrecht</Trans>
        </ListItem>
        <ListItem prefixAdornment={meeting ? <GreenOkCircleIcon /> : <RedCancelCircleIcon />}>
          <Trans>Vergaderrecht</Trans>
        </ListItem>
        <ListItem prefixAdornment={dividend ? <GreenOkCircleIcon /> : <RedCancelCircleIcon />}>
          <Trans>Dividendrecht</Trans>
        </ListItem>
      </List>
    </Card>
  );
};

DelegateRightsCard.defaultProps = {
  spaceAfter: 6,
  backgroundVariant: 'white',
};

export default DelegateRightsCard;
