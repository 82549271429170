import type {
  Attachment,
  AttachmentDetails,
  AttachmentDetailsInput,
  AttachmentInput,
  Maybe,
} from '@elseu/sdu-evidend-graphql';
import { AttachmentType } from '@elseu/sdu-evidend-graphql';

import { mapShareSeriesToShareSeriesInput } from './ShareSeries';

export const mapAttachmentDetailsToAttachmentDetailsInput = (
  attachmentDetails?: Maybe<AttachmentDetails>,
): AttachmentDetailsInput | null => {
  if (!attachmentDetails) return null;
  return {
    attachedAt: attachmentDetails.attachedAt,
    bailiffId: attachmentDetails.bailiff.id,
    requiredByIds: attachmentDetails.requiredBy?.map((requiredBy) => requiredBy.id),
  };
};

export const mapAttachmentToAttachmentInput = (attachment: Attachment): AttachmentInput => ({
  ownerPartyId: attachment.owner.id,
  type: (attachment.type as AttachmentType | null) || AttachmentType.PREJUDGEMENT,
  shareSeries: mapShareSeriesToShareSeriesInput(attachment.shareSeries),
  details: mapAttachmentDetailsToAttachmentDetailsInput(attachment.details),
});
