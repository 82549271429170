import { t } from '@lingui/macro';
import { DatePickerField } from 'components/FormFields/DatePickerField';
import { InputField } from 'components/FormFields/InputField';
import { DocumentUploadForm, documentUploadFormSchema } from 'forms/document/DocumentUploadForm';
import { dateBasicValidation } from 'forms/helpers/dateTransform';
import * as yup from 'yup';

interface PartyUpdateFormSchemaProps {
  isDocumentRequired?: boolean;
  isDateRequired?: boolean;
}
const getPartyUpdateFormSchema = ({
  isDateRequired = true,
  isDocumentRequired,
}: PartyUpdateFormSchemaProps) => {
  return yup.object({
    date: isDateRequired ? dateBasicValidation.required() : dateBasicValidation,
    reason: yup.string().nullable().trim(),
    ...(isDocumentRequired ? { document: documentUploadFormSchema } : null),
  });
};

export const partyUpdateFormSchema = (props: PartyUpdateFormSchemaProps) =>
  getPartyUpdateFormSchema(props);

interface PartyUpdateFormProps extends PartyUpdateFormSchemaProps {
  isFoundation: boolean;
  nestedField?: string;
}
export const PartyUpdateForm = ({
  nestedField = '',
  isDocumentRequired,
  isDateRequired = true,
  isFoundation,
}: PartyUpdateFormProps) => {
  return (
    <>
      {isDateRequired && (
        <DatePickerField
          isFutureDisabled
          required
          label={t`Ingangsdatum wijziging`}
          name={`${nestedField}date`}
          placeholder={t`Ingangsdatum wijziging`}
          spaceAfter={6}
        />
      )}
      <InputField
        label={t`Reden voor wijziging`}
        name={`${nestedField}reason`}
        placeholder={t`Reden voor wijziging`}
        rows={3}
        type="textarea"
      />
      {isDocumentRequired && (
        <DocumentUploadForm
          hasColumns={false}
          isFoundation={isFoundation}
          nestedField="document."
        />
      )}
    </>
  );
};
