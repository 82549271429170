import type { Administration, Party } from '@elseu/sdu-evidend-graphql';
import { Block, Box, Text } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import type { SummaryCardsComponentProps } from 'helpers/formatMutation';
import { useLabels } from 'hooks/useLabels';
import { uniqBy } from 'lodash';
import React, { useMemo } from 'react';
import { CapitalCardListItem } from 'summaries/CapitalCards/CapitalCardListItem';
import { ShareholderCapitalCard } from 'summaries/CapitalCards/ShareholderCapitalCard';
import { DeedCard } from 'summaries/DeedCard/DeedCard';
import PartyCard from 'summaries/PartyCard/PartyCard';

const getAdministrationsOfParty = (
  administrations: Administration[],
  party: Party,
): Administration[] =>
  administrations.filter((administration) => administration.owner.id === party.id);

export const AdministrationSummaryCards = ({
  backgroundVariant = 'white',
  mutation,
  onDocumentSave,
}: SummaryCardsComponentProps) => {
  const { register } = mutation;
  const { legalEntity } = register;
  const labels = useLabels('administration.summary', legalEntity.isFoundation);
  const administrations = useMemo(() => mutation.administration || [], [mutation.administration]);
  const parties = useMemo(
    () =>
      uniqBy(
        administrations.map((administration) => administration.owner),
        'id',
      ),
    [administrations],
  );

  const administrator = useMemo(
    () => administrations[0]?.details?.administrator,
    [administrations],
  );

  return (
    <>
      {!!parties.length && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            {labels.shares}
          </Text>

          <Box spaceAfter={10}>
            {parties.map((party) => (
              <ShareholderCapitalCard
                key={party.id}
                backgroundVariant={backgroundVariant}
                compareDeceaseDate={mutation.effectiveDate}
                party={party}
              >
                {getAdministrationsOfParty(administrations, party).map((administration, index) => (
                  <CapitalCardListItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    shareSeries={administration.shareSeries}
                  />
                ))}
              </ShareholderCapitalCard>
            ))}
          </Box>
        </>
      )}

      {!!administrator && (
        <>
          <Text color="grey80" spaceAfter={6} type="labelBold">
            <Trans>Bewindvoerder</Trans>
          </Text>

          <PartyCard
            backgroundVariant={backgroundVariant}
            compareDeceaseDate={mutation.effectiveDate}
            party={administrator}
          />
        </>
      )}

      {mutation.document && (
        <Block spaceAfter={10}>
          <DeedCard
            backgroundVariant={backgroundVariant}
            document={mutation.document}
            mutation={mutation}
            onDocumentSave={onDocumentSave}
          />
        </Block>
      )}
    </>
  );
};
