import { useGlobalError } from 'hooks/useGlobalError';
import { useRouter } from 'next/router';
import { useCallback } from 'react';

export const useVisitStep = () => {
  const router = useRouter();
  const [, setGlobalError] = useGlobalError();

  return useCallback(
    (newStep: string, replace = false) => {
      const { pathname, query } = router;

      // Clear the save error when leaving a step.
      setGlobalError(undefined);
      // If the original step was a slug, we replace it with a new slug.
      const step = Array.isArray(query.step) ? newStep.split('/') : newStep;
      router[replace ? 'replace' : 'push']({
        pathname,
        query: { ...query, step },
      });
    },
    [router, setGlobalError],
  );
};
