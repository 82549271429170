import type { RegisterMutation } from '@elseu/sdu-evidend-graphql';
import { MutationType } from '@elseu/sdu-evidend-graphql';

export const mutationInputFields = {
  [MutationType.ADMINISTRATION]: 'administration',
  [MutationType.ARTICLE_OF_ASSOCIATION]: 'onboarding', // Reuse onboarding for now
  [MutationType.ATTACHMENT]: 'attachment',
  [MutationType.CONVERSION]: 'conversion',
  [MutationType.END_ADMINISTRATION]: 'endAdministration',
  [MutationType.END_ATTACHMENT]: 'endAttachment',
  [MutationType.END_PLEDGE]: 'endPledge',
  [MutationType.END_USUFRUCT]: 'endUsufruct',
  [MutationType.INCORPORATION]: 'incorporation',
  [MutationType.ISSUANCE]: 'issuance',
  [MutationType.LIQUIDATION]: 'liquidation',
  [MutationType.ONBOARDING]: 'onboarding',
  [MutationType.PAYING_UP]: 'payingUp',
  [MutationType.PLEDGE]: 'pledge',
  [MutationType.TRANSFER]: 'transfer',
  [MutationType.TRUST_CONDITIONS]: 'onboarding', // Reuse onboarding for now
  [MutationType.USUFRUCT]: 'usufruct',
  [MutationType.WITHDRAWAL]: 'withdrawal',
} satisfies {
  [k in MutationType]: keyof RegisterMutation; // we new mutation types are added, this will trigger an error indicating that the new type is missing from the object.
};

export type MutationInputFields = typeof mutationInputFields;
export type MutationInputField = MutationInputFields[keyof MutationInputFields];

export const getMutationField = <Type extends MutationType>(mutationType: Type) =>
  mutationInputFields[mutationType];
