import type { MutationType } from '@elseu/sdu-evidend-graphql';
import {
  updateMutationFormInputMapper,
  useUpdateMutationForm,
} from 'forms/mutation/useUpdateMutationForm';
import { useLabels } from 'hooks/useLabels';
import type { MutationInputFields } from 'mutations/mutationFields';
import { mutationInputFields } from 'mutations/mutationFields';
import { useMutationQueryProvider } from 'mutations/providers/MutationQueryProvider';
import { useRouter } from 'next/router';

const useExecuteMutationForm = <
  Type extends MutationType = MutationType,
  LabelField extends string = `${MutationInputFields[Type]}.summary`,
>() => {
  const router = useRouter();

  const [mutation] = useMutationQueryProvider();

  const {
    register: {
      legalEntity: { id: legalEntityId, isFoundation },
    },
  } = mutation;

  const [mutationForm, mutationFormContext] = useUpdateMutationForm({
    mutation,
    formInputMapper: () => updateMutationFormInputMapper({}, { ...mutation, isDraft: false }),
    onSuccess: () =>
      router.push({
        pathname: `/rechtspersoon/${legalEntityId}`,
      }),
  });

  const mutationType = mutation.type as Type;
  const labelField: LabelField = `${mutationInputFields[mutationType]}.summary` as LabelField;
  const labels = useLabels(labelField, isFoundation);
  return [
    mutationForm,
    {
      ...mutationFormContext,
      labels,
    },
  ] as const;
};

export { useExecuteMutationForm };
